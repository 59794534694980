import React, { useState } from "react";
import "./SearchInputField.scss";
import { FiSearch } from "@react-icons/all-files/fi/FiSearch";
import { ISearchInputField } from "./SearchInputField.interface";
import clsx from "clsx";
import { Input } from "../../uiKit";

export const SearchInputField = ({
  className,
  backgroundInherit,
  value,
  placeholder,
  style,
  setValue,
  isNewDesign,
  ...rest
}: ISearchInputField) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const inputStyles = clsx(
    "search-input",
    className,
    { "text-field-alternative-filled": !backgroundInherit },
    { "background-inherit": backgroundInherit },
    { "zoom-active": isFocused },
    {"search-input_new-design" : isNewDesign},
  );

  return (
    <Input
      style={style}
      autoComplete="off"
      className={inputStyles}
      allowClear
      prefix={isNewDesign ? undefined : <FiSearch />}
      suffix={isNewDesign ? <FiSearch /> : undefined}
      placeholder={placeholder}
      value={value}
      isNewDesign={isNewDesign}
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setValue(event?.target.value)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      {...rest}
    />
  );
};
