import React from "react";
import { IPlanningPageMenu } from "./PlanningPageMenu.interface";
import { SearchInputField } from "../../../../../elements/searchInputField/SearchInputField";
import { AutocompleteAsync } from "../../../../../elements/autocompleteAsync/AutocompleteAsync";
import { PlanningFilter } from "../../components/planningFilter/PlanningFilter";
import { useTranslation } from "react-i18next";

export const PlanningPageWorkPlansMenu = (props: IPlanningPageMenu) => {
  const { t } = useTranslation();

  return (
    <div className="pb-2 d-stack-row justify-space-between align-center">
      <div className="d-stack-row">
        <SearchInputField
          value={props.searchValue ?? ""}
          setValue={props.onPlanSearch!}
          className="planning-page-admin-menu__input-search mr-6"
          placeholder={t("ui:placeholder.plan_search")}
          backgroundInherit
          style={{ width: "410px" }}
        />
        <AutocompleteAsync
          style={{ width: "335px" }}
          type="plan"
          value={props.currentPlanId}
          onChange={props.onPlanIdChange}
          requestOptions={{ userId: props.userId, orderBy: "date_created", orderByType: "desc" }}
          showSearch={false}
        />
      </div>
      <PlanningFilter plans={props.plans} onFilterKeyChange={props.onFilterKeyChange} />
    </div>
  );
};
