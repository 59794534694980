import { PlanningEmployeesCards } from "../../components/planningEmployeesCards/PlanningEmployeesCards";
import { IPlanningPageAdminCardsContent } from "./PlanningPageAdminCardsContent.interface";
import { Empty } from "../../../../../uiKit";

export const PlanningPageAdminCardsContent = (props: IPlanningPageAdminCardsContent) => {
  return (
    <>
      {props.plans?.length ? (
        <PlanningEmployeesCards plans={props.plans} isDone={props.isDone} loadNext={props.loadNext} />
      ) : !props.isLoading ? (
        <Empty />
      ) : null}
    </>
  );
};
