import React, { useEffect, useState } from "react";
import "./AppPreloader.scss";
import { LocalStorageHelpers, useRootStore } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import clsx from "clsx";

function AppPreloaderObserved() {
  const { appStore } = useRootStore();
  const { t } = useTranslation();
  const preloaderText = "BUSINESS BOOSTER";
  const [isVisible, setIsVisible] = useState(appStore.getPreloader ?? false);
  const [isHiding, setIsHiding] = useState(false);
  // const [isVisible, setIsVisible] = useState(appStore.getPreloader ?? false)
  const isDarkMode =
    LocalStorageHelpers.get("appTheme") != null
      ? LocalStorageHelpers.get("appTheme") == 1
      : window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;

  useEffect(() => {
    if (appStore.getPreloader) {
      setIsVisible(true);
    } else {
      setIsHiding(true);
      handlePreloaderOutAnimationEnd();
    }
  }, [appStore.getPreloader]);

  const handlePreloaderOutAnimationEnd = () => {
    const actions = () => {
      setIsVisible(false);
      setIsHiding(false);
    };
    document.getElementById("appPreloader")?.addEventListener("animationend", function () {
      actions();
    });
    setTimeout(() => {
      document.getElementById("appPreloader")?.removeEventListener("animationend", function () {
        actions();
      });
    }, 2500);
  };

  return isVisible ? (
    <div id="appPreloader" className={clsx("app-preloader__wrapper", { __dark: isDarkMode, __out: isHiding })}>
      <div className="d-stack-column align-center">
        <div className="app-preloader__loader">
          {preloaderText.split("").map((l, i) => {
            if (i === 8) return <span key={i}>&nbsp;</span>;
            return <span key={i} data-text={l} children={l} />;
          })}
        </div>
        <span className="app-preloader__text-primary blink-slower" children={t("text:loading_data")} />
        <span className="app-preloader__text-secondary blink-slower" children={t("text:please_wait")} />
      </div>
      {/*<ul className="app-preloader__background">*/}
      {/*  {Array(...Array(10).keys()).map((i) => (*/}
      {/*    <li key={i} />*/}
      {/*  ))}*/}
      {/*</ul>*/}
    </div>
  ) : null;
}

export const AppPreloader = observer(AppPreloaderObserved);
