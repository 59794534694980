import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import "./ListIssues.scss";
import { IListIssues, ISocketMessage } from "./ListIssues.interface";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import Visibility from "visibilityjs";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { api } from "../../../../../../services";
import { BoardFilterContext } from "../../../../../../contexts/communication/boardFilterContext";
import { useIssueHub } from "../../../../../../signalr/hubs/issueHub";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import { regeneratorFilters } from "../../common/regeneratorFilters";
import { IssueShortDto } from "../../../../../../api";
import { RealtimeIssuesKeys } from "../../../../../pages/communication/misc/consts";
import { ListIssuesView } from "./ListIssuesView";
import { BroadcastChannel } from "broadcast-channel";
import { HubConnectionState } from "@microsoft/signalr";
import dayjs from "dayjs";

const ListIssuesMemo = (props: IListIssues) => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  // const globalAuthorizedContext = useContext(GlobalAuthorizedContext);
  const notifier = useNotifier();
  const issueChannel = useMemo(() => new BroadcastChannel("issue"), []);

  const minute = 60 * 1000;

  const context = useContext(BoardFilterContext);
  const { connection, checkDisconnection } = useIssueHub();

  const [debounceReload, setDebounceReload] = useState<boolean>(false);

  const filterGenerator = regeneratorFilters({ ...context });

  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const handlerSuccessRequestEnd = (items: IssueShortDto[]) =>
    // props.onChangeBoardColumnsState({ boardColumnId: props.boardStatus.id, isLoading: false });
    props.onChangeBoardColumnsState({ boardColumnId: props.column?.id, isLoading: false });

  const issues = usePagingWithController(
    api.issueShort,
    {
      boardId: context.boardId ?? null,
      boardColumnId: props.column?.id ?? null,
      // dateDeadlineFrom: context.isDeadlineToday ? dayjs().tz().startOf("day").toISOString() : undefined,
      dateDeadlineTo: context.isDeadlineToday ? dayjs().tz().endOf("day").toISOString() : undefined,
      // dateWorkStartFrom: context.scheduledToday ? dayjs().tz().startOf("day").toISOString() : undefined,
      scheduledForToday: context.scheduledToday,
      tags: props.isGroupingByTags ? [props.tagId!]:  context.tags,
      isActive: true,
      isArchived: false,
      orgchartId: props.isGroupingByTags ? undefined : props.orgchartId,
      ...(context.userRelation && { userRelation: context.userRelation }),
      ...filterGenerator.getReqFilters(),
      ...filterGenerator.getReqSortFilters(),
    },
    {
      pageSize: 10

    },
    undefined,
    handleApiError,
    handlerSuccessRequestEnd
  );

  // const handleCompareBoardStatusById = (boardStatusId: number) => props.boardStatus.id === boardStatusId;
  const handleCompareBoardStatusById = (boardColumnId: number) => props.column?.id === boardColumnId;

  const handlerSocketMessage = (message: ISocketMessage) => {
    if (
      message.boardId != context.boardId ||
      !props.isOpen ||
      message?.byUserId == authStore?.getInitialInfo?.identity?.id
    )
      return;
    if (message.updatedBoardStatusIds) {
      message.updatedBoardStatusIds.forEach((id) => {
        if (message.type == RealtimeIssuesKeys.Added || message.type == RealtimeIssuesKeys.Deleted) {
          if (handleCompareBoardStatusById(id)) setDebounceReload(true);
        } else if (handleCompareBoardStatusById(id) && message.issueId) setDebounceReload(true);
      });
    }
  };

  useEffect(() => {
    if (context.boardId && props.column?.id) {
      props.onChangeBoardColumnsState({ boardColumnId: props.column.id, isLoading: true });
      issues.reset();
      issues.restart();
    }
  }, [
    context.boardId,
    props.column?.id,
    context.userRelation,
    context.senderId,
    context.executorId,
    context.hasViolation,
    context.scheduledToday,
    context.isDeadlineToday,
    context.sortValue,
    context.flowType,
    context.tags,
  ]);

  useEffect(() => {
    if (!connection) return;
    connection.on("IssueUpdate", handlerSocketMessage);
  }, [connection]);

  useEffect(() => {
    connection?.state == HubConnectionState.Disconnected && console.error("Sockets Error: LI");
    // checkDisconnection() &&
    //   notifier.show({
    //     key: "notifier-error-ws",
    //     message: t("notifier:error.real_time_disconnect"),
    //     theme: "error",
    //     timeout: 0,
    //   });
  }, [connection?.state]);

  useEffect(() => {
    const visibilityHandlerId = Visibility.every(minute * 3, () => {
      //communicationsStore.resetOrgchartBoards();
      setDebounceReload(true);
    });

    /*document.addEventListener("visibilitychange", (e: Event) => {
      console.log(document.visibilityState);
      console.log(document.hidden);
    });*/

    return () => {
      Visibility.stop(visibilityHandlerId);
    };
  }, [
    context.userRelation,
    context.sortValue,
    context.boardId,
    context.senderId,
    context.hasViolation,
    context.scheduledToday,
    context.isDeadlineToday,
    context.executorId,
    context.flowType,
    context.tags,
  ]);

  useEffect(() => {
    if (debounceReload) {
      setDebounceReload(false);
      props.onChangeBoardColumnsState({ boardColumnId: props.column?.id, isLoading: true });
      issues.restart(true).then(() => {
        // debounceReloadAfterDelete && customEvent.dispatch("closeCurrentIssue");
      });
    }
  }, [debounceReload]);

  const reloadBoard = () => {
    setDebounceReload(true);
  };

  const handleIssueChannelMessage = useCallback(
    (evt: any) => {
      // if (evt.type != "issueStatus") return;
      setDebounceReload(false);
      // props.onChangeBoardColumnsState({ boardColumnId: props.column?.id, isLoading: true });
      issues.restart(true).then(() => {
        // debounceReloadAfterDelete && customEvent.dispatch("closeCurrentIssue");
      });
    },
    [issues]
  );

  useEffect(() => {
    issueChannel?.addEventListener("message", handleIssueChannelMessage);

    return () => {
      issueChannel?.removeEventListener("message", handleIssueChannelMessage);
    };
  }, [handleIssueChannelMessage, issueChannel]);

  return (
    <ListIssuesView
      issues={issues.items}
      isLoading={issues.info.isLoading}
      loadNext={issues.loadNext}
      isDone={issues.info.isDone}
      totalItems={issues.info.totalItems}
      column={props.column}
      status={props.status}
      currentStatusFromBoard={props.currentStatusFromBoard}
    />
  );
};

export const ListIssues = React.memo(observer(ListIssuesMemo));
