import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BreadcrumbsType, IBreadcrumbsNewView } from "./BreadcrumbsNew.interface";
import { Breadcrumb } from "antd";
import { Link } from "../../uiKit";

export const BreadcrumbsNewView = (props: IBreadcrumbsNewView) => {
  const { breadcrumbsList } = props;

  const [breadcrumbsItemsList, setBreadcrumbsItemsList] = useState<BreadcrumbsType[]>([]);
  const [breadcrumbsHiddenItems, setBreadcrumbsHiddenItems] = useState<BreadcrumbsType[]>([]);
  useEffect(() => {
    setBreadcrumbsHiddenItems([]);
    setBreadcrumbsItemsList(breadcrumbsList);
  }, [breadcrumbsList]);

  const renderBreadcrumbsItem = useCallback((item: BreadcrumbsType, isLast?: boolean) => {
    return {
      key: item.path,
      title: isLast ? (
        <span
          className="breadcrumbs-new-link breadcrumbs-new-link_last"
        >
          {item.name}
        </span>
        ) : (
        <Link
          className="breadcrumbs-new-link"
          onClick={() => props.onRedirect(item.path)}
        >
          {item.name}
        </Link>
      ),
    }
  }, []);


  const itemsList = useMemo(() => {
    const [firstItem] = breadcrumbsItemsList;
    if (firstItem) {
      if (breadcrumbsHiddenItems.length) {
        const itemsList = [
          renderBreadcrumbsItem(firstItem),
          {
            title: "...",
            path: "",
            menu: {
              className: "breadcrumbs-new-menu",
              items: breadcrumbsHiddenItems.map((item) => renderBreadcrumbsItem(item)),
            }
          },
          ...breadcrumbsItemsList.slice(1, breadcrumbsItemsList.length).map((item, i) =>
            renderBreadcrumbsItem(item, i === breadcrumbsItemsList.length - 2)),
        ];
        return itemsList;
      }
      return breadcrumbsItemsList.map((item, i) =>
        renderBreadcrumbsItem(item, i === breadcrumbsItemsList.length - 1));
    }
    return [];
  }, [breadcrumbsItemsList, breadcrumbsHiddenItems]);


  const breadcrumbRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      const pageHeader = document.getElementById("page-header__wrapper");
      const containerWidth = pageHeader?.offsetWidth ?? 0;
      const breadcrumbWidth = breadcrumbRef.current?.offsetWidth ?? 0;
      if (breadcrumbWidth > (containerWidth - 300) && breadcrumbsItemsList.length > 2) {
        const newBreadcrumbsItemsList = [...breadcrumbsItemsList];
        const [firstElement] = newBreadcrumbsItemsList.splice(1, 1);
        setBreadcrumbsHiddenItems((prev: BreadcrumbsType[]) => ([
          ...prev,
          firstElement,
        ]));

        setBreadcrumbsItemsList(newBreadcrumbsItemsList);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [breadcrumbsItemsList]);

  return (
    <div ref={breadcrumbRef}>
      <Breadcrumb
        className="breadcrumbs-new-container"
        items={itemsList}
      />
    </div>
  );
};
