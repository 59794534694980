import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IssueDto, IssueScheduleDto } from "../../../../../../../../api";
import { IssueQueryKeys } from "../../../../../../../../constants/issueTypeKeys";
import { useApi, useRootStore } from "../../../../../../../../hooks";
import { usePagingWithController } from "../../../../../../../../hooks/usePaging";
import { api } from "../../../../../../../../services";
import PageContent from "../../../../../../layouts/pageContent/PageContent";
import { PlanningPageMainContent } from "../../../../contents/planningPageMainContent/PlanningPageMainContent";
import { IPlanningPageAdminWorkPlan } from "./PlanningPageAdminWorkPlan.interface";
import { Breadcrumbs } from "../../../../../../../elements/breadcrumbs/Breadcrumbs";
import { breadcrumbsKeys } from "../../../../../../../elements/breadcrumbs/Breadcrumbs.interface";

export const PlanningPageAdminWorkPlan = (props: IPlanningPageAdminWorkPlan) => {
  const navigate = useNavigate();
  const { breadcrumbsStore } = useRootStore();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const handleOpenCreateIssueDialog = () => navigate({ search: `?${IssueQueryKeys.taskCreate}=${true}` });

  const plan = useApi(
    () => api.plan.getById(props.id),
    null,
    () => {}
  );

  const approvalPlan = props.plansForApproval.find((p) => p.id == props.id);

  const issues = usePagingWithController<IssueDto, {}>(
    api.issue,
    { planId: props.id },
    { pageSize: -1 },
    undefined,
    undefined,
    () => setIsLoading(false)
  );

  const issuesSchedule = usePagingWithController<IssueScheduleDto, {}>(
    api.issueSchedule,
    {
      dateReleaseFrom: plan.value?.dateFrom,
      dateReleaseTo: plan.value?.dateTo,
      executorUserId: plan.value?.userId,
    },
    { pageSize: -1 }
  );

  useEffect(() => {
    if (plan.value) {
      issues.restart();
      issuesSchedule.restart();
    }
  }, [plan.value]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    plan.value?.user?.nameShort && breadcrumbsStore.setLastCrumb(plan.value?.user?.nameShort);
  }, [plan.value?.user?.nameShort]);

  return (
    <>
      {isLoading ? (
        <PageContent isLoading />
      ) : (
        <PageContent>
          <div style={{ overflow: "auto" }} className="full-height">
            <div className="pl-4 mt-2">
              <Breadcrumbs type={breadcrumbsKeys.planning} />
            </div>
            <PlanningPageMainContent
              onOpenCreateIssueDialog={handleOpenCreateIssueDialog}
              plan={plan.value ?? undefined}
              issues={issues.items}
              scheduleIssues={issuesSchedule.items}
              issuesRestart={issues.restart}
              fetchPlans={plan.fetch}
              approvalPlan={approvalPlan}
              fetchApprovalPlans={props.fetchApprovalPlans}
              isLoading={isLoading}
              userId={plan.value?.userId ?? undefined}
            />
          </div>
        </PageContent>
      )}
    </>
  );
};
