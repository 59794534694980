import React from "react";
import "./Tooltip.scss";
import { Tooltip } from "antd";
import { AbstractTooltipProps, TooltipProps } from "antd/lib/tooltip";
import clsx from "clsx";

interface ITooltipView extends AbstractTooltipProps {
  //
}

export function TooltipView({ className, ...rest }: ITooltipView & TooltipProps) {
  return (
    <Tooltip
      overlayClassName={clsx("uiKit-tooltip", className ?? "")}
      // color={"var(--color-white)"}
      {...rest}
    />
  );
}
