import React from "react";
import { Icon, Tooltip } from "../../../../../uiKit";
import { HelpIcon } from "../../../../../../constants/icon";
import { useTranslation } from "react-i18next";
import { useDateHelpers } from "../../../../../../hooks";
import { getObjectFromTimeSpan } from "../../../../../../helpers/dateFunctions";
import "./InfoWrapper.scss";
import { CalendarScheduleDto } from "../../../../../../api/models/CalendarScheduleDto";
import { IInfoWrapperDto } from "./InfoWrapper.interface";

export const InfoWrapper = ({ item, isHiddenFreeTime, isLineItem }: IInfoWrapperDto) => {
  const { t } = useTranslation();
  const { formatTimeSpan } = useDateHelpers();

  const getTimeFormat = (time: string) => {
    return formatTimeSpan(time, {
      formatWithoutDays: true
    }) || 0;
  }

  const getItemStyle = (item: CalendarScheduleDto | null) => {
    if (!item) return;
    const { totalMinutes = 0 } = getObjectFromTimeSpan(item?.freeTime ?? "00:00:00");
    if (totalMinutes > 60 || (totalMinutes >= 0 && item?.type === 2)) {
      return {
        color: 'var(--color-calendar-text-second)',
      };
    }
    if (totalMinutes > 0 && totalMinutes <= 60) {
      return {
        background: 'var(--color-calendar-warning-lighter)',
        color: 'var(--color-calendar-warning-darker)',
      };
    }
    return {
      background: 'var(--color-calendar-error-base)',
      color: 'var(--color-calendar-error-darker)',
    };

  }

  const getTitleStyle = (item: CalendarScheduleDto | null) => {
    if (!item) return;
    const { totalMinutes = 0 } = getObjectFromTimeSpan(item?.freeTime ?? "00:00:00");
    if (totalMinutes > 0 && totalMinutes <= 60) {
      return {
        color: '#171E40',
      };
    }
    return {
      color: 'var(--color-calendar-text-base)',
    };

  }


  return (
    <>
      { !isHiddenFreeTime && (
        <div
          className={`info-item ${isLineItem ? 'info-item_line' : ''}`}
          style={
            getItemStyle(item)
          }
        >
          <div
            className="info-item__title"
            style={getTitleStyle(item)}
          >
            {t("ui:time_block.free_time")}
            { isLineItem ? ":" : (
              <Tooltip title={t("ui:tooltip.time_block.free_time")}>
                <Icon className="info-item__icon" component={() => <HelpIcon />} />
              </Tooltip>
            )}
          </div>
          <div className="info-item__value info-item__value_inherit">
            { getTimeFormat(item?.freeTime ?? "00:00:00") }
          </div>
        </div>
      )}
      <div
        className={`info-item ${isLineItem ? 'info-item_line' : ''}`}
      >
        <div className="info-item__title">
          {t("ui:time_block.total_tasks")}
          { isLineItem ? ":" :  (
            <Tooltip title={t("ui:tooltip.time_block.total_tasks")}>
              <Icon className="info-item__icon" component={() => <HelpIcon />} />
            </Tooltip>
          )}
        </div>
        <div className="info-item__value">
          <span>{item?.doneIssuesCount ?? 0} </span> / {item?.plannedIssuesCount ?? 0}
        </div>
      </div>
      <div
        className={`info-item ${isLineItem ? 'info-item_line' : ''}`}
      >
        <div className="info-item__title">
          {t("ui:time_block.plan_actual")}
          { isLineItem ? ":" : (
            <Tooltip title={t("ui:tooltip.time_block.plan_actual")}>
              <Icon className="info-item__icon" component={() => <HelpIcon />} />
            </Tooltip>
          )}
        </div>
        <div className="info-item__value">
          <span>{getTimeFormat(item?.timeFact ?? "00:00:00")} </span> /
            { getTimeFormat(item?.timePlan ?? "00:00:00") }

        </div>
      </div>
    </>
  )
}