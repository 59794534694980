import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EditableTypography } from "../../../../../elements/editableTypography/EditableTypography";
import { UserCard } from "../../../../../elements/userCard/UserCard";
import { IRegulationContentHeader } from "./RegulationContentHeader.interface";
import { useDateHelpers, useRootStore } from "../../../../../../hooks";
import "./RegulationContentHeader.scss";
import dayjs from "dayjs";

export const RegulationContentHeader: React.FC<IRegulationContentHeader> = (props) => {
  const { authStore } = useRootStore();

  const { t } = useTranslation();

  const dateCreated = useMemo(() => {
    return dayjs(props.dateCreated!)
      .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
      .format("DD MMM YYYY")
  }, [props.dateCreated]);


  return (
    <div className="regulation-content-header">
      {!props.isEditable && (
        <EditableTypography
          multiline
          big
          value={props.currentTitle}
          disabled={true}
          onChange={props.onChangeTitle}
        />
      )}

      <div className="d-stack-row spacing-4 justify-space-between align-center mt-2">
        <UserCard
          userInfo={props.createdByUser}
          avatarSize={35}
          additionalInfo={`${t("parse:created_in")} ${dateCreated}`}
          boldName
          isNewStyle
        />
      </div>
    </div>
  );
};
