import React, { useCallback, useEffect, useRef, useState, memo, useMemo } from "react";
import "./RegulationComments.scss";
import { useTranslation } from "react-i18next";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { api } from "../../../../../../services";
import { IRegulationHistory } from "./RegulationHistory.interface";
import { historySortKeys, historySortKeysType } from "../../misc/consts";
import { RegulationHistoryHeader } from "./RegulationHistoryHeader";
import { RegulationHistoryListCards } from "./RegulationHistoryListCards";
import { Avatar, Button, Card } from "../../../../../uiKit";
import { TextEditor as TextEditorLexical } from "../../../../textEditor/TextEditor";
import { OrgchartDto, RegulationHistoryDto } from "../../../../../../api";
import { UserCard } from "../../../../../elements/userCard/UserCard";
import { RegulationHistorySendBlock } from "./RegulationHistorySendBlock";

export const RegulationHistory = memo((props: IRegulationHistory) => {
  const { t } = useTranslation();
  const notifier = useNotifier();

  const { authStore } = useRootStore();
  const user = useMemo(() => {
    return authStore.getInitialInfo?.identity ?? undefined
  }, [authStore.getInitialInfo?.identity]);


  const [isLoadNextPage, setIsLoadNextPage] = useState<boolean>(false);

  const [sortValue, setSortValue] = useState<historySortKeysType>(historySortKeys.onesFirst);

  const regulationHistory = usePagingWithController(
    api.regulationHistory,
    {
      // @ts-ignore
      regulationId: props.regulationId,
      pageSize: 10,
      orderBy: "date_created",
      order: sortValue === historySortKeys.onesFirst ? "desc" : "asc",
    },
    undefined
  );

  const handleLoadNextPage = useCallback(() => {
    setIsLoadNextPage(true); // костыль собачий, который решает проблему сортировки, почему то параметры, которые я передаю в хук, они динамически не изменяются, и хук использует первоначальные параметры, хотя они изменяются !!!!!
  }, []);


  const handleChangeSortValue = useCallback((newValue: historySortKeysType) => setSortValue(newValue), []);


  useEffect(() => {
    if (isLoadNextPage) {
      regulationHistory.loadNext();
      setIsLoadNextPage(false);
    }
  }, [isLoadNextPage]);

  useEffect(() => {
    regulationHistory.restart(true);
  }, [sortValue]);

  const handleUpdateComment = useCallback((id: number, data: RegulationHistoryDto) => {
    regulationHistory.updateItem(id, data);
  }, [regulationHistory.items]);

  const list = useMemo(() => {
    return regulationHistory.items;
  }, [regulationHistory.items]);


  const handleRemoveComment = (id: number) => {
    regulationHistory.restart();
  };

  useEffect(() => {
    regulationHistory.reset();
    regulationHistory.restart(true);
  }, [props.regulationId])

  return (
    <Card className="regulation-history__wrapper" isShadowed={false}>
      <div className="regulation-history-header">
        {!!list.length && (
          <RegulationHistoryHeader
            sortValue={sortValue}
            onChangeSortValue={handleChangeSortValue}
          />
        )}
      </div>

      {/*<TextEditor*/}
      {/*  id={"regulation-history-editor" + props.regulationId}*/}
      {/*  placeholder={t("ui:placeholder.write_comment")}*/}
      {/*  value={historyContent}*/}
      {/*  ref={editorOptionsRef}*/}
      {/*  onChange={handleChangeCommentContent}*/}
      {/*/>*/}
      <RegulationHistorySendBlock
        user={user}
        regulationId={props.regulationId}
        regulationHistory={regulationHistory}
        regulationContentId={props.regulationContentId}
      />
      {/*<RichTextEditor*/}
      {/*  placeholder={t("ui:placeholder.write_comment")}*/}
      {/*  value={historyContent}*/}
      {/*  ref={editorOptionsRef}*/}
      {/*  onChange={handleChangeCommentContent}*/}
      {/*/>*/}

      <RegulationHistoryListCards
        historyItems={list}
        isDone={regulationHistory.info.isDone}
        loadNext={handleLoadNextPage}
        handleUpdateComment={handleUpdateComment}
        handleRemoveComment={handleRemoveComment}
      />
    </Card>
  );
});
