import React, { useState } from "react";
import { CompanyForAdminDto } from "../../../../../api";
import "./CompanyMainCard.scss";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { useDateHelpers } from "../../../../../hooks";
import { Avatar, Badge, Card, CollapsibleContainer, Divider, Table } from "../../../../uiKit";
import { DropdownMenu } from "../../../../service/dropdownMenu/dropdownMenu";
import { ICompanyColumn, ITableDataSource } from "./CompanyMainCard";
import { PopupMenuItemTypes } from "../../../../service/dropdownMenu/dropdownMenu.interface";

export interface ICompanyMainCardView {
  data: CompanyForAdminDto;
  marginBottom?: boolean;
  marginTop?: boolean;
  menuItems: PopupMenuItemTypes[];
  expandedCardId?: number;
  tableColumns: ICompanyColumn[];
  tableDataSource: ITableDataSource[] | undefined;

  handleBoardReset(boardId: number): void;

  handleCardClick(id: number): void;

  onDeleteClick(): void;
}

function CompanyMainCardView(props: ICompanyMainCardView) {
  const { t } = useTranslation();
  const dateHelpers = useDateHelpers();

  const [collapseOpen, setCollapseOpen] = useState<boolean>(false);

  const handleOpen = () => setCollapseOpen(true);
  const handleClose = () => setCollapseOpen(false);

  return (
    <Card
      clickable
      // onClick={() => props.handleCardClick(props.data.id as number)}
      onClick={collapseOpen ? handleClose : handleOpen}
      className="company-card-main_sheet__wrapper"
    >
      <div className="company-card-main_sheet">
        {/*💵📞  && m.isEnabled*/}
        <Badge
          count={(props.data.isPaid ? "💵" : "") + (props.data.modules?.some((m) => m.type == 9) ? "📞" : "")}
          title={[
            props.data.isPaid ? "Компания платная" : "",
            props.data.modules?.some((m) => m.type == 9) ? "Драйверы активны" : "",
          ]
            .filter((x) => x.length > 0)
            .join(". ")}
          offset={[-12, 2]}
          color="var(--color-info-base)"
        >
          {/*color="var(--color-success-strong)"*/}
          <Avatar
            color={props.data.color}
            src={props.data.image?.url}
            text={props.data.name ?? props.data.nameFallback}
            size={70}
          />
        </Badge>
        <div className="px-4" style={{ flexGrow: 1 }}>
          <span className="company-card-name">
            {/*<Badge count="💵📞" className="mr-1" />*/}
            {props.data.userOwnerId == null
              ? t("ui:text.company_not_registered")
              : (props.data.name ?? "").trim().length > 0
              ? props.data.name
              : props.data.nameFallback}
          </span>
          <div>
            <span className="company-card-secondary">{props.data.description ?? ""}</span>
          </div>
          {props.data.userOwnerId == null ? (
            <div>
              <span className="company-card-secondary">{t("ui:text.invite_code") + ": "}</span>
              <span className="company-card-secondary __content">
                {props.data.inviteForOwner?.code?.toUpperCase() ?? ""}
              </span>
            </div>
          ) : (
            <div>
              {/*<span className="company-card-secondary">{t("ui:text.company_owner") + ": "}</span>*/}
              {/*<span className="company-card-secondary __content">{props.data.userOwner?.name ?? ""}</span>*/}
            </div>
          )}
          <div>
            <span className="company-card-secondary">{t("ui:text.employees_amount") + ": "}</span>
            <span
              className="company-card-secondary __content"
              children={`${props.data.usersCount ?? ""} / ${props.data.userLimit ?? "∞"}`}
            />
          </div>
          {(props.data.contactName ?? "").trim().length > 0 && (
            <div>
              <span className="company-card-secondary">{t("ui:subheader.contact_person") + ": "}</span>
              <span className="company-card-secondary __content">{props.data.contactName ?? ""}</span>
            </div>
          )}
          <div>
            <span className="company-card-secondary">{t("ui:text.date_created") + ": "}</span>
            <span className="company-card-secondary __content">
              {props.data.dateCreated &&
                dateHelpers.formatDate(props.data.dateCreated, { formatObject: { month: "short" } })}
            </span>
          </div>
        </div>
        <div style={{ alignSelf: "flex-start" }}>
          <DropdownMenu items={props.menuItems} />
          {/*<MenuCustom items={props.menuItems} />*/}
        </div>
      </div>
      <CollapsibleContainer isOpen={collapseOpen}>
        <Divider className="my-2" />
        <div>
          <SubheaderText text={t("ui:subheader.boards")} />
          {/* @ts-ignore */}
          <Table pagination={false} columns={props.tableColumns} dataSource={props.tableDataSource} />
        </div>
      </CollapsibleContainer>
    </Card>
  );
}

export default CompanyMainCardView;
