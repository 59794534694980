import React from "react";
import { useDateHelpers } from "../../../../../../hooks";
import { BookIcon } from "../../../../../../images/components/regulation/BookIcon";
import { RegulationProgressBar } from "../RegulationProgressBar/RegulationProgressBar";
import { ICardSectionView } from "./CardSection.interface";
import { Card, Text } from "../../../../../uiKit";

export const CardSectionView = (props: ICardSectionView) => {
  const { formatDate } = useDateHelpers();
  const dateTime = formatDate(new Date(props.section.dateCreated!).toString(), {
    formatObject: {
      hour: undefined,
      minute: undefined,
    },
  });

  return (
    <Card clickable onClick={props.onClick} className="full-width br-none" size="default" isShadowed={false}>
      <div className="d-stack spacing-3 align-center">
        <div className="d-flex align-center flex-grow-1">
          <BookIcon className="mr-4" />
          <Text weight={500} children={props.section.name} />
        </div>
        <div className="flex-shrink-0 d-flex justify-space-around" style={{ flexBasis: "50%" }}>
          <RegulationProgressBar
            progress={{
              progressPercent: props.section?.stats?.studiedPercent
                ? Math.ceil(props.section?.stats.studiedPercent)
                : 0,
            }}
          />
        </div>
        <Text className="flex-shrink-0" type="secondary" weight={500} style={{ width: "150px" }} children={dateTime} />
      </div>
    </Card>
  );
};
