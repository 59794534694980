import { AiOutlinePaperClip } from "@react-icons/all-files/ai/AiOutlinePaperClip";
import { useTranslation } from "react-i18next";
import FileUploader from "../../../../../service/fileUploader/FileUploader";
import { Button, Icon } from "../../../../../uiKit";
import { IPlanHistoryEditor } from "./PlanHistoryModule.interface";
import React from "react";
import { TextEditor as TextEditorLexical } from "../../../../textEditor/TextEditor";
import { initialEditorContent } from "../../../../../../utils/textEditor";

export const PlanHistoryEditor = (props: IPlanHistoryEditor) => {
  const { t } = useTranslation();
  return (
    <>
      {/*<TextEditorOld*/}
      {/*  id="history-editor"*/}
      {/*  placeholder={t("ui:placeholder.write_comment")}*/}
      {/*  value={props.historyContent}*/}
      {/*  ref={props.editorRef}*/}
      {/*  onChange={props.onChangeCommentContent}*/}
      {/*  toolbarType={ToolbarType.Hidden}*/}
      {/*  height="100px"*/}
      {/*/>*/}
      <TextEditorLexical
        id={"plan-history-editor"}
        ref={props.editorRef}
        initialValue={initialEditorContent}
        placeholder={t("ui:placeholder.write_comment")}
        value={props.historyContent}
        onChange={props.onChangeCommentContent}
        height="100px"
        // toolbarType={ToolbarType.Hidden}
      />
      <div className="d-stack-row justify-space-between align-center mb-2">
        <Button
          onClick={props.onCreateHistory}
          disabled={props.getIsDisabled()}
          style={{ marginTop: "8px" }}
          loading={props.isSendingLoading || props.isLoading}
          className="button-icon_17"
          variant="filled"
        >
          {t("ui:button.send")}
        </Button>
        <Button
          onClick={() => (props.uploadFilesInputRef?.current as any)?.click()}
          icon={<Icon component={() => <AiOutlinePaperClip />} />}
          style={{ fontSize: "14px", fontWeight: "14px" }}
          size="small"
          type="text"
        >
          {t("ui:button.attach")}
        </Button>
      </div>
      <FileUploader
        maxSizeMb={40}
        smallSize
        relatedInputId="comment-area-ctrlv-planning"
        value={props.attachments}
        onUpload={(data) => props.setAttachments(data)}
        uploadFilesInputRef={props.uploadFilesInputRef}
        filesContainerMaxHeight="35vh"
        onAnyItemDelete={props.onDeleteAttachmentById}
        onUploadStatusChange={props.setIsFileLoaderUploadingSync}
        hideInfo
      />
    </>
  );
};
