import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IssueDto, IssueScheduleDto, PlanDto } from "../../../../../../api";
import { IssueQueryKeys } from "../../../../../../constants/issueTypeKeys";
import { useApi, useRootStore } from "../../../../../../hooks";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { api } from "../../../../../../services";
import { IPlanningPageMyPlan } from "./PlanningPageMyPlan.interface";
import "./PlanningPageMyPlan.scss";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { PlanningPageMainContent } from "../../contents/planningPageMainContent/PlanningPageMainContent";
import { PlanningPageMyPlanSidebar } from "../../contents/planningPageMyPlanSidebar/PlanningPageMyPlanSidebar";

export const PlanningPageMyPlan = (props: IPlanningPageMyPlan) => {
  const { authStore } = useRootStore();
  const navigate = useNavigate();
  const userId = authStore.getInitialInfo?.identity?.id;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [currentPlanId, setCurrentPlanId] = useState<number | undefined>(undefined);

  const restartPlanHistory = useRef<{ restart: () => void }>();

  const plansShort = usePagingWithController(
    api.planShort,
    { userId: userId, orderBy: "date_created", orderByType: "desc" },
    { pageSize: 10 }
  );

  const currentPlan = useApi<PlanDto | null>(
    () => api.plan.getById(currentPlanId!, { userId: userId, attachCanBeMigratedFromPlanId: true }),
    null,
    () => {},
    {},
    false
  );

  const issuesFromCommunications = useApi(
    () =>
      api.issue.getAll({
        notInPlanId: currentPlan?.value?.id,
        boardStatusKeyIsNot: "done",
        isArchived: false,
        executorUserId: userId,
      }),
    null,
    () => {},
    {},
    false
  );

  const issues = usePagingWithController<IssueDto, {}>(
    api.issue,
    {
      planId: currentPlan?.value?.id,
      includeActions: true,
    },
    { pageSize: -1 },
    undefined,
    undefined,
    () => {
      setIsFirstLoading(false);
      setIsLoading(false);
    }
  );
  const scheduleIssues = usePagingWithController<IssueScheduleDto, {}>(
    api.issueSchedule,
    {
      dateReleaseFrom: currentPlan?.value?.dateFrom,
      dateReleaseTo: currentPlan?.value?.dateTo,
      executorUserId: userId,
    },
    { pageSize: -1 }
  );

  const handleOpenCreateIssueDialog = () => navigate({ search: `?${IssueQueryKeys.taskCreate}=${true}` });

  const handlePlanIdChange = (planId: number) => {
    setCurrentPlanId(planId);
    setIsLoading(true);
  };

  useEffect(() => {
    plansShort.restart();
  }, []);

  useEffect(() => {
    console.log("plansShort.items", plansShort.items);
    if (plansShort.items.length) {
      setCurrentPlanId(plansShort.items.find((i) => i.isCurrent)?.id);
    }
    return () => setCurrentPlanId(undefined);
  }, [plansShort.items]);

  useEffect(() => {
    currentPlan.fetch();
  }, [currentPlanId]);

  useEffect(() => {
    if (currentPlan.value) {
      issuesFromCommunications.fetch();
      issues.restart(true);
      scheduleIssues.restart(true);
      restartPlanHistory.current?.restart && restartPlanHistory.current?.restart();
    }
  }, [currentPlan.value, currentPlan.value?.id]);

  return (
    <div
      className="d-stack-row spacing-2 ml-n2 my-n2 full-height"
      style={{ overflow: "hidden", width: "calc(100% + 8px)", height: "calc(100% + 16px)" }}
    >
      <div className={isSidebarOpen ? "sidebar-visible" : "sidebar-hidden"}>
        <PlanningPageMyPlanSidebar
          isSidebarOpen={isSidebarOpen}
          onSidebarOpenChange={() => setIsSidebarOpen((v) => !v)}
          issues={issuesFromCommunications.value?.items}
          isLoading={issuesFromCommunications.isLoading}
          plan={currentPlan.value ?? undefined}
          fetchPlans={currentPlan.fetch}
          issuesFromCommunicationsRestart={issuesFromCommunications.fetch}
          issuesRestart={issues.restart}
        />
      </div>
      <div className="flex-grow-1 d-flex flex-column pb-2 plan-container">
        <PageContent isLoading={isFirstLoading || issues.info.isLoading}>
          {!isFirstLoading && (
            <PlanningPageMainContent
              ref={restartPlanHistory}
              onOpenCreateIssueDialog={handleOpenCreateIssueDialog}
              plan={currentPlan.value ?? undefined}
              issues={issues.items}
              isLoadingIssue={issues.info.isLoading}
              scheduleIssues={scheduleIssues.items}
              fetchPlans={currentPlan.fetch}
              issuesFromCommunicationsRestart={issuesFromCommunications.fetch}
              issuesRestart={issues.restart}
              fetchApprovalPlans={props.fetchApprovalPlans}
              onPlanIdChange={handlePlanIdChange}
              isLoading={isLoading}
              isSidebarOpen={isSidebarOpen}
              onSidebarOpenChange={() => setIsSidebarOpen((v) => !v)}
              userId={userId}
              plansShort={plansShort.items}
              currentPlanId={currentPlanId}
            />
          )}
        </PageContent>
      </div>
    </div>
  );
};
