import React from "react";
import { CollapseProps } from "antd";
import { CollapseView } from "./CollapseView";

interface ICollapse extends CollapseProps {
  separated?: boolean;
}

export function Collapse(props: ICollapse) {
  return <CollapseView {...props} />;
}
