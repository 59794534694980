import { RelationsTypes } from "../../components/modules/pages/communications/components/boardRelationSwitch/BoardRelationSwitch.interface";
import { RelationsKeys } from "../../components/modules/pages/communications/constants/keys";
import { createContext } from "react";
import { sortKeys, sortKeyType } from "../../components/pages/communication/misc/consts";
import { BoardDto } from "../../api";
import { TagDto } from "../../api/models/TagDto";

export interface IBoardColumnsState {
  boardColumnId?: number;
  isLoading: boolean;
}

export interface ICollapseContext {
  boardId?: number | null;
  userRelation: RelationsTypes;
  sortValue: sortKeyType;
  executorId?: number;
  senderId?: number;
  hasViolation: boolean;
  board?: BoardDto;
  flowType?: number;
  boardColumnsState: IBoardColumnsState[];
  onChangeUserRelation?: (relation: RelationsTypes) => void;
  isGroupingByOrgcharts: boolean;
  isGroupingByTags: boolean;
  isDeadlineToday: boolean;
  scheduledToday: boolean;
  calendarRoleId?: number | undefined;
  tags: number[];
  tagsList: TagDto[];
  allTagsList: TagDto[];
  refetchTags: () => void;
}

const defaultBoardFilterState: ICollapseContext = {
  userRelation: RelationsKeys.My,
  sortValue: sortKeys.date,
  boardId: undefined,
  executorId: undefined,
  senderId: undefined,
  hasViolation: false,
  flowType: undefined,
  boardColumnsState: [],
  isGroupingByOrgcharts: true,
  isGroupingByTags: true,
  isDeadlineToday: false,
  scheduledToday: false,
  tags: [],
  tagsList: [],
  allTagsList: [],
  refetchTags: () => undefined,
};

export const BoardFilterContext = createContext<ICollapseContext>(defaultBoardFilterState);
