import { useTranslation } from "react-i18next";
import { IssueDto } from "../../../../../../api";
import { useRootStore } from "../../../../../../hooks";
import { Shape, Text, Tooltip } from "../../../../../uiKit";
import { PlanningTaskCard } from "./planningTaskCard/PlanningTaskCard";
import { IPlanningTaskCardsView } from "./PlanningTaskCards.interface";
import "./PlanningTaskCards.scss";

export const PlanningTaskCardsView = (props: IPlanningTaskCardsView) => {
  const { orgchartStore } = useRootStore();
  const { t } = useTranslation();
  const getOrgchartById = (id: number) => orgchartStore.getOrgchartsList.find((o) => o.id == id);

  return (
    <div className="d-stack-column spacing-6">
      {props.groupedArr.map((arr: IssueDto[]) => {
        const name = arr.find((item) => item?.role?.name)?.role?.name;
        return (
          <div className="d-stack-column spacing-3" key={props.onIncrementGroupId()}>
            <div className="d-stack-row spacing-2 align-baseline">
              {!name ? null : (
                <Tooltip title={(arr[0]?.orgchartId && getOrgchartById(arr[0].orgchartId)?.name) ?? ""}>
                  <div>
                    <Shape
                      type="circle"
                      size={16}
                      backgroundColor={
                        (arr[0]?.orgchartId &&
                        getOrgchartById(arr[0].orgchartId)?.colorHex != undefined &&
                        (getOrgchartById(arr[0].orgchartId)?.colorHex ?? "").trim().length > 0
                          ? arr[0]?.orgchartId
                            ? getOrgchartById(arr[0].orgchartId)?.colorHex
                            : "var(--color-layout-fill-base)"
                          : "var(--color-layout-fill-base)") ?? "var(--color-layout-fill-base)"
                      }
                    />
                  </div>
                </Tooltip>
              )}
              <Text className="flex-grow-1" size="12px" weight="bold" type="secondary">
                {arr.find((item) => item?.role?.name)?.role?.name ?? t("common:planning.no_function")}
              </Text>
            </div>
            <div className="d-stack-column spacing-3">
              {arr.map((issue) => (
                <div key={issue.id}>
                  <PlanningTaskCard
                    loadingTaskId={props.loadingTaskId}
                    key={issue.id}
                    issue={issue}
                    onAddIssue={props.onAddIssue}
                    plan={props.plan}
                    issuesFromCommunicationsRestart={props.issuesFromCommunicationsRestart}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
