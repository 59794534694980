import React, { memo, useCallback, useEffect, useState } from "react";

import { Button, Dialog, DialogActions } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import { AddingRegulationsToSection } from "../addingRegulationsToSection/AddingRegulationsToSection";
import "./AddingRegulationsToSectionDialog.scss";
import useDebounce from "../../../../hooks/useDebounce";
import { api } from "../../../../services";
import { IAddingRegulationsToSectionDialog } from "./IAddingRegulationsToSectionDialog";

export const AddingRegulationsToSectionDialog = memo((props: IAddingRegulationsToSectionDialog) => {
  const { t } = useTranslation();

  const [checkedKeysList, setCheckedKeysList] = useState<string[]>([]);
  const [parentSectionId, setParentSectionId] = useState<number | null>(null);

  const debouncedKeys = useDebounce(checkedKeysList, 500);

  const getKeysData = (list: string[]) => {
    const regulations: number[] = [];
    const sections: number[] = [];
    list.forEach((item) => {
      const key = item.split('-').pop() ?? '';
      if (key.includes('s')) {
        sections.push(+key.replace('s', ''))
      } else if (key.includes('r')) {
        regulations.push(+key.replace('r', ''))
      }
    });
    return {
      regulations,
      sections,
    }
  }

  const [regulationsCount, setRegulationsCount] = useState<number>(0);

  const getRegulationsCount = async () => {
    const { regulations, sections } = getKeysData(debouncedKeys);
    const count = await api.navigationMenu.getRegulationsCount({
      regulationIds: regulations,
      navigationMenuItemIds: sections,
    });
    if (count) {
      setRegulationsCount(count);
    }

  }

  useEffect(() => {
    if (!props.isSingleChoice) {
      getRegulationsCount();
    }
  }, [debouncedKeys]);

  const [isLoading, setIsLoading] = useState<boolean>(false);


  const handleClickSubmit = useCallback(async () => {
    const { regulations, sections } = getKeysData(checkedKeysList);
    setIsLoading(true);
    const sectionsList = parentSectionId ? [parentSectionId] : sections;
    const status = await props.onAddedRegular(regulations, sectionsList);
    if (status) {
      props.onClose();
    }
    setIsLoading(false);
  }, [checkedKeysList, parentSectionId]);

  // useEffect(() => {
  //   console.log("checkedKeysList", checkedKeysList);
  // }, [checkedKeysList]);


  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      title={props.isSingleChoice
        ? t("ui:adding_regulation_to_section_dialog.title")
        : t("ui:adding_regulations_to_section_dialog.title")
      }
      closable={false}
      className={"adding-regulations-to-section-dialog"}
    >
      <AddingRegulationsToSection
        checkedKeysList={checkedKeysList}
        setCheckedKeysList={setCheckedKeysList}
        setParentSectionId={setParentSectionId}
        sectionId={props.section?.id}
        isSingleChoice={props.isSingleChoice}
      />
      <DialogActions>
        {!props.isSingleChoice && (
          <div className="count-regulars">
            {t("ui:adding_regulations_to_section_dialog.selected", { count: regulationsCount })}
          </div>
        )}
        <Button
          onClick={props.onClose}
          variant="default"
        >
          {t("ui:button.cancel")}
        </Button>
        <Button
          onClick={handleClickSubmit}
          variant="filled"
          disabled={!parentSectionId && !checkedKeysList.length}
          loading={isLoading}
        >
          { t("ui:button.add") }
        </Button>
      </DialogActions>
    </Dialog>
  )
});