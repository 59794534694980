import React, { useContext, useEffect, useMemo, useState } from "react";
import "./BoardCollapse.scss";
import { IBoardCollapse } from "./BoardCollapse.interface";
import { useApi, useNotifier, useRootStore } from "../../../../../../hooks";
import { BoardCollapseView } from "./BoardCollapseView";
import { BoardFilterContext } from "../../../../../../contexts/communication/boardFilterContext";
import { api } from "../../../../../../services";
import { baseBoardStatusKeys } from "../../constants/keys";
import { BoardInfo } from "../../../../../../api";
import { useTranslation } from "react-i18next";
import { regeneratorFilters } from "../../common/regeneratorFilters";
import { useIssueHub } from "../../../../../../signalr/hubs/issueHub";
import { GlobalAuthorizedContext } from "../../../../../../contexts/globalAuthorizedContext";
import { ISocketMessage } from "../listIssues/ListIssues.interface";
import { BroadcastChannel } from "broadcast-channel";

export const BoardCollapse = (props: IBoardCollapse) => {
  const { authStore, orgchartStore } = useRootStore();
  const { t } = useTranslation();
  const notifier = useNotifier();
  const context = useContext(BoardFilterContext);
  const globalAuthorizedContext = useContext(GlobalAuthorizedContext);
  const issueChannel = new BroadcastChannel("issue");

  const { connection, checkDisconnection } = useIssueHub();

  const [openBoards, setOpenBoards] = useState<number[]>([]);

  const [boardInfo, setBoardInfo] = useState<BoardInfo[] | null>(null);

  const filterGenerator = regeneratorFilters({ ...context });

  const handlerApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  // const boardsByOrgcharts = useMemo(() => authStore.getInitialInfo?.orgcharts, [authStore.getInitialInfo?.orgcharts]);

  const boardsByOrgchartsWithStats = useApi(
    () => api.orgchart.getOrgchartsWithStats({ userRelation: context.userRelation }),
    handlerApiError,
    () => {},
    {},
    false
  );

  const boardsByTagsWithStats = useApi(
    () => api.tags.getTagsWithStats({
      userRelation: context.userRelation,
      tags: context.tags,
    }),
    handlerApiError,
    () => {},
    {},
    false
  );

  const handleChangeOpenBoards = (openIds: number[]) => setOpenBoards(openIds);

  useEffect(() => {
    if (orgchartStore.getCurrentOrgchartId) {
      if (openBoards.includes(orgchartStore.getCurrentOrgchartId)) return;
      else setOpenBoards((prev) => [...prev, orgchartStore.getCurrentOrgchartId!]);
    }
  }, [orgchartStore.getCurrentOrgchartId]);

  const handleLoadCounters = async (message?: ISocketMessage) => {
    // if (message != null && authStore.getInitialInfo?.identity?.id == message?.byUserId) {
    //   return;
    // }
    //communicationsStore.resetOrgchartBoards();
    const commonFilter = filterGenerator.getReqFilters();
    const sortFilter = filterGenerator.getReqSortFilters();
    const filters = {
      boardId: context.boardId,
      boardStatusKey: baseBoardStatusKeys.pending,
      isActive: true,
      isArchived: false,
      pageSize: 1,
      ...(context.userRelation && { userRelation: context.userRelation }),
      ...commonFilter,
      ...sortFilter,
    };
    setBoardInfo(await api.issue.boardInfo(filters));
  };

  useEffect(() => {
    context.boardId && handleLoadCounters();
    //if (orgchartStore.getOrgchartsList.length) handleLoadShortBoardData();
  }, [
    orgchartStore.getOrgchartsList.length,
    context.userRelation,
    context.sortValue,
    context.boardId,
    context.senderId,
    context.hasViolation,
    context.scheduledToday,
    context.isDeadlineToday,
    context.executorId,
    context.flowType,
    context.tags,
  ]);

  useEffect(() => {
    if (!connection) return;
    connection.on("IssueUpdate", handleLoadCounters);
  }, [connection]);

  useEffect(() => {
    console.error("Sockets Error: BC");
    // checkDisconnection() &&
    //   notifier.show({
    //     key: "notifier-error-ws",
    //     message: t("notifier:error.real_time_disconnect"),
    //     theme: "error",
    //     timeout: 0,
    //   });
  }, [connection?.state]);

  // useEffect(() => {
  //   globalAuthorizedContext?.setState({
  //     ...globalAuthorizedContext,
  //     issue: {
  //       ...globalAuthorizedContext?.issue,
  //       reload: { ...globalAuthorizedContext?.issue?.reload, issueBoard: handleLoadCounters },
  //     },
  //   });
  // }, []);

  const handleIssueChannelMessage = (evt: any) => {
    //
    console.log("Message detected");
  };

  useEffect(() => {
    issueChannel?.addEventListener("message", handleIssueChannelMessage);

    return () => {
      issueChannel?.removeEventListener("message", handleIssueChannelMessage);
    };
  }, []);

  useEffect(() => {
    boardsByOrgchartsWithStats.fetch();
  }, [context.userRelation]);

  useEffect(() => {
    boardsByTagsWithStats.fetch();
  }, [context.userRelation, context.tags]);

  const groupList = useMemo(() => {
    return context.isGroupingByOrgcharts ? boardsByOrgchartsWithStats.value : boardsByTagsWithStats.value;
  }, [context.isGroupingByOrgcharts, boardsByOrgchartsWithStats.value, boardsByTagsWithStats.value])

  return (
    <BoardCollapseView
      boardsByWithStats={groupList}
      // boardsByOrgcharts={boardsByOrgcharts}
      board={context.board}
      onChangeBoardColumnsState={props.onChangeBoardColumnsState}
      openBoardIds={openBoards}
      onChangeOpenBoards={handleChangeOpenBoards}
      //autocompleteIssues={{ inWorkIssues, pedingIssues }}
      boardInfo={boardInfo}
      isGroupingByTags={context.isGroupingByTags}
    />
  );
};
