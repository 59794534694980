import React from "react";
import "./IssueOptions.scss";
import { IIssueOptions } from "./IssueOptions.interface";
import { IssueActionConsts } from "../../../types/consts";
import { DropdownMenu } from "../../../../../service/dropdownMenu/dropdownMenu";

export const IssueOptions = (props: IIssueOptions): JSX.Element => {
  const actionsHandler = (key: IssueActionConsts): (() => void) => {
    switch (key) {
      case IssueActionConsts.delete: {
        return () => props.toggleDeleteConfirm(true);
      }
      case IssueActionConsts.withdraw: {
        return () => props.onOpenWithdrawIssueDialog();
      }
      case IssueActionConsts.copy: {
        return props.onOpenCopyIssueDialog;
      }
      case IssueActionConsts.sendForArchive: {
        return props.onOpenSendForArchiveDialog;
      }
      case IssueActionConsts.archive: {
        return props.onOpenSendForArchiveDialog;
      }
      case IssueActionConsts.unArchive: {
        return props.onHandleUnArchive;
      }
      // case IssueActionConsts.reject: {
      //   return () => props.onOpenIssueRejectDialog();
      // }
      // case IssueActionConsts.sendAgain: {
      //   return () => props.onSendIssueAgain();
      // }
      // case IssueActionConsts.doByMyself: {
      //   return () => props.onDoingIssueByMyself();
      // }
      default: {
        return () => {};
      }
    }
  };

  const popupItems = (props.actions ?? [])
    .filter((item) => !item.baseAction?.displayInFlowComboBox)
    .map((item) => ({
      id: item.id!,
      text: item.baseAction?.name!,
      action: actionsHandler(item.baseAction?.key as IssueActionConsts),
    }));

  return <DropdownMenu items={popupItems} />;
};
