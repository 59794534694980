import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LexicalTypeaheadMenuPlugin } from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { TextNode } from "lexical";
import * as React from "react";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import * as ReactDOM from "react-dom";
import { UserInfo } from "../../../../elements/userInfo/UserInfo";
import { usePagingWithController } from "../../../../../hooks/usePaging";
import { api } from "../../../../../services";
import useDebounce from "../../../../../hooks/useDebounce";
import ScrollTrigger from "../../../../service/scrollTrigger/ScrollTrigger";
import { Empty, Popover } from "../../../../uiKit";
import { css } from "@emotion/css";
import { checkForAtSignMentions, MentionTypeaheadOption } from "./utils/helpers";
import { $createCustomNode } from "../../nodes/CustomNode";

export const NewMentionsPlugin = memo(() => {
  const [editor] = useLexicalComposerContext();
  const [queryString, setQueryString] = useState<string | null>(null);

  const users = usePagingWithController(api.userShort, { name: queryString }, { pageSize: 10 });

  const searchDebounce = useDebounce(queryString != "" && queryString != null ? queryString : null, 400);

  const options = useMemo(() => users.items.map((user) => new MentionTypeaheadOption(user.name ?? "", user)), [users]);

  const onSelectOption = useCallback(
    (selectedOption: MentionTypeaheadOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(() => {
        const mentionNode = $createCustomNode("user_id", selectedOption.id, "");
        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }
        closeMenu();
      });
    },
    [editor]
  );

  const checkForMentionMatch = (text: string) => {
    const mentionMatch = checkForAtSignMentions(text, 0);
    return mentionMatch ? mentionMatch : null;
  };

  useEffect(() => {
    searchDebounce !== null && users.restart();
  }, [searchDebounce]);

  useEffect(() => {
    users.restart(true);
  }, []);

  return (
    <LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      options={options}
      menuRenderFn={(anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) => {
        return anchorElementRef.current
          ? ReactDOM.createPortal(
              <div className="typeahead-popover mentions-menu">
                <Popover
                  overlayInnerStyle={{
                    maxHeight: 300,
                    overflow: "auto",
                    padding: "4px 4px 4px 8px",
                    width: "max-content",
                  }}
                  placement={"bottomLeft"}
                  open={!!anchorElementRef.current}
                  content={
                    <>
                      {!options.length && !users.info.isLoading ? (
                        <Empty />
                      ) : (
                        options.map((option, i: number) => (
                          <div
                            key={option.id}
                            tabIndex={-1}
                            ref={option.setRefElement}
                            className={css`
                              align-items: center;
                              display: flex;
                              cursor: pointer;
                              background: ${selectedIndex === i ? "var(--color-layout-fill-base)" : ""};
                              height: 36px;
                              border-radius: var(--border-radius-sm);
                              padding: 4px;
                            `}
                            onClick={() => {
                              setHighlightedIndex(i);
                              selectOptionAndCleanUp(option);
                            }}
                            onMouseEnter={() => {
                              setHighlightedIndex(i);
                            }}
                          >
                            <UserInfo user={option.user} isTooltipDisabled />
                          </div>
                        ))
                      )}
                      <ScrollTrigger
                        onIntersection={users.loadNext}
                        hidden={users.info.isDone}
                        disabled={users.info.isDone}
                        marginTop={options?.length > 0}
                      />
                    </>
                  }
                />
              </div>,
              anchorElementRef.current
            )
          : null;
      }}
    />
  );
});
