import "./CalendarPlanningCalendar.scss";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin, { EventReceiveArg, EventResizeDoneArg } from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import uniqid from "uniqid";
import { api } from "../../../../../../services";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import allLocales from "@fullcalendar/core/locales-all";
import { useDateHelpers, useNotifier, useRootStore } from "../../../../../../hooks";
import { EventDropArg, EventInput, EventSourceInput } from "@fullcalendar/core";
import fullCalendarPluginDayjsTimeZone from "../../../../../../plugins/fullCalendar/fullCalendarPluginDayjsTimeZone";
import { observer } from "mobx-react-lite";
import { CalendarPlanningCalendarViewType } from "../../../../../../stores/communicationCalendarPlanningStore";
import dayjs, { ConfigType } from "dayjs";
import { convertMinutesToTimeSpan, getObjectFromTimeSpan } from "../../../../../../helpers/dateFunctions";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Text } from "../../../../../uiKit";
import { cardKeys } from "../../../regulation/misc/consts";
import {
  CalendarEventBorderDto,
  CalendarEventDto, type CompanyScheduleDto,
  type DayOfWeek,
  IssueCustomFieldDto,
  IssueDto
} from "../../../../../../api";
import { CustomConfirmDialog } from "../../../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { getWarningData } from "../../../../forms/issueCreateEditForms/utils/data";
import {
  IIntermediateData
} from "../../../../forms/issueCreateEditForms/containers/issueUpdateContainer/IssueUpdateContainer.interface";
import { WarningsKeys } from "../../../../forms/issueCreateEditForms/utils/keys/warningsKeys";
import { BroadcastChannel } from "broadcast-channel";
import { CheckIcon, DotIcon } from "../../../../../../constants/icon";
import IssueRequiredActionsDialog from "../../../../dialogs/issueRequiredActionsDialog/IssueRequiredActionsDialog";
import { actionsKeysConst, blockedFieldsKeys } from "../../../../forms/types/consts";
import { CalendarTimeBlock } from "../../../../dialogs/calendarTimeBlock/CalendarTimeBlock";
import { CalendarEventTimeBlockDto } from "../../../../../../api/models/CalendarEventTimeBlockDto";
import { CalendarPlanningFooter } from "../calendarPlanningFooter/CalendarPlanningFooter";
import { CalendarScheduleDto } from "../../../../../../api/models/CalendarScheduleDto";
import { IWidthDto } from "../calendarPlanningFooter/CalendarPlanningFooter.interface";
import { DAYS_OF_WEEK } from "../../constants/daysOfWeek";
import { CalendarGoogleEventBlock } from "../../../../dialogs/calendarGoogleEventBlock/CalendarGoogleEventBlock";
import { GoogleCalendarEventDto } from "../../../../../../api/models/GoogleCalendarEventDto";
import { ACCEPTED, DECLINED, TENTATIVE } from "../../../../../../constants/googleCalendarEvent";
import { BoardFilterContext } from "../../../../../../contexts/communication/boardFilterContext";
import { GoogleCalendarAccountDto } from "../../../../../../api/models/GoogleCalendarAccountDto";

interface ICalendarPlanningCalendarProps {
  onIsLoadingChange: (isLoading: boolean) => void;
  calendarSidebarContainer: HTMLInputElement | null;
}

const CalendarPlanningCalendarObserved = (props: ICalendarPlanningCalendarProps) => {
  const { authStore, appStore, communicationCalendarPlanningStore } = useRootStore();
  const notifier = useNotifier();
  const { t } = useTranslation();

  const { calendarRoleId, tags } = useContext(BoardFilterContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const calendarRef = useRef<InstanceType<typeof FullCalendar>>(null);

  const [events, setEvents] = useState<EventInput[]>([]);

  const isSmallView = useRef<boolean>(false);
  const isDayView = useRef<boolean>(false);

  const getCalendarItemColor = (item: CalendarEventBorderDto) => {
    const cardBackground = item?.colorSchemeKey === "secondary"
      ? "var(--color-calendar-primary-weaker)"
      : `var(--color-calendar-${item?.colorSchemeKey}-base)`;

    return item?.colorSchemeKey
      ? cardBackground
      : 'var(--color-calendar-default)';
  };

  const getCalendarIconSize = (item: any) => {
    const parsedTimePlan = getObjectFromTimeSpan(item.timePlan!);

    return parsedTimePlan.hours ? 16 : 10;
  }

  const getCalendarItemTextColor = (item: CalendarEventBorderDto, isDeclined?: boolean) => {
    if (item?.colorSchemeKey === "secondary" || isDeclined) {
      return "var(--color-calendar-text-second)"
    }

    if (item?.colorSchemeKey === "green") {
      return "var(--color-calendar-text-third)"
    }

    return  !item?.colorSchemeKey || item?.colorSchemeKey === "warning" || item?.colorSchemeKey === "error"
    || item?.colorSchemeKey === "faint"
      ? "var(--color-calendar-text-base)"
      : "var(--fc-event-text-color)"
  };

  const formationEventData = (event: CalendarEventDto, id?: string | null) => {
    const parsedTimePlan = getObjectFromTimeSpan(event.timeFact! ?? event.timePlan!);

    const item  = event.border || {};
    const { responseStatus } = event.googleCalendarData ?? {};

    const backgroundColor: string =
      responseStatus === "declined" ? "var(--color-calendar-green-light)" : getCalendarItemColor(item);

    const calendarCardSmallClassName =
      isSmallView.current
        ? `calendar-card_small`
        : '';
    const eventId = event.id ? `${event.id}-${event.issueId}` : uniqid();
    return {
      id: id || eventId,
      eventId: event.id,
      issueId: event.issueId,
      title: event.name ?? "",
      start: dayjs(event.dateWorkStart).toISOString(),
      end: dayjs(event.dateWorkStart)
        .add({
          hours: parsedTimePlan.hours,
          minutes: parsedTimePlan.minutes,
          seconds: parsedTimePlan.seconds,
        })
        // .tz()
        .toISOString(),
      className: `calendar-card ${parsedTimePlan.hours ? "calendar-card_lg" : ""} ${calendarCardSmallClassName}`,
      backgroundColor: backgroundColor,
      editable: !event.isReadOnly && event?.border?.colorSchemeKey !== "green",
      extendedProps: {
        eventData: event,
      },
      selectMirror: true,
      // allDay: false,
    };
  }

  const axiosController = useRef<AbortController | null>(new AbortController());
  const isLoading = useRef(false);

  const [isScrollCalendar, setIsScrollCalendar] = useState(false);

  const getEventsFromApi = async (dateFrom: string, dateTo: string, functionId?: number | undefined) => {
    // if (!isFirstRenderTriggered.current) return void (isFirstRenderTriggered.current = true);
    props.onIsLoadingChange(true);
    // setEvents([]);
    setEvents([]);
    setIsScrollCalendar(false);
    let isAborting = false;
    if (axiosController.current) {
      axiosController.current.abort();
      isAborting = true;
    }
    axiosController.current = new AbortController();
    const r =
      await api.calendarEvent.getCalendarEvents({ dateFrom, dateTo, functionId, tags }, axiosController.current.signal);
    isLoading.current = false
    if (r == null) {
      if (!isAborting) {
        props.onIsLoadingChange(false);
      }
      return;
    }

    const newEvents: EventSourceInput = r
      .filter((e) => e.dateWorkStart != null && e.timePlan != null)
      .map((e) => {
        return formationEventData(e);
      });

    setEvents(newEvents);
    if (r.length) {
      const minDate = r.length === 1
        ? r[0].dateWorkStart
        : r
        .filter((e) => e.dateWorkStart != null && e.timePlan != null)
        .reduce((prev, current) => {
          const prevDateWorkStart = dayjs(prev.dateWorkStart!)
            .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
            .format("HH:mm:ss");
          const currentDateWorkStart = dayjs(current.dateWorkStart!)
            .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
            .format("HH:mm:ss")

          return prevDateWorkStart < currentDateWorkStart
            ? prev
            : current
     }, {})?.dateWorkStart;
      if (minDate != null) {
        const calendarApi = calendarRef.current?.getApi();
        calendarApi?.scrollToTime(
          dayjs(minDate)
            .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
            .format("HH:mm:ss")
        );
      }
    } else {
      setIsScrollCalendar(true);
    }
    props.onIsLoadingChange(false);
  };

  useEffect(() => {
    if (isScrollCalendar) {
      const [firstDate] = companyShedule ?? []
      const minDate = companyShedule
        .filter((item) => item.timeDayStart)
        .reduce((prev, current) => {
          const prevTimeObj = prev?.timeDayStart! ? getObjectFromTimeSpan(prev?.timeDayStart!) : {};
          const currentTimeObj = getObjectFromTimeSpan(current?.timeDayStart!);
          const prevDateWorkStart = dayjs()
            .set("hour", prevTimeObj?.hours ?? 23)
            .set("minute", prevTimeObj?.minutes ?? 0)
            .toDate();

          const currentDateWorkStart = dayjs()
            .set("hour", currentTimeObj?.hours ?? 23)
            .set("minute", currentTimeObj?.minutes ?? 0)
            .toDate();

          return dayjs(prevDateWorkStart).format("HH:mm:ss") < dayjs(currentDateWorkStart).format("HH:mm:ss")
            ? prev
            : current
        }, {} as CompanyScheduleDto)?.timeDayStart;


      if (minDate != null) {
        const calendarApi = calendarRef.current?.getApi();
        calendarApi?.scrollToTime(minDate);
      }
      setIsScrollCalendar(false);
    }
  }, [isScrollCalendar])

  const DateCellWrapper = (props: any) => {
    return <div style={{ width: "calc(100% / 7)" }}>Cell</div>;
  };

  const handleGoToToday = () => {
    const calendarApi = calendarRef.current?.getApi();
    calendarApi?.today();
  };

  const handleGoToPrevious = () => {
    const calendarApi = calendarRef.current?.getApi();
    calendarApi?.prev();
  };

  const handleGoToNext = () => {
    const calendarApi = calendarRef.current?.getApi();
    calendarApi?.next();
  };

  const handleGoToDate = (date: string) => {
    const calendarApi = calendarRef.current?.getApi();
    calendarApi?.gotoDate(date);
  };

  const [schedule, setSchedule] = useState<CalendarScheduleDto[]>([]);
  const calendarViewType =  calendarRef.current?.getApi()?.view;

  const handleDateRangeChange = async (startDate: Date, endDate: Date) => {

    if (communicationCalendarPlanningStore.getDateFrom.toISOString() === startDate.toISOString()) {
      return;
    }
    // await getEventsFromApi(startDate.toISOString(), endDate.toISOString());

    const calendarApi = calendarRef.current?.getApi();

    communicationCalendarPlanningStore.setDateFrom(startDate);
    communicationCalendarPlanningStore.setDateTo(endDate);
    // const calendarApi = calendarRef.current?.getApi();
    const { type = ''} = calendarApi?.view ?? {}
    if (!(type === 'timeGridWeek' || type === 'timeGridDay')) {
      setStatistic(null)
    }
  };

  const fetchEvents = useCallback(async () => {
    const startDate = communicationCalendarPlanningStore.getDateFrom;
    const endDate = communicationCalendarPlanningStore.getDateTo;
    await getEventsFromApi(startDate.toISOString(), endDate.toISOString(), calendarRoleId);
  }, [
    communicationCalendarPlanningStore.getDateFrom,
    communicationCalendarPlanningStore.getDateTo,
    calendarRoleId,
    tags,
  ]);

  const axiosScheduleSignal = useRef<any>(undefined);

  const fetchSchedule = useCallback(async () => {
    // setSchedule([]);
    const startDate = communicationCalendarPlanningStore.getDateFrom;
    const endDate = communicationCalendarPlanningStore.getDateTo;

    let isAborting = false;
    if (axiosScheduleSignal.current) {
      axiosScheduleSignal.current.abort();
      isAborting = true;
    }
    axiosScheduleSignal.current = new AbortController();
    console.log("fetchSchedule tags", tags);
    try {
      const data = await api.calendarEvent.getSchedule(
        {
          dateFrom: startDate.toISOString(),
          dateTo: endDate.toISOString(),
          functionId: calendarRoleId,
          tags,
        },
        axiosScheduleSignal.current.signal
      );
      setSchedule(data ?? []);
    } catch (err) {
      console.log('err==========', err);
      if (!isAborting) {
        setSchedule([]);
      }
    }

  }, [
    communicationCalendarPlanningStore.getDateFrom,
    communicationCalendarPlanningStore.getDateTo,
    axiosScheduleSignal.current,
    calendarRoleId,
    tags,
  ]);


  const [statistic, setStatistic] = useState<CalendarScheduleDto | null>(null);
  const fetchStatistic = useCallback(async () => {
    // setSchedule([]);
    const calendarApi = calendarRef.current?.getApi();
    const { type = ''} = calendarApi?.view ?? {}
    if (type !== 'timeGridWeek') {
      setStatistic(null);
      return;
    }
    const startDate = communicationCalendarPlanningStore.getDateFrom;
    const endDate = communicationCalendarPlanningStore.getDateTo;
    try {
      const data = await api.calendarEvent.getStatistic({
        dateFrom: startDate.toISOString(),
        dateTo: endDate.toISOString(),
        functionId: calendarRoleId,
        tags,
      });
      setStatistic(data ?? null);
    } catch (err) {
      console.error(err);
      setStatistic(null);
    }

  }, [
    communicationCalendarPlanningStore.getDateFrom,
    communicationCalendarPlanningStore.getDateTo,
    calendarRoleId,
    tags,
  ]);

  const getInitialData = async () => {
    if (isLoading.current) {
      axiosController.current?.abort();
    }
    isLoading.current = true
    props.onIsLoadingChange(true);
    const calendarApi = calendarRef.current?.getApi();
    const { type = ''} = calendarApi?.view ?? {}
    if (type === 'timeGridWeek' || type === 'timeGridDay') {
      await fetchSchedule();
      await fetchStatistic();
    }
    await fetchEvents();
  }

  const endDate = useRef<Date>(new Date());
  const prevRoleId = useRef<number | undefined>();
  const prevTags = useRef<number[]>([]);
  const prevGoogleAccount = useRef<boolean>(false);

  useEffect(() => {
    if ((prevRoleId.current !== calendarRoleId)
        || (endDate.current !== communicationCalendarPlanningStore.getDateTo)
      || (prevGoogleAccount.current !== authStore.getGoogleCalendarAccount?.connectedToReadCalendar)
      || (prevTags.current.length !== tags.length)
    ) {
      getInitialData();
    }
    // startDate.current = communicationCalendarPlanningStore.getDateFrom
    endDate.current = communicationCalendarPlanningStore.getDateTo;
    prevRoleId.current = calendarRoleId;
    prevGoogleAccount.current = authStore.getGoogleCalendarAccount?.connectedToReadCalendar ?? false;
    prevTags.current = tags;
  }, [
      communicationCalendarPlanningStore.getDateFrom,
      communicationCalendarPlanningStore.getDateTo,
      calendarRoleId,
      tags,
      authStore.getGoogleCalendarAccount,
  ]);

  useEffect(() => {
    prevGoogleAccount.current = authStore.getGoogleCalendarAccount?.connectedToReadCalendar ?? false;
  }, [authStore.getGoogleCalendarAccount?.connectedToReadCalendar]);

  const handleIssueChannelMessage = async () => {
    const startDate = communicationCalendarPlanningStore.getDateFrom;
    const endDate = communicationCalendarPlanningStore.getDateTo;
    await getEventsFromApi(startDate.toISOString(), endDate.toISOString(), calendarRoleId);
    await fetchStatistic()
    await fetchSchedule();
  }

  const issueChannel = useMemo(() => new BroadcastChannel("issue"), []);

  useEffect(() => {
    issueChannel?.addEventListener("message", handleIssueChannelMessage);

    return () => {
      issueChannel?.removeEventListener("message", handleIssueChannelMessage);
    };
  }, [issueChannel, tags, calendarRoleId]);

  const handleEventClick = (issueId: number | undefined | null) => {
    if (issueId == null) return;
    searchParams.set("issueId", issueId?.toString());
    setSearchParams(searchParams);
    // globalAuthorizedContext.issue?.onIssueDialogOpen?.(issueId);
  };

  const updateCalendarEventsList = (item: CalendarEventDto, id?: string | null) => {
    const event= formationEventData(item, id);
    setEvents((prev) => {
      const list = prev.filter((item) => {
        if (event.issueId) {
          return item.issueId !== event.issueId
        } else if (event.eventId) {
          return item.eventId !== event.eventId
        }
      });
      return [
      ...list,
      event
      ]
    });
  }

  const [isShowFooter, setIsShowFooter] = useState<boolean>(true);


  const creatCalendarItem = async (eventData: CalendarEventDto, info?: EventReceiveArg, issueData?: any) => {
    props.onIsLoadingChange(true);
    try {
      const r = await api.calendarEvent.create(eventData);
      props.onIsLoadingChange(false);
      if (r == null) {
        notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
        if (info) {
          info.revert();
        }
        return false;
      }
      updateCalendarEventsList(r);
      await fetchSchedule();
      await fetchStatistic()
      // await handleIssueChannelMessage();
      if (info) {
        communicationCalendarPlanningStore.getOnSidebarReload(issueData);
      }
      return true;
    } catch (err) {
      console.error(err);
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      if (info) {
        info.revert();
      }
      return false;
    }
  }

  const updateCalendarItem = async (info: EventDropArg | EventReceiveArg, issueData: any) => {
    props.onIsLoadingChange(true);
    try {
      const r = await api.calendarEvent.create({
        ...issueData,
        id: issueData.id || undefined,
        dateWorkStart: info.event.start?.toISOString(),
      });
      props.onIsLoadingChange(false);
      const { id } = info.event;
      if (r == null) {
        notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
        info.revert();
        return;
      }
      updateCalendarEventsList(r, id);
      if (issueData.issueId) {
        communicationCalendarPlanningStore.getOnSidebarReload({
          ...issueData,
          id: issueData.issueId,
          dateWorkStart: info.event.start?.toISOString(),
        });
      }
      await fetchSchedule();
      await fetchStatistic();
    } catch (err) {
      console.error(err);
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      info.revert();
    }
  }

  const updateCalendarTimePlan = async (info: EventResizeDoneArg, issueData: any) => {
    props.onIsLoadingChange(true);
    try {
      const { timePlan, timeFact } = issueData;
      const r = await api.calendarEvent.create({
        ...issueData,
        id: issueData.id || undefined,
        timePlan: timeFact
          ? timePlan
          : convertMinutesToTimeSpan(Math.abs(dayjs(info.event.start).diff(info.event.end, "minute"))),
        timeFact: timeFact
          ? convertMinutesToTimeSpan(Math.abs(dayjs(info.event.start).diff(info.event.end, "minute")))
          : undefined,

      });

      props.onIsLoadingChange(false);
      if (r == null) {
        notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
        info.revert();
        return;
      }
      const { id } = info.event;
      updateCalendarEventsList(r, id);
      communicationCalendarPlanningStore.getOnSidebarReload(issueData);
      await fetchSchedule();
      await fetchStatistic()
    } catch (err) {
      console.error(err);
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      info.revert();
    }
  }

  const [intermediateData, setIntermediateData] = useState<IIntermediateData | undefined>(undefined);

  const validationDeadLine = (issueData: any, info: any) => {
    const { timePlan = '00:15', dateDeadline } = issueData;
    const parsedTimePlan = getObjectFromTimeSpan(timePlan!);

    const dateDeadlineWithTimezone = dayjs(dateDeadline)
      .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
      .toDate();

    const endDate =  dayjs(info.event.start)
      .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
      .add({
        hours: parsedTimePlan.hours,
        minutes: parsedTimePlan.minutes,
        seconds: parsedTimePlan.seconds,
      })
      .toDate();

    if (issueData == null) {
      info.revert();
      return false;
    }
    if (dateDeadline && ((info.event.start as Date) > dateDeadlineWithTimezone || endDate > dateDeadlineWithTimezone)) {
      const { fields = [], isStrictDeadline } = issueData;
      const strictDeadlineField = fields.find((field: IssueCustomFieldDto) => field.key === blockedFieldsKeys.isStrictDeadline);
      const isStrictDeadlineFieldValue = strictDeadlineField?.valueBool ?? false;
      if (!isStrictDeadline && !isStrictDeadlineFieldValue) {
        setIsOpenRequiredActionsDialog(true);

        setIssue({
          dateDeadline: issueData.dateDeadline,
          dateWorkStart: info.event.start,
          timePlan: issueData.timePlan ?? "00:15:00",
          id: issueData.issueId ?? issueData.id,
        });
        setIntermediateData({
          warningKey: WarningsKeys.changeDeadline,
          event: {
            info,
            issueData,
          },
        });
      } else {
        setIntermediateData({
          warningKey: WarningsKeys.issuesOverdue,
          event: {
            info,
            issueData,
          },
        });
      }
      return false;
    }
    return true;
  }

  const getModalSubTitle = (): string => {
    if (!intermediateData) return '';
    const { issueData, info } = intermediateData?.event;
    const { dateDeadline } = issueData;
    const dateDeadlineDate = new Date(dateDeadline);

    const dateFormat = dayjs(dateDeadlineDate)
      .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
      .format("HH:mm, DD.MM");


    const { start } = info.event;

    const startDateFormat = dayjs(start)
      .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
      .format("HH:mm, DD.MM");
    const [startTime, startDate] = startDateFormat.split(',')
    return t(getWarningData().find((item) => item.key == intermediateData?.warningKey)?.description ?? "", {
      date: dateFormat,
      startDate,
      startTime,

    });
  };

  const getEventData = (info: EventReceiveArg, issueData: CalendarEventDto) => {
    const { start, end } = info.event;
    return {
      dateWorkStart: start?.toISOString(),
      issueId: issueData.id,
      userId: authStore.getInitialInfo?.identity?.id,
      timePlan: convertMinutesToTimeSpan(Math.abs(dayjs(start).diff(end, "minute"))),
    }
  }

  const handleEventCreate = async (info: EventReceiveArg) => {
    const issueData = info.event.extendedProps.issueData;
    console.log("issueData", issueData);
    console.log("events", events);
    console.log("info.event.extendedProps", info.event.extendedProps);
    const currentEvent = events.find((item) => item.issueId === issueData.id);
    console.log("currentEvent", currentEvent);
    if (currentEvent) {
      const { eventData } = currentEvent.extendedProps ?? {};
      console.log(eventData, eventData);
      console.log("currentEvent", currentEvent);
      if (validationDeadLine(issueData, info)) {
        await updateCalendarItem(info, {
          ...eventData,
          dateWorkStart: info.event.start,
        });
      }
    } else {
      if (validationDeadLine(issueData, info)) {
        const eventData = getEventData(info, issueData);
        await creatCalendarItem(eventData, info, issueData);
      }
    }
    // const calendarApi = calendarRef.current?.getApi();
  };

  const [draggingEventId, setDraggingEventId] = useState<string | null>(null);
  const [draggingDiffMinutes, setDraggingDiffMinutes] = useState<number>(0);

  const handleEventDrop = async (info: EventDropArg) => {
    if (draggingDiffMinutes) {
      info.event.moveDates({ minutes: draggingDiffMinutes });
    }
    setDraggingDiffMinutes(0);
    setDraggingEventId(null);
    const eventData = info.event.extendedProps.eventData;
    console.log("eventData", eventData);
    // if (isDragging) {
    //   setIsDragging(false);
    // }
    if (validationDeadLine(eventData, info)) {
      await updateCalendarItem(info, eventData);
    }
    return;
  };

  const handleEventResize = async (info: EventResizeDoneArg) => {
    const { eventData, issueData } = info.event.extendedProps;
    const eventUpdateData = eventData ?? issueData;
    const days = Math.abs(dayjs(info.event.start).diff(info.event.end, "days"));
    if (days > 0) {
      eventUpdateData.timePlan = "23:59:00"
      const { start } = info.event;
      const end = dayjs(start).add({hours: 23, minutes: 59}).toDate();
      info.event.setEnd(end);
    }
    if (validationDeadLine(eventUpdateData, info)) {
      if (eventData) {
        await updateCalendarTimePlan(info, eventUpdateData);
      }
    }
    if (eventData == null) {
      info.revert();
      return;
    }
  };

  const [firstDay, setFirstDay] = useState<DayOfWeek>(1);

  const handleCalendarViewChange = (viewName: CalendarPlanningCalendarViewType) => {
    let view = "timeGridWeek";
    const {
      DAY,
      WEEK,
      MONTH,
      YEAR,
      REPORTING_WEEK,
    } = CalendarPlanningCalendarViewType;


    if (viewName === DAY) view = "timeGridDay";
    if (viewName === WEEK || viewName === REPORTING_WEEK) view = "timeGridWeek";
    if (viewName === MONTH) view = "dayGridMonth";
    if (viewName === YEAR) view = "multiMonthYear";

    isSmallView.current = viewName === MONTH || viewName === YEAR  || viewName === DAY;
    isDayView.current = viewName === DAY;
    // setCalendarColumnWidth({all: 0, first: 0, tableWidth: 0});

    const calendarApi = calendarRef.current?.getApi();
    const prevView = calendarApi?.view?.type ?? '';

    const firstWeekDay = viewName === REPORTING_WEEK
      ? authStore.getCurrentCompany?.weekReportStart
      : authStore.getCurrentCompany?.weekStart

    setFirstDay(firstWeekDay as DayOfWeek);
    calendarApi?.changeView(view);

    if (viewName === REPORTING_WEEK) {
      const today = dayjs();
      const diff = (authStore.getCurrentCompany?.weekReportStart ?? 1) - today.day();
      const startDage = today.add(diff, 'day');
      communicationCalendarPlanningStore.setDateFrom(startDage.toDate());
      communicationCalendarPlanningStore.setDateTo(startDage.add(6, "day").toDate());
    }

    if (prevView !== 'timeGridDay' && prevView !== 'timeGridWeek') {
      getFooterSize();
    }
    setIsShowFooter(viewName === DAY || viewName === WEEK || viewName === REPORTING_WEEK);

  };

  const [companyShedule, setCompanyShedule] = useState<CompanyScheduleDto[]>([]);

  useEffect(() => {
    if (companyShedule.length) {
      const weekends = companyShedule.filter(({ isActive }) => !isActive);
      const container = calendarContainer.current;

      weekends.forEach(({ dayOfWeek }) => {
        if (dayOfWeek !== undefined) {
          const dayColor = `--date-${DAYS_OF_WEEK[dayOfWeek]}-color`;
          const dayDarkColor = `--date-${DAYS_OF_WEEK[dayOfWeek]}-dark-color`;
          // @ts-ignore
          container?.style.setProperty(dayColor, "#F9F9F9");
          // @ts-ignore
          container?.style.setProperty(dayDarkColor, "#9ba6c226");
        }
      });
    }
  }, [companyShedule])

  const fetchCompanyShedule = async () => {
    const id = authStore.getCurrentCompanyId
    if (id) {
      const shedule = await api.company.getCompanyShedule(id);
      if (shedule) {
        setCompanyShedule(shedule);
      }
    }
  };

  useEffect(() => {
    fetchCompanyShedule();
    communicationCalendarPlanningStore.setOnGoToToday(handleGoToToday);
    communicationCalendarPlanningStore.setOnGoToPrevious(handleGoToPrevious);
    communicationCalendarPlanningStore.setOnGoToNext(handleGoToNext);

    return () => {
      axiosController.current = null
    }
  }, []);

  useEffect(() => {
    handleCalendarViewChange(communicationCalendarPlanningStore.getCalendarViewType);
  }, [communicationCalendarPlanningStore.getCalendarViewType]);

  const handlePreventDefaultField = () => {
    intermediateData?.event?.info.revert();
    setIntermediateData(undefined);
  };

  const handleConfirm = async () => {
    const { info, issueData } = intermediateData?.event;
    const { endDelta, oldEvent } = info;
    setIntermediateData(undefined);
    if (endDelta) {
      await updateCalendarTimePlan(info, issueData);
    } else if (oldEvent) {
      await updateCalendarItem(info, issueData);
    } else {
      const eventData = getEventData(info, issueData);
      await creatCalendarItem(eventData, info, issueData);
    }
  };

  const getTimeFormat = (date: Date | null, id?: string | null) => {
    const minute = draggingEventId && id === draggingEventId ? draggingDiffMinutes : 0;
    return dayjs(date)
      .add(minute, "minute")
      .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local").format("LT")
  };

  const [isOpenRequiredActionsDialog, setIsOpenRequiredActionsDialog] = useState<boolean>(true);
  const [issue, setIssue] = useState<IssueDto | undefined>(undefined);


  const handleCloseRequiredActionsDialog = () => {
    setIsOpenRequiredActionsDialog(false);
    setIssue(undefined);
    handlePreventDefaultField();
  };

  const handleConfirmChangeDeadline = () => {
    setIsOpenRequiredActionsDialog(false);
    setIssue(undefined);
    handleConfirm();
  }

  const calendarContainer = useRef(null);

  const [calendarColumnWidth, setCalendarColumnWidth] = useState<IWidthDto>({first: 0, tableWidth: 0});

  const getFooterSize = () => {
    setCalendarColumnWidth({first: 0, tableWidth: 0});
    const calendarApi = calendarRef.current?.getApi();
    const view = calendarApi?.view;
    if (view?.type === 'timeGridWeek' || view?.type === 'timeGridDay') {
      const calendar = view?.calendar;
      // @ts-ignore
      const table = document.querySelector(".fc-col-header");
      const tableWidth = table?.getBoundingClientRect().width ?? 0;
      // const columnSize = view?.viewSpec.options.slotWidth;
      // const th = table?.querySelector(".calendar-planning-calendar__day");
      const thFirst = table?.querySelector(".fc-timegrid-axis");
      // const all = th?.getBoundingClientRect().width ?? 0;
      const firstWidth = thFirst?.getBoundingClientRect().width ?? 80;
      const first = firstWidth < 80 ? 80 : firstWidth;
      // const tableWidth = table?.getBoundingClientRect().width ?? 0;
      setCalendarColumnWidth({ first, tableWidth });
    }
    setIsDetectFooterSize(false)
  }

  const [isDetectFooterSize, setIsDetectFooterSize] = useState<boolean>(false);

  useEffect(() => {
    if (isDetectFooterSize) {
      setCalendarColumnWidth({first: 0, tableWidth: 0});
      calendarRef.current?.getApi().updateSize();
      getFooterSize();
    }
  }, [isDetectFooterSize]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setCalendarColumnWidth({first: 0, tableWidth: 0});
      calendarRef.current?.getApi().updateSize();
      getFooterSize();
    };

    const observer = new ResizeObserver(handleResize);
    if (calendarContainer.current) {
      observer.observe(calendarContainer.current);
    }

    return () => {
      if (calendarContainer.current) {
        observer.unobserve(calendarContainer.current);
      }
    };
  }, [calendarContainer]);

  const [selectedDate, setSelectedDate] = useState<CalendarEventTimeBlockDto | null>(null);

  const handleSelectedDate = (workStart: Date) => {
    const dateWorkStart = dayjs(workStart)
      .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
      .format();
    const dateWorkEnd =
      dayjs(workStart)
        .add({ minutes: 15 })
        .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
        .format();
    setSelectedDate({
      dateWorkStart,
      dateWorkEnd,
    })
  };

  const handleClickTimeBlock = (event: any) => {
    const { dateWorkStart, timePlan } = event;
    const { hours = 0,  minutes = 0, seconds = 0 } = getObjectFromTimeSpan(timePlan!);
    const dateWorkEnd = dayjs(dateWorkStart)
      .add({
        hours,
        minutes,
        seconds,
      })
    setSelectedDate({
      ...event,
      dateWorkEnd,
      dateWorkStart: dayjs(dateWorkStart),
    });
  };

  const [googleCalendarEvent, setGoogleCalendarEvent] = useState<GoogleCalendarEventDto | null>(null);

  const getResponseStatus = (responseStatus: string) => {
    if (responseStatus === DECLINED || responseStatus === TENTATIVE || responseStatus === ACCEPTED) {
      return responseStatus;
    }
    return null;
  };

  const handleClickGoogleEvent = (event: any) => {
    const {
      googleCalendarData, estimatedEndDate: dateWorkEnd, dateWorkStart, ...googleEventData
    } = event;
    const { url, responseStatus } = googleCalendarData;
    const startDate = dayjs(dateWorkStart).format("YYYY-MM-DD");
    const endDate = dayjs(dateWorkEnd).format("YYYY-MM-DD");
    const isSameDay = dayjs(startDate).isSame(dayjs(endDate), "day");

    const eventTime = isSameDay
      ? `${dayjs(dateWorkStart).format("L LT")} - ${dayjs(dateWorkEnd).format("LT")}`
      : `${dayjs(dateWorkStart).format("LLL")} - ${dayjs(dateWorkEnd).format("LLL")}`;

    setGoogleCalendarEvent({
      ...googleEventData,
      ...googleCalendarData,
      dateWorkStart: dayjs(dateWorkStart),
      dateWorkEnd: dayjs(dateWorkEnd),
      status: getResponseStatus(responseStatus),
      url: url ? `<a href="${url}" target="_blank">${url}</a>` : "",
      eventTime,
    });
  };

  const [isLoadingTimeBlock, setIsLoadingTimeBlock] = useState<boolean>(false);

  const handleSaveTimeBlock = async (event: CalendarEventTimeBlockDto) => {
    const { isFullDay, dateWorkStart, dateWorkEnd, name, description, id } = event;

    const currentDay: CalendarScheduleDto = schedule?.find((item) =>
      dayjs(item?.dateFrom).format("dd DD MMM") === dayjs(dateWorkStart).format("dd DD MMM")
    ) || {} as CalendarScheduleDto;


    const timeFrom = currentDay?.timeFrom ?? "00:00:00";
    const timeTo = currentDay?.timeTo ?? "23:59:00";
    const [hour, minute, second] = timeFrom.split(":");
    const [hourEndDate, minuteEndDate, secondEndDate] = timeTo.split(":");

    const startDate = isFullDay
      ? dayjs(dateWorkStart)
        .set('hour', +hour)
        .set('minute', +minute)
        .set('second', +second)
      : dayjs(dateWorkStart);

    const endDate = isFullDay
      ? dayjs(dateWorkStart)
        .set('hour', +hourEndDate)
        .set('minute', +minuteEndDate)
        .set('second', +secondEndDate)
      : dayjs(dateWorkEnd);

    const timePlan = convertMinutesToTimeSpan(Math.abs(startDate.diff(endDate, "minute")));

    const eventData = {
      // id: 0,
      id,
      dateWorkStart: startDate.toDate()?.toISOString(),
      userId: authStore.getInitialInfo?.identity?.id,
      timePlan,
      name,
      description,
    }
    setIsLoadingTimeBlock(true);
    const status = await creatCalendarItem(eventData);
    setIsLoadingTimeBlock(false);
    if (status) {
      setSelectedDate(null);
    }
  };

  const handleClickRemove = async () => {
    if (eventId) {
      props.onIsLoadingChange(true);
      try {
        const r = await api.calendarEvent.del(eventId);
        props.onIsLoadingChange(false);
        if (r == null) {
          notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
          return false;
        }
        await fetchSchedule();
        await fetchStatistic()
        setEvents((prev) => {
          return prev.filter((item) => item?.eventId !== eventId)
        });
        setSelectedDate(null);
        // await handleIssueChannelMessage();
        handleToggleDeleteConfirmDialog(false);
        return true;
      } catch (err) {
        console.error(err);
        notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
        return false;
      }
    }
  };

  const [eventId, setEventId] = useState<number | null>(null);
  const [openDialogDeleteMessage, setOpenDialogDeleteMessage] = useState<boolean>(false);
  const handleToggleDeleteConfirmDialog = (value: boolean) => {
    setOpenDialogDeleteMessage(value);
    if (!value) {
      setEventId(null);
    }
  };

  const openConfirmDeleteDialog = (id: number) => {
    handleToggleDeleteConfirmDialog(true);
    setEventId(id);
  }

  const popupItems = [
    {
      id: 1,
      text: t("ui:button:delete"),
      // action: handleClickRemove,
      action: openConfirmDeleteDialog,
    },
  ];


  const deleteCalendarEvent = async (event: any) => {
    const { eventData } = event.extendedProps;
    props.onIsLoadingChange(true);
    try {
      const r = await api.calendarEvent.create({
        ...eventData,
        id: eventData.id || undefined,
        dateWorkStart: null,
      });
      props.onIsLoadingChange(false);
      if (r == null) {
        notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
        return;
      }
      await issueChannel.postMessage({
        issueId: eventData?.issueId,
        type: "issueBody",
      });
      setEvents((prev) => prev.filter((item) => item.id !== event.id));
      await fetchSchedule();
      await fetchStatistic()
    } catch (err) {
      console.error(err);
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    }
  };

  return (
    <>
      {!!googleCalendarEvent && (
        <CalendarGoogleEventBlock
          event={googleCalendarEvent}
          onClose={() => setGoogleCalendarEvent(null)}
        />
      )}

      {selectedDate && (
        <CalendarTimeBlock
          item={selectedDate}
          popupItems={popupItems}
          handleSaveTimeBlock={handleSaveTimeBlock}
          onClose={() => setSelectedDate(null)}
          isLoadingTimeBlock={isLoadingTimeBlock}
        />
      )}
      {openDialogDeleteMessage && (
        <CustomConfirmDialog
          open={openDialogDeleteMessage}
          onClose={() => handleToggleDeleteConfirmDialog(false)}
          onConfirm={handleClickRemove}
          title={t("ui:title.delete_events")}
        />
      )}
      {isOpenRequiredActionsDialog && issue && (
        <IssueRequiredActionsDialog
          open={isOpenRequiredActionsDialog}
          boardData={{
            baseTransitionActionKey: actionsKeysConst.changeDeadline,
          }}
          issueData={issue}
          onConfirmData={handleConfirmChangeDeadline}
          onClose={handleCloseRequiredActionsDialog}
        />
      )}
      {!!intermediateData && intermediateData.warningKey === WarningsKeys.issuesOverdue &&(
      <CustomConfirmDialog
        open={true}
        onClose={handlePreventDefaultField}
        onConfirm={handleConfirm}
        title={t('ui:title.attention')}
        subTitle={getModalSubTitle()}
        buttonText={{
          confirm: t('ui:button.accept')
        }}
      />
    )}
    <div
      className="calendar-planning-calendar__wrapper full-height"
      ref={calendarContainer}
    >
      <FullCalendar
        ref={calendarRef}
        events={events}
        firstDay={firstDay}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, multiMonthPlugin, fullCalendarPluginDayjsTimeZone]}
        timeZone={authStore.getInitialInfo?.identity?.timeZoneId ?? "local"}
        initialView="timeGridWeek"
        nowIndicator
        height="100%"
        multiMonthMaxColumns={2}
        expandRows={false}
        headerToolbar={false}
        eventDisplay={"block"}
        editable={true}
        selectable={false}
        droppable={true}
        allDaySlot={false}
        locale={appStore.getAppLocale ?? "en"}
        locales={allLocales}
        scrollTime={undefined}
        stickyFooterScrollbar={true}
        slotLabelFormat={{ hour: "2-digit", minute: "2-digit" }}
        multiMonthTitleFormat={(info) => dayjs(info.date.marker).format("MMMM YYYY")}
        slotDuration={"01:00:00"}
        snapDuration={"00:15:00"}
        eventDragStart={(e) => {
          // setIsDragging(true);
          const { start, id } = e.event;
          const eventStart = dayjs(start);

          const minute = +dayjs(start).format('mm');
          if (minute % 15) {
            const roundedMinute = Math.round(+minute / 5) * 5;
            const diffMinutes = roundedMinute - minute;
            setDraggingEventId(id);
            setDraggingDiffMinutes(diffMinutes);
            // eventStart.add(diffMinutes, 'minute').toDate();
            // event.moveDates({minutes: diffMinutes });
          }
        }}
        eventDragStop={(e) => {
          const { jsEvent: { pageX, pageY }, el, event } = e;
          const {
            left = 0,
            right = 0,
            top = 0,
          } = props.calendarSidebarContainer?.getBoundingClientRect() ?? {};

          if (((pageX >= left &&  pageX <= right) || (pageX < left && pageX - el.clientWidth >= left))
            && (pageY >= top || (pageY - el.clientHeight) >= top)) {
            const { issueId } = event.extendedProps;
            if (issueId) {
              deleteCalendarEvent(event)
            }
          }

        }}
        eventMinHeight={20}
        selectMirror={true}
        datesSet={({ start, end }) => handleDateRangeChange(start, end)}
        eventClick={(e) => {
          console.log("e.event.extendedProps?.eventData", e.event.extendedProps?.eventData);
          if (e.event.extendedProps?.issueData?.id ?? e.event.extendedProps?.eventData?.issueId) {
            handleEventClick(e.event.extendedProps?.issueData?.id ?? e.event.extendedProps?.eventData?.issueId);
          } else {
            if (e.event.extendedProps?.eventData?.googleCalendarData) {
              handleClickGoogleEvent(e.event.extendedProps?.eventData);
            } else {
              handleClickTimeBlock(e.event.extendedProps?.eventData);
            }
          }
        }
        }
        eventDrop={handleEventDrop}
        eventReceive={handleEventCreate}
        eventResize={handleEventResize}
        eventTimeFormat={(info) => getTimeFormat(info.date.marker)}
        eventContent={(info) => {
          return (
            <div
              className={
                `${
                  isSmallView.current ? 'd-stack-row align-center' : 'd-stack-column'
                } spacing-1 calendar-card-wrapper`}
              style={{
                maxHeight: "100%",
                overflow: "hidden",
                color: getCalendarItemTextColor(
                  info.event.extendedProps?.eventData?.border,
                  info.event.extendedProps?.eventData?.googleCalendarData?.responseStatus === "declined"
                )
            }}
            >
              <div className="calendar-card-wrapper-header">
                {
                  !isSmallView.current && info.event.extendedProps?.eventData?.border?.colorSchemeKey === "secondary" && (
                    <div className="calendar-card-wrapper-header__icon">
                      <CheckIcon
                        size={getCalendarIconSize(info.event.extendedProps?.eventData)}
                      />
                    </div>
                  )
                }
                <Text
                  className={isSmallView.current ? "calendar-card-wrapper__title" : ''}
                  size="12px"
                  style={{
                    color: "inherit",
                    fontWeight: isSmallView.current ? "bold" : "normal",
                    textDecoration:
                      info.event.extendedProps?.eventData?.border?.colorSchemeKey === "secondary" ||
                      info.event.extendedProps?.eventData?.googleCalendarData?.responseStatus === "declined"
                      ? 'line-through' : "none",
                    lineHeight: 1.25,
                }}
                >
                  { info.event.extendedProps.eventData?.name?.trim() || info.event.extendedProps.issueData?.name }
                </Text>
              </div>
              <Text
                size={"10px" }
                style={{
                  color: "inherit",
                  textDecoration:
                    info.event.extendedProps?.eventData?.border?.colorSchemeKey === "secondary" ||
                    info.event.extendedProps?.eventData?.googleCalendarData?.responseStatus === "declined"
                      ? 'line-through' : "none",
                  marginLeft: !isSmallView.current && info.event.extendedProps?.eventData?.border?.colorSchemeKey === "secondary"
                    ? getCalendarIconSize(info.event.extendedProps?.eventData) + 5
                    : 0,
                  lineHeight: 1.2,
              }}>
                { isSmallView.current && !isDayView.current
                  ? getTimeFormat(info.event.start, info.event.id)
                  : [getTimeFormat(info.event.start, info.event.id), getTimeFormat(info.event.end, info.event.id)].join(" - ")
                }
              </Text>
            </div>
          )
        }}
        showNonCurrentDates={false}
        dateClick={({date, dateStr, ...arg}) => {
          console.log("dateClick");
          handleSelectedDate(date);
        }}
        slotLabelContent={(e) => {
          return (
            <div
              className={`d-flex align-center px-4 day-time ${!e.time?.milliseconds ? 'day-time_first' : ''}`}
              style={{ height: "50px" }}
            >
              <span children={e.text} />
            </div>
          )
        }}
        dayHeaderClassNames={"calendar-planning-calendar__day"}
        viewDidMount={(e) => {
          setTimeout(() => {
            setIsDetectFooterSize(true)
          }, 150);
        }}

        dayHeaderContent={(e) => (
            <div className="d-flex align-center px-4" style={{ height: "32px" }}>
              <span
                style={{
                  fontWeight: "normal",
                  color: e.isToday ? "var(--color-primary-base)" : "var(--color-text-base)",
                }}
                children={e.text}
                // children={`${dayjs(e.date).format("dd DD MMM")}`}
              />
            </div>
          )
        }
      />
      {isShowFooter && (
        <CalendarPlanningFooter
          width={calendarColumnWidth}
          schedule={schedule}
          statistic={statistic}
        />
      )}
    </div>
    </>
  );
};

const CalendarPlanningCalendarRow = (props: any) => {
  return <div className="calendar-planning-calendar__row">{props.children}</div>;
  // fc-event-dragging
};

export const CalendarPlanningCalendar = observer(CalendarPlanningCalendarObserved);
