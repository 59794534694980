import React from "react";
import { IUpdateNoteCreateHeader } from "./UpdateNoteCreateHeader.interface";
import { LocaleSwitcher } from "../../components/LocaleSwitcher/LocaleSwitcher";
import { Checkbox } from "../../../../../uiKit";

export const UpdateNoteCreateHeader = (props: IUpdateNoteCreateHeader) => {
  return (
    <div className="d-stack-row align-center justify-space-between full-width">
      <LocaleSwitcher currentLocale={props.currentLocale} onChangeLocale={props.onChangeLocale} />
      <Checkbox
        checked={props.isHideUpdateModal}
        onChange={(e) => props.onChangeIsHideUpdateModal(e.target.checked)}>
        Публикация без модального окна
      </Checkbox>
    </div>
  );
};
