import React, { forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { IIssueFormView } from "../common/IssueForm.interface";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import SubheaderText from "../../../../../elements/subheaderText/SubheaderText";
import {
  Button,
  DatePicker,
  Icon,
  ListItem,
  Select,
  SelectOption, Tag,
  TextArea,
  TimeSpanPicker
} from "../../../../../uiKit";
import { blockedFieldsKeys, SwitchFieldType } from "../../../types/consts";
import { AiOutlinePaperClip } from "@react-icons/all-files/ai/AiOutlinePaperClip";
import FileUploader from "../../../../../service/fileUploader/FileUploader";
import { AutocompleteAsync } from "../../../../../elements/autocompleteAsync/AutocompleteAsync";
import { useApi, useRootStore } from "../../../../../../hooks";
import { IssueDto, IssueProofRequirementDto } from "../../../../../../api";
import IssueProofForm from "../../../issueProofForm/IssueProofForm";
import { IssueContext } from "../../../../../../contexts/communication/issueContext";
import IssueCustomFieldsForm from "../../../issueCustomFieldsForm/IssueCustomFieldsForm";
import { OrgchartSwitcher } from "../../../../../elements/orgchartSwitcher/OrgchartSwitcher";
import { WarningsKeys } from "../../utils/keys/warningsKeys";
import dayjs from "dayjs";
import { GlobalAuthorizedContext } from "../../../../../../contexts/globalAuthorizedContext";
import { TextEditor } from "../../../../textEditor/TextEditor";
import { ToolbarType } from "../../../../textEditor/types/types";
import { initialEditorContent } from "../../../../../../utils/textEditor";
import { InputWithPriorities } from "../../../../pages/communications/components/inputWithPriorities/InputWithPriorities";
import { AutocompleteUserModel } from "../../../../../../api/models/AutocompleteModel";
import debounce from "lodash/debounce";
import { permissionKeys } from "../../../../../../utils/permissions";
import { SelectTags } from "../../../../../uiKit/selectTags/SelectTags";
import { api } from "../../../../../../services";
import { TagDto } from "../../../../../../api/models/TagDto";
import { ISelectTagOption } from "../../../../../uiKit/selectTags/ISelectTags";

const APPROVAL = "default.v1.status.approval";
const NOT_APPROVED = "default.v1.status.not_approved";
const REJECT = "default.v1.status.rejected";
const DONE = "default.v1.status.done";


export const TaskFormView = forwardRef((props: IIssueFormView, ref) => {
  const { orgchartStore, authStore } = useRootStore();
  const [isValidationErrorExecutor, setIsValidationErrorExecutor] = useState(false);
  const { t } = useTranslation();
  const globalAuthorizedContext = useContext(GlobalAuthorizedContext);
  const currentUserId = authStore.getInitialInfo?.identity?.id;

  const { issueType } = useContext(IssueContext);

  const defaultOrgchartId = useMemo(
    () => props.updater?.currentState?.orgchartId,
    [props.updater?.currentState?.orgchartId]
  );

  const handleFinish = () => !isValidationErrorExecutor && props.onCreate && props.onCreate();

  const isAutoChangeUserRole = useRef<boolean>(false);

  const [currentTask, setCurrentTask] = useState<IssueDto>({
    name: props.updater.currentState?.name ?? undefined,
    description:  props.updater.currentState?.description ?? undefined,
    awaitedResult: props.updater?.currentState?.awaitedResult ?? "",
    tags: props.updater?.currentState?.tags ?? []
  });
  const prevCurrentTask = useRef<IssueDto | null>(null);

  const applyChanges = useCallback((newValue) => {
    setCurrentTask((prev) => ({
      ...prev,
      ...newValue
    }))
  }, []);

  useEffect(() => {
    if (prevCurrentTask.current) {
      props.updater?.applyChanges(currentTask);
      props.onUpdate();
    }
    prevCurrentTask.current = currentTask;
  }, [currentTask]);

  const onChangeDescription = useMemo(() => {
    return debounce((description) => applyChanges({ description }), 1000)
  }, []);

  const onChangeName = useMemo(() => {
    return debounce((event) => {
      !props.isUpdateForm
        ? applyChanges({name: event.target.value})
        : event.target.value.trim()?.length > 0 && applyChanges({name: event.target.value})
    }, 1000)
  }, [])

  const onChangeAwaitedResult = useMemo(() => {
    return debounce((event: any) => {
      applyChanges({ awaitedResult: event.target.value })
    }, 1000);
  }, []);

  const proofAttachmentsList = useMemo(() => {
    return (props.updater.currentState?.proof?.history?.comment?.attachments ?? [])
      .map((item) => item.file!);
  }, [props.updater.currentState?.proof?.history?.comment?.attachments?.length]);

  const tags = useApi(
    () =>
      api.tags.getAll({
        pageSize: -1,
        createByUserId: currentUserId,
      }),
    null,
    () => {},
    {},
    false
  );

  const refetchTags = useCallback(() => {
    return tags.fetch();
  }, []);

  useEffect(() => {
    refetchTags();
  }, []);

  const tagsList = useMemo(() => {
    return tags.value?.items ?? [];
  }, [tags.value?.items]);

  //
  // const [tagsList, setTagsList] = useState<TagDto[]>([]);
  // useEffect(() => {
  //   setTagsList(tags.value?.items ?? []);
  // }, [tags.value?.items]);

  const handleChangeTags = useCallback(async (data: any) => {
    const { addedTags = [],  removedTags = [] } = data;
    const addedTagsList: TagDto[] = addedTags.filter((tag: ISelectTagOption) => tag.id).map((tag: ISelectTagOption) => ({
      id: tag.id,
      name: tag.value,
    }));
    const uniqTags: TagDto[] = [];
    addedTagsList.forEach((tag) => {
      if (!(props.updater.currentState?.tags ?? []).find((item) => item.id === tag.id)) {
        uniqTags.push(tag);
      }
    });

    if (props.isUpdateForm) {
      if (removedTags.length) {
        const removedTagsTagIds = removedTags.join(",");
        const issue = await api.issue.deleteTags({
          id: props.issueId,
          tagIds: removedTagsTagIds,
        });
        if (!uniqTags.length && issue) {
          const { tags = [] } = issue;
          props.updater.applyChanges({
            tags,
            // tagIds,
          })
        }
      }

      if (uniqTags.length) {
        const tagIds = uniqTags.map((tag) => tag.id).join(",");
        const issue = await api.issue.addedTags({
          id: props.issueId,
          tagIds,
        });
        if (issue) {
          const { tags = [] } = issue;
          props.updater.applyChanges({
            tags,
          })
        }
      }
    } else {
      applyChanges({
        tags: addedTagsList.map(({ id, name }) => ({ id, name })),
      })
    }
    // const tagIds = tagIdsArr.join(",");
    // const newTags = addedTags.filter((tag: ISelectTagOption) => !tag.id);
    // console.log("newTags", newTags);
    // const tagIdsArr = addedTags.filter((tag: ISelectTagOption) => tag.id).map((tag: ISelectTagOption) => tag.id);
    // const tagIds = tagIdsArr.join(",");
    // console.log("tagIds", tagIds);
    // const status = await api.issue.addedTags({
    //   id: props.issueId,
    //   tagIds,
    // });
    // console.log("status", status);

  }, [props.issueId, props.updater.currentState?.tags]);

  return (
    <Form
      name="task"
      className="flex-grow-1"
      style={{ overflow: "auto", maxWidth: props.isUpdateForm ? 547 : "100%" }}
      form={props.form}
      onFinish={handleFinish}
      autoComplete="off"
    >
      <div className="d-stack-row full-width">
        <div className="flex-grow-1">
          <SubheaderText text={t("ui:subheader.issue_name")} />
          <Form.Item
            className="mb-3 full-width"
            name="name"
            initialValue={currentTask.name}
            rules={[
              {
                required: true,
                message: t("validation:field_required"),
              },
            ]}
          >
            <TextArea
              autoSize
              style={{ borderRadius: "8px 0 0 8px" }}
              className="mb-1 full-width"
              placeholder={t("ui:placeholder.issue_name")}
              value={currentTask.name ?? undefined}
              onChange={onChangeName}
              readOnly={props.onCheckToAvailability(blockedFieldsKeys.name)}
            />
          </Form.Item>
        </div>
        <div>
          <SubheaderText text={t("ui:subheader.issue_priority")} />
          <InputWithPriorities
            isUpdateForm={props.isUpdateForm}
            value={props.updater.currentState?.priority}
            readOnly={props.onCheckToAvailability(blockedFieldsKeys.name)}
            onChange={(value: number) => {
              !props.isUpdateForm
                ? props.updater.applyChanges({ priority: value })
                : value && props.updater.applyChanges({ priority: value });
              props.onUpdate();
            }}
          />
        </div>
      </div>
      <SubheaderText text={t("ui:subheader.issue_description")} />
      {/*<TextFieldLinkified*/}
      {/*  value={props.updater.currentState?.description ?? ""}*/}
      {/*  setValue={(newDescription) => props.updater.applyChanges({ description: newDescription })}*/}
      {/*  placeholder={t("ui:placeholder.issue_description")}*/}
      {/*  onUpdate={props.onUpdate}*/}
      {/*  readonly={props.onCheckToAvailability(blockedFieldsKeys.description)}*/}
      {/*/>*/}
      <TextEditor
        id={"issue-form-description-editor" + props.issueId}
        value={
          currentTask.description && currentTask.description !== ""
            ? currentTask.description
            : initialEditorContent
        }
        initialValue={
          currentTask.description && currentTask.description !== ""
            ? currentTask.description
            : initialEditorContent
        }
        onChange={onChangeDescription}
        placeholder={t("ui:placeholder.issue_description")}
        readOnly={props.onCheckToAvailability(blockedFieldsKeys.description)}
        toolbarType={ToolbarType.Dynamic}
        isSimplifiedToolbar
        variant="bordered"
        // isHidingByClick
      />
      <div className="d-stack-row justify-space-between align-center mb-2 mt-1">
        <SubheaderText
          noBottomOffset
          text={`${t("ui:subheader.attachments")} ${`(${props.attachments?.length ?? 0})`}`}
        />
        <Button
          /*@ts-ignore*/
          onClick={() => props.uploadFilesInputRef?.current?.click()}
          icon={<Icon component={() => <AiOutlinePaperClip />} />}
          style={{ fontSize: "12px", fontWeight: "14px" }}
          size="small"
          type="text"
          disabled={props.onCheckToAvailability(blockedFieldsKeys.attachments)}
        >
          {t("ui:button.attach")}
        </Button>
      </div>
      <FileUploader
        maxSizeMb={40}
        // accept="image/*"
        // readonly
        noDragAndDrop
        noDelete={props.onCheckToAvailability(blockedFieldsKeys.attachments)}
        relatedInputId="issue-attachments-area-ctrlv-communications"
        value={props.attachments}
        // value={(updater.currentState?.attachments?.map((x) => x.file) as StaticFileDto[]) ?? undefined}
        onUpload={(data) => (props.isFileLoaderUploadingCopy.current ? undefined : props.setAttachmentsIssueSync(data))}
        onValueItemDelete={props.onDeleteAttachmentsByFileId}
        onAnyItemDelete={props.onDeleteAttachmentsByFileId}
        uploadFilesInputRef={props.uploadFilesInputRef}
        filesContainerMaxHeight="35vh"
        onUploadStatusChange={props.setIsFileLoaderUploadingSync}
        hideInfo
      />
      {/*{props.attachments && props.attachments.length === 0 && (*/}
      {/*  <Input autoComplete="off" name="empty" value={t("text:no_attachments")} />*/}
      {/*)}*/}
      <div className="mt-3" />
      {/*<SubheaderText text={helperStore.getCompanyGlossaryItem("r")} />*/}
      <SubheaderText
        text={orgchartStore.getOrgchartResultNameByOrgchartId(props.updater.currentState?.orgchartId ?? 0)}
      />
      <Form.Item
        className="mb-3 full-width"
        name="awaitedResult"
        initialValue={currentTask.awaitedResult}
      >
        <TextArea
          autoSize
          // style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
          // className="mb-3"
          readOnly={props.onCheckToAvailability(blockedFieldsKeys.awaitedResult)}
          // autoComplete="off"
          placeholder={`${t("ui:placeholder.enter")} ${orgchartStore.getOrgchartResultNameByOrgchartId(
            props.updater.currentState?.orgchartId ?? 0
          )}`}
          value={currentTask.awaitedResult ?? ""}
          onChange={onChangeAwaitedResult}
        />
      </Form.Item>
      {(!!props.updater.currentState?.proof?.history?.comment?.text || proofAttachmentsList.length > 0)
        && (
          props.updater.currentState?.status?.key === APPROVAL ||
          props.updater.currentState?.status?.key === NOT_APPROVED ||
          props.updater.currentState?.status?.key === REJECT ||
          props.updater.currentState?.status?.key === DONE
        )
        && (
        <div className="my-5">
          <SubheaderText text={t("ui:subheader.issue_fact_result")} />
          {!!props.updater.currentState?.proof?.history?.comment?.text && (
            <TextEditor
              value={
                props.updater.currentState?.proof?.history?.comment?.text ?? undefined
              }
              onChange={onChangeDescription}
              placeholder={t("ui:placeholder.issue_description")}
              readOnly={true}
              isSimplifiedToolbar
              variant="bordered"
            />
          )}

          {proofAttachmentsList.length > 0 && (
            <ListItem className={"mt-3"}>
              <div className="full-width no-select">
                <FileUploader
                  noDragAndDrop
                  value={proofAttachmentsList}
                  hideInfo
                  smallSize
                  noDelete={true}
                  containerClass={"issue-card-history__files-container"}
                />
              </div>
            </ListItem>
            )}
        </div>
      )}
      <div className="my-5">
        {props.updater?.currentState?.fields && props.updater?.currentState?.fields.length > 0 && (
          <IssueCustomFieldsForm
            type={issueType ?? undefined}
            fieldKey={SwitchFieldType.isManagerApprovalRequired}
            fieldsArray={props.updater.currentState.fields ?? props.customFields}
            value={props.updater?.currentState?.fields}
            onChange={(fields) => {
              if (fields.find((f) => f.key === SwitchFieldType.isManagerApprovalRequired)?.valueBool === true) {
                props.updater.applyChanges({ fields: fields, timePlanForApproval: "00:15:00" });
                props.isUpdateForm && props.customFieldsIsChanged(fields) && props.onAsyncUpdate();
              } else {
                props.updater.applyChanges({ fields: fields, timePlanForApproval: undefined });
                props.isUpdateForm && props.customFieldsIsChanged(fields) && props.onAsyncUpdate();
              }
            }}
            elementsState={[
              {
                key: blockedFieldsKeys.isStrictDeadline,
                disabled: props.onCheckToAvailability(blockedFieldsKeys.isStrictDeadline),
              },
              {
                key: blockedFieldsKeys.isManagerApprovalRequired,
                disabled: props.onCheckToAvailability(blockedFieldsKeys.isManagerApprovalRequired),
              },
            ]}
          />
        )}
      </div>
      {props.updater.currentState?.fields?.find((f) => f.key === SwitchFieldType.isManagerApprovalRequired)
        ?.valueBool && (
        <div className="d-stack-column spacing-3">
          <IssueProofForm
            readOnly={props.onCheckToAvailability(blockedFieldsKeys.proofRequirement)}
            data={props.updater.currentState?.proofRequirement ?? null}
            onChange={(data: IssueProofRequirementDto) =>
              props.updater.applyChanges({
                proofRequirement: data,
                proofRequirementId: data == null ? null : props.updater.currentState?.proofRequirementId,
              })
            }
            onBlur={props.onUpdate}
          />
          {props.updater.currentState.initiatorUserId === authStore.getInitialInfo?.identity?.id && (
            <>
              {/*<div>*/}
              {/*  <SubheaderText text={t("ui:subheader.date_work_start_for_approval")} />*/}
              {/*  <DatePicker*/}
              {/*    className="full-width"*/}
              {/*    value={*/}
              {/*      props.updater?.currentState?.dateWorkStartForApproval*/}
              {/*        ? dayjs(props.updater?.currentState?.dateWorkStartForApproval)?.tz(undefined, false)*/}
              {/*        : null*/}
              {/*    }*/}
              {/*    onChange={(value) => {*/}
              {/*      console.log("value", value);*/}
              {/*      props.updater.applyChanges({ dateWorkStartForApproval: value });*/}
              {/*      props.onAsyncUpdate();*/}
              {/*    }}*/}
              {/*    allowClear*/}
              {/*    size="large"*/}
              {/*    showTime={{ format: "HH:mm" }}*/}
              {/*  />*/}
              {/*</div>*/}
              <div className="d-stack-row justify-space-between align-start">
                <div style={{
                  width: "48%"
                }}>
                  <SubheaderText text={t("ui:subheader.issue_time_for_approval")} />
                  <TimeSpanPicker
                    // disabled={props.onCheckToAvailability(blockedFieldsKeys.timePlan)}
                    size="large"
                    className="full-width"
                    //onBlur={props.onAsyncUpdate}
                    value={props.updater.currentState?.timePlanForApproval}
                    onChange={(value) => props.onPickerChange("timePlanForApproval", value ?? null)}
                    // onChange={(date) =>
                    //   props.updater.applyChanges({ timePlan: date ? generateTimeSpan({ dateObject: date }) : undefined })
                    // }
                  />
                </div>
                <div style={{
                  width: "48%"
                }}>
                  <SubheaderText text={t("ui:subheader.issue_time_fact_for_approval")} />
                  <TimeSpanPicker
                    // disabled={props.onCheckToAvailability(blockedFieldsKeys.timePlan)}
                    size="large"
                    className="full-width"
                    //onBlur={props.onAsyncUpdate}
                    value={props.updater.currentState?.timeFactForApproval}
                    onChange={(value) => props.onPickerChange("timeFactForApproval", value ?? null)}
                    // onChange={(date) =>
                    //   props.updater.applyChanges({ timePlan: date ? generateTimeSpan({ dateObject: date }) : undefined })
                    // }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <div className="mt-3">
        <SubheaderText text={t("ui:subheader.tags")} />
        <SelectTags
          tags={tagsList}
          onChange={handleChangeTags}
          selectedTags={props.updater?.currentState?.tags ??[]}
          refetchTags={refetchTags}
        />

      </div>
      {orgchartStore.getOrgchartsList.length > 1 && (
        <>
          <SubheaderText className="mt-3" text={t("ui:subheader.issue_orgachart_company")} />
          {/*<Form.Item*/}
          {/*  className="full-width"*/}
          {/*  name="orgchartId"*/}
          {/*  // initialValue={defaultOrgchartId}*/}
          {/*  rules={[*/}
          {/*    {*/}
          {/*      required: true,*/}
          {/*      message: t("validation:field_required"),*/}
          {/*    },*/}
          {/*  ]}*/}
          {/*>*/}
          <OrgchartSwitcher
            value={defaultOrgchartId}
            onChange={(value) => props.updater.applyChanges({ orgchartId: +value, roleId: undefined })}
            selectProps={{
                style: { width: "100%", marginBottom: 4 },
                placeholder: t("ui:placeholder.click_to_select"),
                defaultValue: { defaultOrgchartId },
                onDropdownVisibleChange: (visible) => !visible && props.onAsyncUpdate(),
                disabled: props.onCheckToAvailability(blockedFieldsKeys.orgchart),
              }}
              drawType="select"
            />
          {/*</Form.Item>*/}
        </>
      )}
      <div className="mt-3 d-stack-row justify-space-between align-start">
        <div style={{ width: "48%" }}>
          <SubheaderText text={t("ui:subheader.issue_executor")} />
          <Form.Item
            className="full-width"
            validateStatus={isValidationErrorExecutor ? "error" : undefined}
            help={isValidationErrorExecutor ? t("validation:field_required") : undefined}
            rules={[{ required: true, message: t("validation:field_required") }]}
          >
            <AutocompleteAsync
              className="mb-1 full-width"
              requestOptions={{
                orderBy: "issues_created",
                order: "desc",
                // OrgchartIds: [props.updater.currentState?.orgchartId],
              }}
              // requestOptions={{ orderBy: "issues_created" }}
              // queryUri="potential-executors"
              type="user"
              size="large"
              mode={!props.isUpdateForm ? "multiple" : undefined}
              disabled={props.onCheckToAvailability(blockedFieldsKeys.executorUserId)}
              minOptionsCount={!props.isUpdateForm ? 1 : undefined}
              additionalItems={[
                {
                  id: props.updater?.currentState?.executorUserId,
                  text:
                    props.updater?.currentState?.executorUser?.nameShort ??
                    props.updater?.currentState?.executorUser?.nameFallback,
                  avatarText: [
                    props.updater?.currentState?.executorUser?.lastName,
                    props.updater?.currentState?.executorUser?.firstName,
                  ],
                  avatar: props.updater?.currentState?.executorUser?.avatar,
                  color: props.updater?.currentState?.executorUser?.color ?? "",
                  disabled: !props.executorIds?.length,
                },
              ]}
              placeholder={t("ui:placeholder.click_to_select")}
              value={props.isUpdateForm ? props.updater?.currentState?.executorUserId ?? undefined : props.executorIds}
              onChange={
                (value, data) => {
                  const { avatar,  avatarText, text: nameShort, color, id } = data as AutocompleteUserModel ?? {};
                  const [lastName, firstName] = avatarText ?? [];
                  const executorUser = { avatar, nameShort, color, lastName, firstName, id }
                  if (props.isUpdateForm) {

                    isAutoChangeUserRole.current = true;
                    props.onChangeExecutor!(
                      props.updater?.currentState?.executorUser?.id!,
                      value,
                      props.updater?.currentState?.executorUser,
                      executorUser
                    );
                    // props.updater.applyChanges({ executorUser });
                  } else {
                    // if ((value ?? []).length == 0) setIsValidationErrorExecutor(true);
                    // else setIsValidationErrorExecutor(false);
                    if ((value ?? []).length > 1 && props.updater?.currentState?.roleId)
                      props.updater.applyChanges({ roleId: undefined });
                    props.onChangeExecutorIds && props.onChangeExecutorIds(value);
                  }
                }
                // props.updater.applyChanges({ executorUserId: value })
              }
              onDropdownVisibleChange={(value: boolean) => {
                if (
                  !props.isUpdateForm &&
                  value &&
                  props.executorIds?.length === 1 &&
                  props.executorIds?.includes(currentUserId!)
                ) {
                  props.onChangeExecutorIds?.([]);
                }
                if (!props.isUpdateForm && !value && !props.executorIds?.length) {
                  props.onChangeExecutorIds?.([currentUserId!]);
                }
              }}
            />
          </Form.Item>
        </div>
        <div style={{ width: "48%" }}>
          <SubheaderText text={t("ui:subheader.issue_role")} />
          <AutocompleteAsync
            className="mb-3 full-width"
            dropdownStyle={{ zIndex: 1202, maxWidth: 259.3 }}
            type="role"
            size="large"
            placeholder={t("ui:placeholder.click_to_select")}
            allowClear={true}
            disabled={
              props.onCheckToAvailability(blockedFieldsKeys.roleId) ||
              (props.isUpdateForm
                ? !props.updater?.currentState?.executorUserId
                : (props.executorIds ?? []).length > 1 || !props.executorIds?.length)
            }
            additionalItems={
              props.updater.currentState?.role && [
                {
                  id: props.updater.currentState?.role?.id,
                  text: props.updater.currentState?.role?.name,
                },
              ]
            }
            requestOptions={{
              orderBy: "text",
              order: "asc",
              orgchartId: props.updater.currentState?.orgchartId,
              userIds: props.isUpdateForm
                ? props.updater?.currentState?.executorUserId
                  ? [props.updater?.currentState?.executorUserId]
                  : undefined
                : (props.executorIds ?? []).length === 1
                ? props.executorIds
                : undefined,
            }}
            onChange={(id) => {
              props.updater.applyChanges({ roleId: id });
              if (!id) return props.onAsyncUpdate();
            }}
            onRequestCallback={(id) => {
              if (!(props.onCheckToAvailability(blockedFieldsKeys.roleId) ||
                (props.isUpdateForm
                  ? !props.updater?.currentState?.executorUserId
                  : (props.executorIds ?? []).length > 1 || !props.executorIds?.length)) && (
                !props.isUpdateForm || isAutoChangeUserRole.current
              )) {
                props.updater.applyChanges({ roleId: id });
              }
            }}
            value={props.updater?.currentState?.roleId ?? undefined}
            onDropdownVisibleChange={(visible) => !visible && props.onAsyncUpdate()}
          />
        </div>
      </div>
      <div className="d-stack-row justify-space-between align-start">
        <div style={{ width: "48%" }}>
          <SubheaderText text={t("ui:subheader.executor_deadline")} />
          <DatePicker
            disabled={props.onCheckToAvailability(blockedFieldsKeys.dateWorkStart)}
            className="full-width"
            value={
              props.updater?.currentState?.dateWorkStart
                ? dayjs(props.updater?.currentState?.dateWorkStart)?.tz(undefined, false)
                : null
            }
            onChange={(value) =>
              props.onChangeExecutorDeadline!({
                prevValue: props.updater.currentState?.dateWorkStart,
                nextValue: value?.toDate() ?? null,
                warningKey: WarningsKeys.executorDeadline,
              })
            }
            allowClear
            size="large"
            showTime={{ format: "HH:mm" }}
          />
        </div>
        <div style={{ width: "48%" }}>
          <SubheaderText text={t("ui:subheader.deadline")} />
          <DatePicker
            disabled={props.onCheckToAvailability(blockedFieldsKeys.dateDeadline)}
            className="full-width"
            value={
              props.updater?.currentState?.dateDeadline
                ? dayjs(props.updater?.currentState?.dateDeadline)?.tz(undefined, false)
                : null
            }
            // TODO: check toIso
            onChange={(value) => {
              props.onPickerChange("dateDeadline", value?.toISOString() ?? null)
            }}
            //onChange={(value) => props.updater.applyChanges({ dateDeadline: value })}
            allowClear
            size="large"
            showTime={{
              format: "HH:mm",
              defaultValue: dayjs('18:00', 'HH:mm'), //TODO установить время окончания рабочего времени компании
            }}
            // maxDate={
            //   props.updater?.currentState?.calculated?.dateWorkStartTo
            //     ? new Date(props.updater?.currentState?.calculated?.dateWorkStartTo)
            //     : undefined
            // }
            //onBlur={props.onAsyncUpdate}
          />
        </div>
      </div>
      <div className="my-5">
        {props.updater?.currentState?.fields && props.updater?.currentState?.fields.length > 0 && (
          <IssueCustomFieldsForm
            type={issueType ?? undefined}
            fieldKey={SwitchFieldType.isStrictDeadline}
            fieldsArray={props.updater.currentState.fields ?? props.customFields}
            value={props.updater?.currentState?.fields}
            onChange={(fields) => {
              props.updater.applyChanges({ fields: fields });
              props.isUpdateForm && props.customFieldsIsChanged(fields) && props.onAsyncUpdate();
            }}
            elementsState={[
              {
                key: blockedFieldsKeys.isStrictDeadline,
                disabled: props.onCheckToAvailability(blockedFieldsKeys.isStrictDeadline),
              },
              {
                key: blockedFieldsKeys.isManagerApprovalRequired,
                disabled: props.onCheckToAvailability(blockedFieldsKeys.isManagerApprovalRequired),
              },
            ]}
          />
        )}
      </div>
      <div className="d-stack-row align-center justify-space-between mb-3">
        <div style={{ width: "48%" }}>
          <SubheaderText text={t("ui:planning_table.plan")} />
          <TimeSpanPicker
            disabled={props.onCheckToAvailability(blockedFieldsKeys.timePlan)}
            size="large"
            className="full-width"
            value={props.updater.currentState?.timePlan}
            onChange={(value) => props.onPickerChange("timePlan", value ?? null)}
          />
        </div>
        <div style={{ width: "48%" }}>
          <SubheaderText text={t("ui:planning_table.fact")} />
          <TimeSpanPicker
            disabled={props.onCheckToAvailability(blockedFieldsKeys.timeFact) || !props.isUpdateForm}
            size="large"
            className="full-width"
            value={props.updater.currentState?.timeFact}
            onChange={(value) => props.onPickerChange("timeFact", value ?? null)}
          />
        </div>
      </div>
      <SubheaderText text={t("ui:subheader.issue_sender")} />
      <AutocompleteAsync
        className="mb-1 full-width"
        dropdownStyle={{ zIndex: 1202 }}
        requestOptions={{
          orderBy: "issues_created",
          order: "desc",
          // OrgchartIds: [props.updater.currentState?.orgchartId],
        }}
        // requestOptions={{ orderBy: "issues_created" }}
        type="user"
        // queryUri="potential-initiators"
        size="large"
        allowClear={false}
        disabled={props.onCheckToAvailability(blockedFieldsKeys.initiatorUserId)}
        additionalItems={[
          {
            id: props.updater?.currentState?.initiatorUserId ?? undefined,
            text:
              props.updater?.currentState?.initiatorUser?.nameShort?.trim() ||
              props.updater?.currentState?.initiatorUser?.nameFallback?.trim() ||
              props.updater?.currentState?.initiatorUser?.name?.trim(),
            avatarText: [
              props.updater?.currentState?.initiatorUser?.lastName,
              props.updater?.currentState?.initiatorUser?.firstName,
            ],
            avatar: props.updater?.currentState?.initiatorUser?.avatar,
            color: props.updater?.currentState?.initiatorUser?.color ?? "",
          },
        ]}
        placeholder={t("ui:placeholder.click_to_select")}
        value={props.updater?.currentState?.initiatorUserId ?? undefined}
        onChange={(value, data) => {
          const { avatar,  avatarText, text: name, color, id } = data as AutocompleteUserModel ?? {};
          const [lastName, firstName] = avatarText ?? [];
          const user = { avatar, name, color, lastName, firstName, id }
          props.updater.applyChanges({ initiatorUserId: +value,  initiatorUser: user });
        }}
        onDropdownVisibleChange={(visible) => !visible && props.onAsyncUpdate()}
      />
      {/*<Form.Item
        className="full-width"
        name="orgchartId"
        initialValue={props.updater?.currentState?.orgchartId}
        rules={[
          {
            required: true,
            message: t("validation:field_required"),
          },
        ]}
      >
        <Select
          defaultValue={defaultOrgchartId}
          onDropdownVisibleChange={(visible) => !visible && props.onAsyncUpdate()}
          dropdownStyle={{ zIndex: 1202 }}
          value={props.updater?.currentState?.orgchartId?.toString() ?? undefined}
          disabled={props.onCheckToAvailability(blockedFieldsKeys.orgchart)}
          placeholder={t("ui:placeholder.click_to_select")}
          onChange={(value) => props.updater.applyChanges({ orgchartId: +value })}
          style={{ width: "100%" }}
        >
          {authStore.getInitialInfo?.orgcharts?.map((orgchart) => (
            <SelectOption value={(orgchart.id ?? 0).toString()}>{orgchart.name}</SelectOption>
          ))}
        </Select>
      </Form.Item>*/}
      <SubheaderText className="mt-3" text={t("ui:subheader.issue_participants")} />
      <AutocompleteAsync
        style={{ minWidth: "100%", maxWidth: 570 }}
        dropdownStyle={{ zIndex: 1202 }}
        className="mb-3 full-width"
        requestOptions={{ orderBy: "issues_created" }}
        type="user"
        placeholder={t("ui:placeholder.click_to_select")}
        mode="multiple"
        size="large"
        onDropdownVisibleChange={(visible) => !visible && props.onAsyncUpdate()}
        // disabled={props.onCheckToAvailability(blockedFieldsKeys.participants)}

        isSelectedDisabled={props.onCheckToAvailability(blockedFieldsKeys.participants)}
        additionalItems={
          // console.log(props.onCheckToAvailability(blockedFieldsKeys.participants))
          // props.isUpdateForm && props.onCheckToAvailability(blockedFieldsKeys.participants)
          props.isUpdateForm
          ? props.updater?.currentState?.participants?.map((item) => {
            const { userId: id, user } = item;
            const disabled =  props.isUpdateForm && !props.onCheckToAvailability(blockedFieldsKeys.participants)
              ? false
              : id !== currentUserId;
            const {
              nameShort = '',
              nameFallback = '',
              name = '',
              lastName = '',
              firstName = '',
              avatar,
              color = ''
            } = user ?? {}
            return {
              id,
              disabled: props.isUpdateForm ? disabled : false,
              text:
                nameShort?.trim() ||
                nameFallback?.trim() ||
                name?.trim(),
              avatarText: [
               lastName,
               firstName,
              ],
              avatar,
              color: color ?? "",
            }
            }) ?? []
          : []
        }
        defaultValue={props.updater?.currentState?.participants?.map((item) => item.userId) ?? []}
        onChange={(arrParticipants: number[]) =>
          props.isUpdateForm
            ? props.setParticipantsWithUpdater!(arrParticipants)
            : props.updater.applyChanges({
              participants: arrParticipants.map((id) => ({
                userId: Number(id),
                user: { id },
              })),
            })
        }
      />
    </Form>
  );
});
