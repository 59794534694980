import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getObjectFromTimeSpan } from "../../../../../../../helpers/dateFunctions";
import { removeLeadingZerosFromString } from "../../../../../../../helpers/stringFunctions";
import { useColorSchemeStyles } from "../../../../../../../hooks/useColorSchemeStyles";
import { colorSchemeType } from "../../../../../../../utils/colorScheme";
import { UserCard } from "../../../../../../elements/userCard/UserCard";
import { Card, Text } from "../../../../../../uiKit";
import { IPlanningEmployeeCard } from "./PlanningEmployeeCard.interface";
import "./PlanningEmployeeCard.scss";
import { EmployeeRolesList } from "../../../../employee/employeeRolesList/EmployeeRolesList";
import { formatDateToDateString } from "../../../../../../../helpers/formatFunctions";
import { Progress } from "antd";

export const PlanningEmployeeCard = (props: IPlanningEmployeeCard) => {
  const { t } = useTranslation();
  const url = useLocation();
  const { findColorByKey } = useColorSchemeStyles();
  const colorScheme = findColorByKey(props.plan?.statusColorScheme as colorSchemeType);

  const calculateTimePlan = () => {
    return props.plan?.timePlanOverall != null
      ? Math.ceil((props.plan.timePlanOverallTotalMinutes as number) / 60)
      : 0;
  };

  const calculateTimeFact = () => {
    return (
      props.plan?.timeFactOverall != null &&
      removeLeadingZerosFromString(getObjectFromTimeSpan(props.plan?.timeFactOverall).totalHours!)
    );
  };

  return (
    <Card
      isShadowed={false}
      className={props.plansLength == 1 ? "" : "br-none"}
      clickable
      onClick={() => props.onRedirectToPlan(props.plan.id!)}
    >
      <div className="d-stack spacing-10 align-center planning-subordinates-card__container">
        <div className="flex-grow-1 flex-shrink-1">
          <UserCard
            userInfo={props.plan.user}
            avatarSize={55}
            fullWidth
            additionalInfo={
              <div className="mt-1">
                <EmployeeRolesList roles={props.plan.user?.roles ?? []} maxOrgchartsCount={4} />
                {url.pathname == "/communication/require-approval" ? (
                  <span style={{ color: "#8E939D" }}>{`${formatDateToDateString(
                    new Date(props.plan.dateFrom as string)
                  )} - ${formatDateToDateString(new Date(props.plan.dateTo as string))}`}</span>
                ) : null}
              </div>
            }
          />
        </div>
        <div className="flex-grow-0 flex-shrink-0" style={{ flexBasis: "20%" }}>
          <div className="d-stack-column">
            <Text>{t("ui:subheader.issues")}</Text>
            <Progress
              style={{ fontSize: "12px" }}
              percent={(props.plan?.issuesDone! / props.plan?.issuesOverall!) * 100 ?? 0}
              format={() => `${props.plan.issuesDone}/${props.plan.issuesOverall}`}
              className="mt-2"
              status={props.plan?.issuesDone! > props.plan?.issuesOverall! ? "exception" : "normal"}
            />
          </div>
        </div>
        <div className="flex-grow-0 flex-shrink-0" style={{ flexBasis: "20%" }}>
          <div className="d-stack-column">
            <Text>{t("ui:subheader.time")}</Text>
            {/*TODO: Replace via uiProgress*/}
            <Progress
              style={{ fontSize: "12px" }}
              percent={(Number(calculateTimeFact()) / Number(calculateTimePlan())) * 100 ?? 0}
              format={() => `${calculateTimeFact()}/${calculateTimePlan()}`}
              className="mt-2"
              status={calculateTimeFact() > calculateTimePlan() ? "exception" : "normal"}
            />
          </div>
        </div>
        {/*TODO: Remove and change to UiChip or something else*/}
        <div
          style={{ background: colorScheme.backgroundColor, color: colorScheme.textColor }}
          className="planning-subordinates-card__indicator d-flex justify-center align-center"
        >
          {props.plan?.statusName}
        </div>
      </div>
    </Card>
  );
};
