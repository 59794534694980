import React, { useMemo, useState, useCallback, useEffect } from "react";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { IRegulationHistoryListCards } from "./RegulationHistory.interface";
import { RegulationHistoryCard } from "./RegulationHistoryCard";
import { useRootStore } from "../../../../../../hooks";
import { RegulationHistoryDto } from "../../../../../../api";
import { api } from "../../../../../../services";
import { initialEditorContent } from "../../../../../../utils/textEditor";

const RegulationHistoryListCardsMemo: React.FC<IRegulationHistoryListCards> = (props) => {
  const { authStore } = useRootStore();
  const user = useMemo(() => {
    return authStore.getInitialInfo?.identity ?? undefined
  }, [authStore.getInitialInfo?.identity]);

  const [editCommentId, setEditCommentId] = useState<number>(-1);

  const handleEditClick = useCallback((id) =>{
    setEditCommentId(id)
  }, []);

  const handleSaveClick = useCallback((id: number, data: RegulationHistoryDto) => {
    props.handleUpdateComment(id, data);
    setEditCommentId(-1);
  }, [props.historyItems]);


  return (
    <div className="regulation-history__list spacing-4 d-stack-column justify-start align-center">
      {props.historyItems.map((item, index) => (
        <React.Fragment key={item.id}>
          <RegulationHistoryCard
            key={item.id}
            historyId={item.id!}
            index={index}
            item={item}
            dateCreated={item.dateCreated!}
            reactions={item.reactions ?? []}
            creatorId={item.createdByUserId!}
            creator={item.createdByUser!}
            isEditing={item.createdByUserId === user?.id}
            editCommentId={editCommentId}
            commentContent={item.comment?.content ?? ""}
            handleEditClick={handleEditClick}
            handleSaveClick={handleSaveClick}
            handleRemoveComment={props.handleRemoveComment}
          />
        </React.Fragment>
      ))}
      {props.historyItems.length !== 0 && (
        <ScrollTrigger
          fullWidth
          disabled={props.isDone}
          onIntersection={props.loadNext}
          marginTop={props.historyItems.length > 0}
          hidden={props.isDone}
        />
      )}


    </div>
  );
};

export const RegulationHistoryListCards = React.memo(RegulationHistoryListCardsMemo);
