import React from "react";
import { TooltipView } from "./TooltipView";
import { AbstractTooltipProps, TooltipProps } from "antd/lib/tooltip";

export interface ITooltip extends AbstractTooltipProps {
  //
}

export function Tooltip(props: ITooltip & TooltipProps) {
  return <TooltipView {...props} />;
}
