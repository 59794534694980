import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IDragAndDropContainer } from "./BoardCollapse.interface";
import { DragDropContext, DragStart, DropResult } from "react-beautiful-dnd";
import { CollapseContext } from "../../../../../../contexts/communication/collapseContext";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import IssueRequiredActionsDialog from "../../../../dialogs/issueRequiredActionsDialog/IssueRequiredActionsDialog";
import {
  BoardStatusActionRuleDto,
  BoardStatusDto,
  BoardStatusTransitionActionRuleDto,
  IssueDto,
} from "../../../../../../api";
import { nextStatusesToIds } from "./helpers";
import { api } from "../../../../../../services";
import { useTranslation } from "react-i18next";
import { ListIssues } from "../listIssues/ListIssues";
import { GlobalAuthorizedContext } from "../../../../../../contexts/globalAuthorizedContext";
import { BoardFilterContext } from "../../../../../../contexts/communication/boardFilterContext";
import { toJS } from "mobx";
import { ActionBaseRuleKey } from "../../constants/keys";
import { custonFieldsKeys } from "../../../../forms/types/consts";
import { BroadcastChannel } from "broadcast-channel";
import { Column } from "./Column";

export const DragAndDropContainer = (props: IDragAndDropContainer) => {
  const { boardStore, authStore } = useRootStore();
  const { t } = useTranslation();
  const notifier = useNotifier();
  //const { communicationsStore } = useRootStore();
  const globalAuthorizedContext = useContext(GlobalAuthorizedContext);
  const context = useContext(BoardFilterContext);
  const issueChannel = new BroadcastChannel("issue");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [issue, setIssue] = useState<IssueDto | undefined>(undefined);
  // const [fromStatusId, setFromStatusId] = useState<number | undefined>(undefined);
  const [fromColumnId, setFromColumnId] = useState<number | undefined>(undefined);
  const [nextStatuses, setNextStatuses] = useState<BoardStatusDto[] | undefined>(undefined);
  const [toStatusId, setToStatusId] = useState<number | undefined>(undefined);

  const [isActiveTriggerMove, setIsActiveTriggerMove] = useState<boolean>(false);

  const [isOpenRequiredActionsDialog, setIsOpenRequiredActionsDialog] = useState<boolean>(false);

  const currentStatus = useMemo(() => issue?.status, [issue?.status]);

  const currentStatusFromBoard = useMemo(() => {
    return boardStore.getBoard?.statuses?.find((bS) => bS.id === currentStatus?.id);
  }, [boardStore.getBoard?.statuses, currentStatus?.id]);

  const actions = useMemo(
    () => issue?.calculated?.actions?.filter((a) => a.baseAction?.displayInFlowComboBox),
    [issue?.calculated?.actions]
  );

  const handleCloseRequiredActionsDialog = () => {
    setIsOpenRequiredActionsDialog(false);
    handleResetDragData();
  };

  const handleOpenRequiredActionsDialog = () => {
    setIsLoading(true);
    setIsOpenRequiredActionsDialog(true);
  };

  const handlerApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  const onSwitchStatusWithoutDialog = async () => {
    const r = await api.issueHistory.create({
      issueId: issue?.id,
      actions: [{ key: handleGetNextActionAndStatus()?.status }],
    });
    if (r == null) handlerApiError();

    await issueChannel.postMessage({
      issueId: issue?.id,
      type: "issueBody",
    });
    
    await api.notification.setIsRead({ issueId: issue?.id, setRead: true });

    handleResetDragData();
  };

  const handleChangeIssueStatus = async () => {
    const countActions = handleGetCountMoveActions();
    if (countActions == null || !handleCheckAllowToMove()) return handlerApiError();
    if (countActions > 0) handleOpenRequiredActionsDialog();
    else {
      await onSwitchStatusWithoutDialog();
      // customEvent.dispatch("reloadIssueBoard", { boardId: props.board?.id });
      // customEvent.dispatch("reloadIssue", { boardId: props.board?.id });
      // globalAuthorizedContext?.issue?.reload?.issueBoard &&
      //   (await globalAuthorizedContext?.issue?.reload?.issueBoard());
      // globalAuthorizedContext?.issue?.reload?.issueBoardColumn &&
      //   (await globalAuthorizedContext?.issue?.reload?.issueBoardColumn());
      await issueChannel.postMessage({
        issueId: issue?.id,
        type: "issueBody",
      });
      handleResetDragData();
    }
  };

  const handleInitDragData = async (data: DragStart) => {
    //const issue = communicationsStore.getIssue(Number(data.draggableId));
    const statusId = Number(data.source.droppableId);
    setFromColumnId(statusId);
    const issue = await api.issue.getById(Number(data.draggableId), {
      includeIndicators: true,
      includeActions: true,
    });
    if (issue == null) return handlerApiError();
    setIssue(issue);
  };

  useEffect(() => {
    setNextStatuses(actions?.map((a) => a.destinationStatus!) ?? undefined);
  }, [actions]);

  const handleCheckAllowToMove = () => nextStatuses?.some((item) => item?.id === toStatusId);

  const handleGetCountMoveActions = (): number => {
    const nextStatus = nextStatuses?.find((item) => item?.id === toStatusId);
    return (
      toJS(currentStatusFromBoard)?.transitionsTo?.find((tT) => tT.boardStatusTo?.id === nextStatus?.id)
        ?.requiredActions?.length ?? 0
    );
  };
  const handleResetDragData = () => {
    setFromColumnId(undefined);
    setToStatusId(undefined);
    setIssue(undefined);
    setNextStatuses(undefined);
  };

  const handleDragStart = (data: DragStart) => {
    handleInitDragData(data);
  };

  const handleDragEnd = (data: DropResult) => {
    if (!data.destination?.droppableId) {
      return handleResetDragData();
      // return handlerApiError();
    }
    if (Number(data.destination?.droppableId) === fromColumnId) return handleResetDragData();
    setToStatusId(Number(data.destination?.droppableId));
    setIsActiveTriggerMove(true);
  };

  const handleCheckActionRule = useCallback(
    (rule: BoardStatusActionRuleDto): boolean => {
      switch (rule.baseRuleKey) {
        case ActionBaseRuleKey.AllowToParticipator:
          return !!issue?.participants?.find((p) => p.userId === authStore.getInitialInfo?.identity?.id);
        case ActionBaseRuleKey.AllowToExecutor:
          return issue?.executorUserId === authStore.getInitialInfo?.identity?.id;
        case ActionBaseRuleKey.AllowToCreator:
          return issue?.createdByUserId === authStore.getInitialInfo?.identity?.id;
        case ActionBaseRuleKey.AllowToInitiator:
          return issue?.initiatorUserId === authStore.getInitialInfo?.identity?.id;
        case ActionBaseRuleKey.AllowToAny:
          return true;
        case ActionBaseRuleKey.IfProofIsSet:
          return issue?.proof != null;
        case ActionBaseRuleKey.IfStrictDeadlineIsTrue:
          return !!issue?.fields?.find((f) => f.key === custonFieldsKeys.strictDeadline)?.valueBool;
        case ActionBaseRuleKey.IfStrictDeadlineIsFalse:
          return !issue?.fields?.find((f) => f.key === custonFieldsKeys.strictDeadline)?.valueBool;
        case ActionBaseRuleKey.IfIsManagerApprovalRequiredIsTrue:
          return !!issue?.fields?.find((f) => f.key === custonFieldsKeys.managerApproval)?.valueBool;
        case ActionBaseRuleKey.IfIsManagerApprovalRequiredIsFalse:
          return !issue?.fields?.find((f) => f.key === custonFieldsKeys.managerApproval)?.valueBool;
        default:
          throw new Error("New base rule key");
      }
    },
    [
      authStore.getInitialInfo?.identity?.id,
      issue?.createdByUserId,
      issue?.executorUserId,
      issue?.fields,
      issue?.initiatorUserId,
      issue?.participants,
      issue?.proof,
    ]
  );

  const handleCheckActionRules = useCallback(
    (rules: BoardStatusTransitionActionRuleDto[]): boolean => {
      if (rules == null || !rules.length) {
        return true;
      }
      let result = false;
      rules.forEach((rule) => {
        if (handleCheckActionRule(rule) || rule.isOr) {
          result = true;
        } else {
          return false;
        }
      });
      return result;
    },
    [handleCheckActionRule]
  );

  const handleGetNextActionAndStatus = useCallback(() => {
    const currentDestinationStatusKey = actions?.find((a) => a.destinationStatus?.id === toStatusId)?.baseAction?.key;
    const nextStatus = nextStatuses?.find((item) => item?.id === toStatusId);
    const requiredActionsForNextStatus = toJS(currentStatusFromBoard)
      ?.transitionsTo?.find((tT) => tT.boardStatusTo?.id === nextStatus?.id)
      ?.requiredActions?.filter((rA) => handleCheckActionRules(rA.rules ?? []));
    return { status: currentDestinationStatusKey, action: requiredActionsForNextStatus?.[0] };
  }, [actions, currentStatusFromBoard, handleCheckActionRules, nextStatuses, toStatusId]);

  // const statusesForFiltering = {
  //   reject: "default.v1.status.rejected",
  //   approval: "default.v1.status.approval",
  //   not_approved: "default.v1.status.not_approved",
  // };
  //кейс когда медленный интернет и задача не успела загрузиться по id
  useEffect(() => {
    if (isActiveTriggerMove && issue && toStatusId) {
      setIsActiveTriggerMove(false);
      handleChangeIssueStatus();
    }
  }, [isActiveTriggerMove, issue, toStatusId]);

  const isOpenBoard = useMemo(() => {
    if (props.isGroupingByTags) {
      return props.openBoardIds?.includes(props.tagId ?? 0) ?? false;
    }
    return props.openBoardIds?.includes(props.orgchartId ?? 0) ?? false;
  }, [props.openBoardIds, props.orgchartId, props.tagId]);

  const boardColumnsList = useMemo(() => {
    return context.board?.columns ?? [];
  }, [context.board?.columns])

   return (
    <>
      {isOpenRequiredActionsDialog && issue && (
        <IssueRequiredActionsDialog
          open={isOpenRequiredActionsDialog}
          boardData={handleGetNextActionAndStatus()?.action ?? null}
          issueData={issue}
          onClose={handleCloseRequiredActionsDialog}
          currentDestinationActionKey={handleGetNextActionAndStatus()?.status as any}
        />
      )}
      <CollapseContext.Provider
        value={{
          draggedIssueId: issue ? issue.id : undefined,
          fromColumnId,
          toStatusId,
          possibilityStatuses: nextStatusesToIds(nextStatuses),
          isLoadingIssueMove: isLoading,
        }}
      >
        <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <div className="d-stack-row spacing-1">
            {boardColumnsList.map((column, index) => {
              return (
                <Column
                  key={column.id}
                  isOpenBoard={isOpenBoard}
                  onChangeBoardColumnsState={props.onChangeBoardColumnsState}
                  orgchartId={props.orgchartId!}
                  tagId={props.tagId}
                  isGroupingByTags={props.isGroupingByTags}
                  column={column}
                  currentStatusFromBoard={currentStatusFromBoard}
                />
                // <div key={column.id} className="d-stack-column spacing-2">
                //   <ListIssues
                //     key={column.id}
                //     isOpen={isOpenBoard}
                //     onChangeBoardColumnsState={props.onChangeBoardColumnsState}
                //     orgchartId={props.orgchartId!}
                //     column={toJS(column)}
                //     status={toJS(statuses?.[index])}
                //     currentStatusFromBoard={toJS(currentStatusFromBoard)}
                //   />
                // </div>
              );
            })}
          </div>
        </DragDropContext>
      </CollapseContext.Provider>
    </>
  );
};
