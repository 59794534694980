import React, { useCallback, useEffect, useRef, useState, memo, useMemo } from "react";
import { UserCard } from "../../../../../elements/userCard/UserCard";
import { TextEditor as TextEditorLexical } from "../../../../textEditor/TextEditor";
import { ToolbarType } from "../../../../textEditor/types/types";
import { initialEditorContent } from "../../../../../../utils/textEditor";
import { Button } from "../../../../../uiKit";
import { api } from "../../../../../../services";
import { useTranslation } from "react-i18next";
import { useNotifier } from "../../../../../../hooks";
import { IRegulationHistorySendBlock } from "./RegulationHistory.interface";

export const RegulationHistorySendBlock = (props: IRegulationHistorySendBlock) => {
  const { t } = useTranslation();

  const editorOptionsRef = useRef<{
    resetEditor: (content?: string) => void;
    isEmptyEditor: () => boolean
    hiddenToolbar: () => void;
    setIsActivated: (value: boolean) => void;
  }>();

  const [historyContent, setHistoryContent] = useState<string>(initialEditorContent);
  const [isSendingLoading, setIsSendingLoading] = useState<boolean>(false);

  const handleChangeCommentContent = useCallback((content: string) => {
    setHistoryContent(content);
  }, []);

  const handleClickCancel = useCallback(() => {
    setHistoryContent('');
    editorOptionsRef.current?.resetEditor();
    editorOptionsRef.current?.hiddenToolbar();
    editorOptionsRef.current?.setIsActivated(false);
    localStorage.removeItem(`regulation-history-editor-send${props.regulationId}`);
    setIsFocus(false);
  }, [props.regulationId]);

  useEffect(() => {
    const value = localStorage.getItem(`regulation-history-editor-send${props.regulationId}`);
    const editorValue = value ? JSON.parse(value) : initialEditorContent;
    setHistoryContent(editorValue);
    editorOptionsRef.current?.resetEditor(editorValue);


  }, [props.regulationId]);

  const notifier = useNotifier();

  const handleCreateRegulationHistory = async (text?: string) => {
    if (editorOptionsRef.current?.isEmptyEditor()) {
      return;
    }
    setIsSendingLoading(true);
    api.regulationHistory
      .create({
        regulationContentId: props.regulationContentId,
        comment: {
          content: text ?? historyContent,
        },
      })
      .then((res) => {
        editorOptionsRef.current?.resetEditor();
        setHistoryContent(initialEditorContent)
        localStorage.removeItem(`regulation-history-editor-send${props.regulationId}`);
        //regulationHistory.reset();
        props.regulationHistory.restart(true);
      })
      .catch((error) => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }))
      .finally(() => setIsSendingLoading(false));
  };

  const [isFocus, setIsFocus] = useState<boolean>(false);
  // const handleOnBlur = useCallback(() => {
  //   console.log("handleOnBlur");
  //   setIsFocus(false);
  // }, [])

  const handleOnFocus = useCallback(() => {
    setIsFocus(true);
  }, [])


  return (
    <div className="regulation-history-comment">
      <div className="regulation-history-comment-row">
        <div className="regulation-history-comment-row__user">
          <UserCard
            userInfo={props.user}
            avatarSize={32}
            hiddenUserInfo
          />
        </div>
        <TextEditorLexical
          id={`regulation-history-editor-send${props.regulationId}`}
          variant="new-style"
          ref={editorOptionsRef}
          value={historyContent}
          toolbarType={ToolbarType.Dynamic}
          initialValue={initialEditorContent}
          onChange={handleChangeCommentContent}
          onFocus={handleOnFocus}
          placeholder={t("ui:placeholder.write_comment")}
        />
      </div>
      {isFocus && (
        <div className="regulation-history-comment-footer">
          <Button
            onClick={handleClickCancel}
            className="button-icon_17"
            variant="default"
          >
            {t("ui:button.cancel")}
          </Button>
          <Button
            onClick={() => handleCreateRegulationHistory()}
            disabled={editorOptionsRef.current?.isEmptyEditor()}
            loading={isSendingLoading || props.regulationHistory?.info?.isLoading}
            className="button-icon_17"
            variant="filled"
          >
            {t("ui:button.send")}
          </Button>
        </div>
      )}
    </div>
  )
}