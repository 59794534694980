import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IssueQueryKeys } from "../../../../../../../constants/issueTypeKeys";
import { useDateHelpers, useRootStore } from "../../../../../../../hooks";
import { Button, Card, Divider, Icon, Text } from "../../../../../../uiKit";
import { blockedFieldsKeys } from "../../../../../forms/types/consts";
import { IPlanningTaskCard } from "./PlanningTaskCard.interface";
import "./PlanningTaskCard.scss";
import { LucidePlus } from "lucide-react";
import { removeLeadingZerosFromString } from "../../../../../../../helpers/stringFunctions";
import { getObjectFromTimeSpan } from "../../../../../../../helpers/dateFunctions";

export const PlanningTaskCard = (props: IPlanningTaskCard) => {
  const { issueInitDataStore } = useRootStore();
  const { formatDate } = useDateHelpers();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOpenIssue = () => {
    navigate({
      search: `?${IssueQueryKeys.issueId}=${props?.issue?.id}`,
    });
    issueInitDataStore.setOnChangeDefiniteField = props.issuesFromCommunicationsRestart;
    issueInitDataStore.setBlockedFields = [blockedFieldsKeys.executorUserId];
  };

  return (
    <Card clickable bodyStyle={{ padding: 0 }} className="planning-task-card" onClick={handleOpenIssue}>
      <div className="pa-3 d-stack-row spacing-3 align-start">
        <div className="d-stack-column spacing-1 flex-grow-1">
          <Text weight="bold" size="12px" children={props.issue.name} />
          <Text size="12px" children={props.issue.awaitedResult} />
        </div>
        <Button
          variant="text"
          size="small"
          className="flex-shrink-0"
          style={{ height: "18.85px", width: "18.85px" }}
          disabled={props.plan?.status == 2 || !props.plan}
          loading={props.loadingTaskId == props.issue.id}
          onClick={(e) => {
            e.stopPropagation();
            props.onAddIssue({
              issueId: props.issue.id,
            });
          }}
          icon={<Icon className="planning-task-card__add-button" component={() => <LucidePlus size={16} />} />}
        />
      </div>
      {props.issue.dateDeadline || props.issue.timePlan ? (
        <>
          <Divider />
          <div className="pa-3 d-flex align-center justify-space-between">
            <Text
              size="12px"
              children={
                props.issue.dateDeadline
                  ? formatDate(props.issue.dateDeadline, { formatObject: { month: "short" } })
                  : ""
              }
            />
            <Text
              size="12px"
              children={
                props.issue.timePlan
                  ? `${removeLeadingZerosFromString(getObjectFromTimeSpan(props.issue.timePlan).hours!)}${t(
                      "common:planning.hour"
                    )}`
                  : null
              }
            />
          </div>
        </>
      ) : null}
    </Card>
  );
};
