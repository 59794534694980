import React from "react";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { CompanyDto, CompanyScheduleDto, TimeZoneDto } from "../../../../api";
import CompanyEditFormScheduleForm, {
  ScheduleFormTypes,
} from "./companyEditFormScheduleForm/CompanyEditFormScheduleForm";
import AvatarCompanySettings from "../../pages/settings/avatarCompanySettings/AvatarCompanySettings";
import { useClaims } from "../../../../hooks";
import { permissionKeys } from "../../../../utils/permissions";
import { Button, Card, Input, Select, SelectOption, Switch, TextArea } from "../../../uiKit";
import { theme } from "antd";

export interface ICompanyEditFormView {
  companyData: CompanyDto;
  updater: {
    update: () => Promise<void>;
    updatePartially: () => Promise<void>;
    setInitialState: (initialState: CompanyDto) => void;
    applyChanges: (changes: any) => void;
    currentState: CompanyDto | null;
    beforeState: CompanyDto | null;
    reset: () => void;
  };
  timezonesList: TimeZoneDto[];
  scheduleData: ScheduleFormTypes;
  firstLaunch?: boolean;
  canShowError: any;

  onSaveClick?: () => void;

  handleAvatarChange(id: number): void;

  handleAvatarDelete(): void;

  setCanShowError(value: any): void;

  handleScheduleSave(data: CompanyScheduleDto[]): Promise<ScheduleFormTypes | null>;
}

function CompanyEditFormView(props: ICompanyEditFormView) {
  const { t } = useTranslation();
  const claims = useClaims();
  const { useToken } = theme;
  const { token } = useToken();

  const startDays = [
    { id: 0, name: t("common:date.day.sunday") },
    { id: 1, name: t("common:date.day.monday") },
  ];

  const startDaysReports = [
    { id: 0, name: t("common:date.day.sunday") },
    { id: 1, name: t("common:date.day.monday") },
    { id: 2, name: t("common:date.day.tuesday") },
    { id: 3, name: t("common:date.day.wednesday") },
    { id: 4, name: t("common:date.day.thursday") },
    { id: 5, name: t("common:date.day.friday") },
    { id: 6, name: t("common:date.day.saturday") },
  ];

  return (
    <div className={`d-stack-row ${props.firstLaunch ? "" : "spacing-12"}`}>
      <div
        className="d-stack-column spacing-3 flex-grow-1"
        style={props.firstLaunch ? undefined : { maxWidth: "444px" }}
      >
        <SubheaderText text={t("ui:subheader.avatar")} />
        <div className="mb-3 py-2" style={{ borderRadius: "8px", border: `1px solid ${token.colorBorder}` }}>
          <AvatarCompanySettings
            imageUrl={props.updater.currentState?.image?.url ?? ""}
            companyId={props.updater.currentState?.id}
            letter={props.updater.currentState?.name ?? undefined}
            color={props.updater.currentState?.color ?? undefined}
            onUploadSuccess={props.handleAvatarChange}
            onDeleteClick={props.handleAvatarDelete}
          />
        </div>

        <SubheaderText text={t("ui:subheader.company_name")} />
        <Input // disabled={!props.updater.currentState?.name}
          readOnly={!claims.has(permissionKeys.company.edit.nameAndDesc)}
          disabled={!claims.has(permissionKeys.company.edit.nameAndDesc)}
          placeholder={t("ui:placeholder.company_name")}
          value={props.updater.currentState?.name ?? ""}
          onInput={(event) => {
            props.updater.applyChanges({ name: (event.target as HTMLInputElement).value });
            props.setCanShowError({ ...props.canShowError, name: true });
          }}
          onBlur={props.updater.currentState?.name?.length! > 0 ? props.updater.updatePartially : undefined}
          status={!(props.updater.currentState?.name?.length! > 0) && props.canShowError.name ? "error" : undefined}
          // helperText={
          //   props.updater.currentState?.name?.length > 0 || !props.canShowError.name
          //     ? undefined
          //     : t("validation:field_required")
          // }
        />

        <SubheaderText text={t("ui:subheader.description")} />
        <TextArea
          readOnly={!claims.has(permissionKeys.company.edit.nameAndDesc)}
          disabled={!claims.has(permissionKeys.company.edit.nameAndDesc)}
          placeholder={t("ui:placeholder.description")}
          value={props.updater.currentState?.description ?? ""}
          onInput={(event) => props.updater.applyChanges({ description: (event.target as HTMLInputElement).value })}
          onBlur={props.updater.updatePartially}
        />

        <SubheaderText text={t("ui:subheader.contact_person")} />
        <Input
          readOnly={!claims.has(permissionKeys.company.edit.nameAndDesc)}
          placeholder={t("ui:placeholder.contact_person")}
          value={props.updater.currentState?.contactName ?? ""}
          onInput={(event) => {
            props.updater.applyChanges({ contactName: (event.target as HTMLInputElement).value });
          }}
          onBlur={props.updater.updatePartially}
        />

        <SubheaderText text={t("ui:subheader.contact_phone_number")} />
        <Input
          readOnly={!claims.has(permissionKeys.company.edit.nameAndDesc)}
          placeholder={t("ui:placeholder.contact_phone_number")}
          value={props.updater.currentState?.contactPhoneNumber ?? ""}
          onInput={(event) => {
            props.updater.applyChanges({ contactPhoneNumber: (event.target as HTMLInputElement).value });
          }}
          onBlur={props.updater.updatePartially}
        />

        <SubheaderText text={t("ui:subheader.week_start_day")} />
        <Select
          disabled={!claims.has(permissionKeys.company.edit.schedule)}
          placeholder={t("ui:placeholder.week_start_day")}
          value={props.updater.currentState?.weekStart ?? ""}
          onChange={(value) => props.updater.applyChanges({ weekStart: Number(value) })}
          onBlur={props.updater.updatePartially}
        >
          {startDays.map((day) => (
            <SelectOption key={day.id} value={day.id}>
              {day.name}
            </SelectOption>
          ))}
        </Select>

        <SubheaderText text={t("ui:subheader.report_start_day")} />
        <Select
          disabled={!claims.has(permissionKeys.company.edit.schedule)}
          placeholder={t("ui:placeholder.report_start_day")}
          value={props.updater.currentState?.weekReportStart ?? ""}
          onChange={(value) => props.updater.applyChanges({ weekReportStart: Number(value) })}
          onBlur={props.updater.updatePartially}
        >
          {startDaysReports.map((day) => (
            <SelectOption key={day.id} value={day.id}>
              {day.name}
            </SelectOption>
          ))}
        </Select>

        <SubheaderText text={t("ui:subheader.time_zone")} />
        <Select
          // disabled={!props.updater.currentState?.timeZoneFromUtc}
          disabled={!claims.has(permissionKeys.company.edit.timeZone)}
          placeholder={t("ui:placeholder.time_zone")}
          value={props.updater.currentState?.timeZoneId}
          onChange={(value) => props.updater.applyChanges({ timeZoneId: value })}
          onBlur={props.updater.updatePartially}
          fullWidth
          optionLabelProp="label"
          items={props.timezonesList.map((tz, index) => ({
            id: index,
            value: tz.id!,
            text: tz.idWithUtc!,
            additionalProps: { label: tz.idWithUtc },
          }))}
          status={(props.updater.currentState?.timeZoneId ?? "").trim().length == 0 ? "error" : undefined}
        />

        <CompanyEditFormScheduleForm
          readOnly={!claims.has(permissionKeys.company.edit.schedule)}
          scheduleData={props.scheduleData}
          dayStart={props.updater.currentState?.weekStart as any}
          handleScheduleSave={props.handleScheduleSave}
        />

        {/*<SubheaderText text={t("ui:subheader.terminology")} />*/}
        {/*<div className="pa-2" style={{ borderRadius: "4px", border: "2px solid var(--color-background-stronger)" }}>*/}
        {/*  <SubheaderText text={t("ui:placeholder.glossary.r")} />*/}
        {/*  <div className="d-stack-column spacing-3">*/}
        {/*    <TextArea*/}
        {/*      placeholder={t("ui:placeholder.glossary.r")}*/}
        {/*      value={props.updater.currentState?.glossary?.find((g: any) => g.key == "r")?.value ?? ""}*/}
        {/*      onInput={(event) =>*/}
        {/*        props.updater.applyChanges({*/}
        {/*          glossary: [{ key: "r", value: (event.target as HTMLInputElement).value }],*/}
        {/*        })*/}
        {/*      }*/}
        {/*      onBlur={props.updater.update}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}

        {props.firstLaunch && (
          <div className="d-flex justify-end">
            {/*|| !props.updater.currentState.timeZoneId?.length*/}
            <Button
              onClick={props.onSaveClick}
              disabled={!props.updater.currentState?.name?.length || !props.updater.currentState?.timeZoneId}
              variant="filled"
            >
              {t("ui:button.save")}
            </Button>
          </div>
        )}
      </div>
      {!props.firstLaunch && (
        <div
          className="d-stack-column spacing-6"
        >
          <Card
            variant={'transparent'}
            isNoPadding
          >
            <div className="d-stack-column spacing-3">
              <SubheaderText text={t("ui:subheader.requirements_switches")} />
              <Switch
                checked={
                  props.updater.currentState?.companySettings?.timeFactRequired ?? false
                }
                onChange={(value) => {
                  props.updater.applyChanges({
                    companySettings: {
                      ...props.updater.currentState?.companySettings,
                      timeFactRequired: value
                    }
                  });
                  props.updater.update();
                }}
                label={t("ui:label.requirements_switches.first")}
                disabled={!claims.has(permissionKeys.company.notifications.manageViolations)}
              />
            </div>
          </Card>
          <Card
            variant={'transparent'}
            isNoPadding
          >
            <div className="d-stack-column spacing-3">
              <SubheaderText text={t("ui:subheader.violation_notification_switches")} />
              <Switch
                checked={
                  props.updater.currentState?.companySettings?.notAcceptedForMoreThan24HoursIndicatorVisible ?? false
                }
                onChange={(value) => {
                  props.updater.applyChanges({
                    companySettings: {
                      ...props.updater.currentState?.companySettings,
                      notAcceptedForMoreThan24HoursIndicatorVisible: value
                    }
                  });
                  props.updater.update();
                }}
                label={t("ui:label.violation_notification_switch.first")}
                disabled={!claims.has(permissionKeys.company.notifications.manageViolations)}
              />
              <Switch
                checked={
                  props.updater.currentState?.companySettings?.notMovedInWorkForMoreThan48HoursIndicatorVisible ?? false
                }
                onChange={(value) => {
                  props.updater.applyChanges({
                    companySettings: {
                      ...props.updater.currentState?.companySettings,
                      notMovedInWorkForMoreThan48HoursIndicatorVisible: value
                    }
                  });
                  props.updater.update();
                }}
                label={t("ui:label.violation_notification_switch.second")}
                disabled={!claims.has(permissionKeys.company.notifications.manageViolations)}
              />
              <Switch
                checked={props.updater.currentState?.companySettings?.notApprovedFor72HoursIndicatorVisible ?? false}
                onChange={(value) => {
                  props.updater.applyChanges({
                    companySettings: {
                      ...props.updater.currentState?.companySettings,
                      notApprovedFor72HoursIndicatorVisible: value
                    }
                  });
                  props.updater.update();
                }}
                label={t("ui:label.violation_notification_switch.third")}
                disabled={!claims.has(permissionKeys.company.notifications.manageViolations)}
              />
            </div>
          </Card>
        </div>
        )}
    </div>
  );
}

export default CompanyEditFormView;
