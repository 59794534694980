import React, { useEffect } from "react";
import "./CalendarPlanningFooter.scss";

import { InfoWrapper } from "../InfoWrapper/InfoWrapper";
import { ICalendarPlanningFooterDto } from "./CalendarPlanningFooter.interface";
import { SumIcon } from "../../../../../../constants/icon";
import { Popover } from "../../../../../uiKit";
import { CalendarScheduleDto } from "../../../../../../api/models/CalendarScheduleDto";
import { getObjectFromTimeSpan } from "../../../../../../helpers/dateFunctions";


export const CalendarPlanningFooter = React.memo((props: ICalendarPlanningFooterDto) => {
  const { schedule, width, statistic } = props;

  const getSumStyle = (item: CalendarScheduleDto | null) => {
    if (!item) return;
    const { totalHours = 0, totalMinutes = 0 } = getObjectFromTimeSpan(item?.timePlan ?? "00:00:00");
    if (totalHours > 40 || (totalHours === 40 && totalMinutes > 0)) {
      return {
        background: 'var(--color-calendar-error-base)',
        color: 'var(--color-calendar-error-darker)',
      };
    }
  }


  return width.tableWidth > 0 ? (
    <table
      className="calendar-planning-footer"
      style={{
        width: width.tableWidth,
      }}
    >
      <tbody>
        <tr>
          <td
            className="calendar-planning-footer__item calendar-planning-footer__item_first"
            style={{
              width: width.first,
              minWidth: width.first,
              maxWidth: width.first,
              ...getSumStyle(statistic)
            }}
          >
            { statistic && (
              <Popover
                trigger={["hover"]}
                placement="right"
                mouseEnterDelay={0.1}
                content={
                  <InfoWrapper
                    item={statistic}
                    isHiddenFreeTime
                    isLineItem
                  />
                }
              >
                <span>
                  <SumIcon/>
                </span>
              </Popover>
            )}

          </td>
          { [...new Array(schedule.length)].map((_, i) => (
            <td
              key={i}
              className={
                `calendar-planning-footer__item ${schedule[i]?.type === 2 ? 'calendar-planning-footer__item_day-off' : ''}`
              }
              // style={{
              //   width: props.width.all,
              //   minWidth: props.width.all,
              //   maxWidth: props.width.all
              // }}
            >
              <InfoWrapper
                item={schedule[i]}
              />
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  ) : null
});
