import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { SectionContent } from "../../pages";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserSelectionRule } from "../../../../../../api/types/userSelectionRule";
import { SettingUpAccessDialog } from "../../../../dialogs/settingUpAccessDialog/SettingUpAccessDialog";
import { keysRulesFor } from "../../../../dialogs/settingUpAccessDialog/SettingUpAccessDialog.interface";
import { CreateRegulationSectionForm } from "../../../../forms/createRegulationSectionForm/CreateRegulationSectionForm";
import { SectionViewHeader } from "../../headers/sectionViewHeader/SectionViewHeader";
import { CustomConfirmDialog } from "../../../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { observer } from "mobx-react-lite";
import { SectionContext } from "../../../../../../contexts/RegulationPage/SectionContext";
import "./Section.scss";
import { NavigationMenuItemDto } from "../../../../../../api/models/NavigationMenuDto";
import {
  AddingRegulationsToSectionDialog
} from "../../../../dialogs/addingRegulationsToSectionDialog/AddingRegulationsToSectionDialog";
import type { RegulationDto } from "../../../../../../api";
import { GlobalAuthorizedContext } from "../../../../../../contexts/globalAuthorizedContext";
import { api } from "../../../../../../services";
import {
  RegulationAccessControlDialog
} from "../../../../dialogs/regulationAccessControlDialog/RegulationAccessControlDialog";

export const Section = observer(() => {

  const { regulationStore } = useRootStore();

  const {
    breadcrumbsList,
    isLoading,
    section,
    setSection,
    isOpenAccessSettingsDialog,
    isOpenAssignForStudyDialog,
    handleCloseAssignForStudyDialog,
    handleCloseAccessSettingsDialog,
    isEditable,
    handleSwitchEditable,
    handleOffEditable,
    isOpenConfirmDialog,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    fetchSection,
    isOpenAddingRegulations,
    handleAddedRegulationsToSection,
    handleCloseAddingRegulation,
    getRegulations,
    onMove,
    onRemovalRegulationsFromSection,
    onAppointApprover,
    handleSubmitForPublish,
    handleSubmitForReject,
  } = useContext(SectionContext);
  const globalAuthorizedContext = useContext(GlobalAuthorizedContext);
  const notifier = useNotifier();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const handlerApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const handleDeleteSection = useCallback(async () => {
    // const { id } = accessControlDialogsState;

    setIsConfirmLoading(true);

    const resp = await api.navigationMenu.deleteItem(section?.id!);
    setIsConfirmLoading(false);
    if (resp !== null) {
      notifier.show({ message: t("notifier:success.delete_section"), theme: "success" });
      handleCloseConfirmDialog();
    } else {
      handlerApiError();
    }

  }, [section]);

  const handleRedirectToRegulation = useCallback((regulationId: number) => {
      // ?contentType=${regulationContentTypeKeys.approved}
    if (section) {
      regulationStore.setParentId(section?.id!)

      navigate(`/policy/all/${regulationId}/`, {
        state: {
          fromSectionId: section?.id,
          // noLearntRegulationsIds: handleGetNoLearntRegulationsIdFromSection(section),
          // allRegulationsIds: handleGetAllRegulationsIdFromSection(section),
        },
      });
    }
  }, [section]);

  const toEdit = useMemo(() => {
    return {
      isEdit: isEditable,
      sectionTitle: section?.name ?? "",
      onCancel: handleOffEditable,
      section: section ?? undefined,
      userSelectionRules: section?.userSelectionRules ?? [],
      // currentRegulations:
      //   section?.regulations?.map((item: NavigationMenuItemDto) => ({
      //     id: item.regulationId,
      //     text: item.regulation?.name ?? "",
      //   })) ?? [],
    }
  }, [isEditable, section]);

  const onSubmitForPublish = useCallback(async (id: number) => {
    const status = await handleSubmitForPublish(id);
    if (status !== null) {
      await fetchSection(section?.id!);
      globalAuthorizedContext?.regulation?.reloadSidebarData?.(section?.id);
    }
  }, [section]);

  const onSubmitForReject = useCallback(async (id: number) => {
    const status = await handleSubmitForReject(id);
    if (status !== null) {
      await fetchSection(section?.id!);
    }
  }, [section]);


  const handleRemovalRegulationsFromSection = useCallback(async(id: number, isRegular: boolean) => {
    const status = await onRemovalRegulationsFromSection(id, isRegular);
    if (status !== null) {
      await fetchSection(section?.id!);
      globalAuthorizedContext?.regulation?.reloadSidebarData?.(isRegular ? section?.id : undefined);
    }
    return status;
  }, [section?.id]);

  const handleAddedRegular = useCallback(async (regulations: number[], sections: number[]) => {
    const regulationsList: RegulationDto[] = await getRegulations(regulations, sections);
    const list: NavigationMenuItemDto[] = regulationsList.map((item) => {
      const userSelectionRules = item.userSelectionRules as UserSelectionRule[];
      return {
      ...item,
        userSelectionRules,
        id: item.id ?? -1,
        name: item.name ?? "",
        order: -1,
        breadcrumbs: [],
        isFavorite: false,
        regulationId: item.id,
        regulation: item,
        allowedActions: item.actions ?? [],
      }
    });

    const status = await handleAddedRegulationsToSection(regulations, sections);
    if (status) {
      setSection((prev)=> {
        return prev
            ? { ...prev, items: [...list, ...(prev.items ?? [])] }
            : null
        }
      );
      handleCloseAddingRegulation();
    }
    // setSection
  }, [section]);

  const onRemoveRegulation = (id: number) => {
    console.log("onRemoveRegulation", id);
    setSection((prev) => {
      if (prev) {
        return {
          ...prev,
          items: (prev.items ?? []).filter((item) => item.id !== id),
        }
      }
      return prev;
    })
  }


  return (
    <>
      {isOpenAddingRegulations && (
        <AddingRegulationsToSectionDialog
          onAddedRegular={handleAddedRegular}
          section={section}
          onClose={handleCloseAddingRegulation}
        />
      )}

      {isOpenAccessSettingsDialog && (
        <RegulationAccessControlDialog
          isOpen={isOpenAccessSettingsDialog}
          onClose={handleCloseAccessSettingsDialog}
          sectionId={section?.id!}
          createdByUser={section?.createdByUser}
          title={section?.name!}
        />
      )}

      {isOpenAssignForStudyDialog && (
        <SettingUpAccessDialog
          keyRuleTo={keysRulesFor.regulation}
          isOpen={isOpenAssignForStudyDialog}
          currentRules={(section?.userSelectionRules as UserSelectionRule[]) ?? []}
          onClose={handleCloseAssignForStudyDialog}
          sectionId={section?.id!}
          title={section?.name!}
        />
      )}

      {isOpenConfirmDialog && (
        <CustomConfirmDialog
          open={isOpenConfirmDialog}
          onClose={handleCloseConfirmDialog}
          onConfirm={handleDeleteSection}
          title={t("ui:title.confirm_deletion")}
          subTitle={t("ui:text.delete_topic")}
          loading={isConfirmLoading}
        />
      )}
      <PageHeader
        className="section-page-header"
        isOverflow
      >
        <SectionViewHeader
          isEditable={isEditable}
          // permissionToEdit={authStore.getInitialInfo?.identity?.id === section?.createdByUserId}
          permissionToEdit={true}
          // onOpenDialog={handleOpenDialog}
          onDeleteSection={handleOpenConfirmDialog}
          onSwitchEditable={handleSwitchEditable}
          breadcrumbsList={breadcrumbsList}
          toEdit={toEdit}
        />
      </PageHeader>
      
      <PageContent
        isLoading={isLoading}
        wrapperClassName={"section-page-content"}
      >
        {isEditable ? (
          section && (
            <CreateRegulationSectionForm
              toEdit={toEdit}
              section={section}
              removeRegulation={onRemoveRegulation}
            />
          )
        ) : (
          <SectionContent
            isEditable={isEditable}
            section={section}
            // creator={creator}
            isLoading={isLoading}
            toEdit={toEdit}
            onOffEditable={handleOffEditable}
            onRedirectToRegulation={handleRedirectToRegulation}
            onMove={onMove}
            onRemovalRegulationsFromSection={handleRemovalRegulationsFromSection}
            onAppointApprover={onAppointApprover}
            handleSubmitForPublish={onSubmitForPublish}
            handleSubmitForReject={onSubmitForReject}
          />
        )}
      </PageContent>
    </>
  );
});
