import React from "react";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import {
  Button,
  Card,
  DatePickerRange,
  Dialog,
  DialogActions,
  Segmented,
  Select,
  Spin,
  Table,
  Text,
} from "../../../uiKit";
import { ICompanyColumn } from "../../pages/companies-management/companyMainCard/CompanyMainCard";
import { FiCreditCard } from "@react-icons/all-files/fi/FiCreditCard";
import { FiTrendingUp } from "@react-icons/all-files/fi/FiTrendingUp";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Report2ValueModel } from "../../../../api/models/Report2ValueModel";
import dayjs from "dayjs";

interface IAdminCompaniesStatsDialogView {
  open: boolean;
  tableFilters: any;
  tableItems: Report2ValueModel[];
  displayMode: "chart" | "table";
  isPaid: string | null;
  isTest: string;
  hasDriver: boolean | null;
  isLoading: boolean;

  setIsPaid: (value: string) => void;
  setIsTest: (value: string) => void;
  setHasDriver: (value: boolean | null) => void;
  setDisplayMode: (mode: "chart" | "table") => void;
  setTableFilters: (val: any) => void;

  onClose(): void;
}

export function AdminCompaniesStatsDialogView(props: IAdminCompaniesStatsDialogView) {
  const { t } = useTranslation();

  const tableColumns: ICompanyColumn[] = [
    {
      title: t("parse:metrics_table.date"),
      dataIndex: "date",
      key: "date",
    },
    {
      title: t("parse:companies_number"),
      dataIndex: "value",
      key: "value",
    },
    {
      title: t("parse:employees_number"),
      dataIndex: "value2",
      key: "value2",
    },
  ];

  const filtersIsPaid = [
    { id: 0, text: t("parse:all"), value: "null" },
    { id: 1, text: t("parse:paid"), value: "true" },
    { id: 2, text: t("parse:free"), value: "false" },
    { id: 3, text: t("parse:partially_paid"), value: "isPartiallyPaid"},
  ];

  const filtersHasDriver = [
    { id: 0, text: t("parse:all"), value: "null" },
    { id: 1, text: t("parse:has_drivers"), value: "true" },
    { id: 2, text: t("parse:has_no_drivers"), value: "false" },
  ];

  const filtersIsTest = [
    { id: 0, text: t("parse:all"), value: "null" },
    { id: 0, text: t("parse:yes"), value: "true" },
    { id: 1, text: t("parse:no"), value: "false" },
    { id: 3, text: t("parse:inactive"), value: "isInactive" },
  ];

  const CustomizedTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Card style={{ boxShadow: "var(--shadow-down-sm)" }}>
          <Text>{label}</Text>
          <div />
          <Text>{`${t("parse:companies_number")} ${payload[0]?.value}`}</Text>
          <div />
          <Text>{`${t("parse:employees_number")}: ${payload[1]?.value}`}</Text>
        </Card>
      );
    }

    return null;
  };

  return (
    <Dialog
      width="80%"
      title={t("parse:companies_statistics")}
      open={props.open}
      onClose={props.onClose}
      closable={false}
    >
      <div className="d-stack spacing-2 align-end mb-3">
        <div>
          <SubheaderText text={t("parse:date_range")} />
          <DatePickerRange
            allowClear={false}
            disableFuture
            value={[props.tableFilters.dateFrom, props.tableFilters.dateTo]}
            onChange={(val) => {
              props.setTableFilters({
                ...props.tableFilters,
                dateFrom: dayjs(val?.[0]).tz("Indian/Antananarivo").startOf('day'),
                dateTo: dayjs(val?.[1]).tz("Indian/Antananarivo").endOf('day'),
              })
            }}
          />
        </div>
        <div>
          <SubheaderText text={t("parse:company_type")} />
          <Select
            style={{ width: "220px" }}
            size="middle"
            value={props.isPaid ?? "null"}
            onChange={(value) => props.setIsPaid(value)}
            items={filtersIsPaid}
          />
        </div>
        {/*<div>*/}
        {/*  <SubheaderText text={t("parse:company_with_drivers")} />*/}
        {/*  <Select*/}
        {/*    style={{ width: "150px" }}*/}
        {/*    size="middle"*/}
        {/*    value={props.hasDriver ?? "null"}*/}
        {/*    onChange={(value) => props.setHasDriver(value == "null" ? null : value)}*/}
        {/*    items={filtersHasDriver}*/}
        {/*  />*/}
        {/*</div>*/}
        <div>
          <SubheaderText text={t("parse:test")} />
          <Select
            style={{ width: "120px" }}
            size="middle"
            value={`${props.isTest}`}
            onChange={(value) => props.setIsTest(value)}
            items={filtersIsTest}
          />
        </div>
        <div className="flex-grow-1" />
        <div>
          <SubheaderText text={t("parse:display_type")} />
          <Segmented
            value={props.displayMode}
            onChange={(val: any) => props.setDisplayMode(val)}
            options={[
              {
                label: <FiCreditCard />,
                value: "table",
              },
              {
                label: <FiTrendingUp />,
                value: "chart",
              },
            ]}
          />
        </div>
      </div>
      {props.displayMode == "table" ? (
        <Spin spinning={props.isLoading}>
          {/* @ts-ignore */}
          <Table size="small" className="mt-5" columns={tableColumns} dataSource={props.tableItems} />
        </Spin>
      ) : (
        <Spin spinning={props.isLoading}>
          <div style={{ width: "100%", height: "250px" }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                margin={{
                  top: 16,
                  right: 10,
                  left: 0,
                  bottom: 0,
                }}
                data={props.tableItems}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                {/*<Area dataKey="value" stroke="#8884d8" fill="#8884d8" />*/}
                <Tooltip content={CustomizedTooltip} />
                <Line
                  dot={false}
                  name={t("parse:companies_number")}
                  // dot={{ stroke: "#5672ff", strokeWidth: 1 }}
                  type="linear"
                  dataKey="value"
                  stroke="#0d5c69"
                />
                <Line
                  dot={false}
                  // dot={{ stroke: "#5672ff", strokeWidth: 1 }}
                  name={t("parse:employees_number")}
                  type="linear"
                  dataKey="value2"
                  stroke="#5672ff"
                />
                <Legend iconType="circle" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Spin>
      )}
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
