import React, { ChangeEvent, FormEvent, memo, useCallback, useEffect, useState } from "react";
import { Icon, IconButton, Input, Text, TextArea } from "../../../../../uiKit";
import { FiTrash2 } from "@react-icons/all-files/fi/FiTrash2";
import "./EditTag.scss";
import { IEditTag } from "./IEditTag.interface";
import clsx from "clsx";
import { TagDto } from "../../../../../../api/models/TagDto";
import useDebounce from "../../../../../../hooks/useDebounce";
import { ColorPicker } from "../../../../../uiKit/colorPicker/ColorPicker";
import { useTranslation } from "react-i18next";

export const EditTag = memo((props: IEditTag) => {
  const { t } = useTranslation();

  const handeClickDeleteTag = useCallback(() => {
    props.onDelete(props.tag.id, props.tag.name);
  }, []);

  const handleClickEditTag = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    props.onChangeEditTagId(props.tag.id);
  }, [props.tag.id]);

  const [editTag, setEditTag] = useState<TagDto>(props.tag);
  useEffect(() => {
    setEditTag(props.tag);
  }, [props.tag]);

  const handleChangeTagName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEditTag((prev) => ({ ...prev, name: e.target.value }));
  }, []);

  const debouncedTag = useDebounce(editTag, 400);

  const handleChangeColor = useCallback((color: string) => {
    setEditTag((prev) => ({ ...prev, color }));
  }, []);

  useEffect(() => {
    if (editTag.color !== props.tag.color) {
      props.onUpdateTag(editTag);
    }
  }, [editTag.color]);

  const [isShowError, setIsShowError] = useState<boolean>(false);
  const clearError = useCallback(() => {
    setIsShowError(false);
  }, []);

  const handleUpdateTagName = useCallback(async (tag: TagDto) => {
    const status = await props.onUpdateTag(tag);
    if (status === "TagWithThisNameAlreadyExists") {
      setIsShowError(true);
    }

  }, []) ;

  useEffect(() => {
    if (debouncedTag.name !== props.tag.name) {
      handleUpdateTagName(debouncedTag)
    }
  }, [debouncedTag.name]);

  useEffect(() => {
    clearError();
  }, [props.editTagId]);

  return (
    <>
    <div className={clsx("tag-edit", {"tag-edit_active": props.editTagId === props.tag.id})}>

      <div className="tag-edit__color">

        <ColorPicker
          activeColor={props.tag?.color ?? "#252525"}
          onChangeColor={handleChangeColor}
        />
      </div>
      <div
        className={clsx("tag-edit-name", {"tag-edit-name_edit": props.editTagId === props.tag.id})}
        onClick={handleClickEditTag}
      >
        {props.editTagId === props.tag.id && (
          <Input
            className={"tag-edit-name__input"}
            value={editTag.name}
            onInput={handleChangeTagName}
            autoFocus
          />
        )}
        <span>
          { editTag.name }
        </span>
      </div>
      <div className="tag-edit-remove">
        {props.editTagId !== props.tag.id && (
          <IconButton
            onClick={handeClickDeleteTag}
            // className="employee-invite-dialog_button __delete"
            size="large"
            icon={<Icon component={() => <FiTrash2 fontSize="medium" />} />}

          />
        )}
      </div>
    </div>
  { isShowError && (
    <Text
      size="12px"
      style={{ color: "var(--color-error-base)" }}
      children={t("validation:tag_name")}
    />
  )}
  </>
  )
});