import React from "react";
import "./PlanningCollapseContentTable.scss";
import { Table } from "../../../../../../uiKit";
import { css } from "@emotion/css";
import { ICustomIssue } from "../PlanningCollapseContent.interface";
import { ColumnsType } from "antd/es/table";

interface IPlanningCollapseContentTableView {
  dataSource: ICustomIssue[];
  columns: ColumnsType<ICustomIssue>;
}

export const PlanningCollapseContentTableView = (props: IPlanningCollapseContentTableView) => {
  const body = css({
    "& thead > tr > th": {
      color: "#7F85A2",
      fontSize: "12px",
    },
  });

  return (
    <div>
      <Table
        rowClassName={(record: ICustomIssue) => (record.isDeleted ? "table-row-deleted" : "")}
        // showHeader={!!props.dataSource?.find((i: ICustomIssueType) => i.issue.roleId)?.roleId}
        className={`${body} planning-table`}
        pagination={false}
        dataSource={props.dataSource}
        columns={props.columns}
        style={{ minWidth: "max-content" }}
      />
    </div>
  );
};
