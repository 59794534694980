import React from "react";
import { useTranslation } from "react-i18next";
import { CompanyInviteDto } from "../../../../api";
import "./EmployeeInviteListDialog.scss";
import { FiKey } from "@react-icons/all-files/fi/FiKey";
import { FiTrash2 } from "@react-icons/all-files/fi/FiTrash2";
import { FiLink2 } from "@react-icons/all-files/fi/FiLink2";
import { FiCopy } from "@react-icons/all-files/fi/FiCopy";
import { useClaims } from "../../../../hooks";
import { permissionKeys } from "../../../../utils/permissions";
import { Button, Dialog, DialogActions, Icon, IconButton } from "../../../uiKit";

export interface IEmployeeInviteListDialogView {
  open: boolean;
  allInvites: CompanyInviteDto[];

  handleCodeCopy(code: string): void;

  handleLinkCopy(link: string): void;

  handleDelete(id: number): void;

  onClose(): void;
}

function EmployeeInviteListDialogView(props: IEmployeeInviteListDialogView) {
  const { t } = useTranslation();
  const claims = useClaims();

  return (
    <Dialog title={t("ui:title.all_invites")} open={props.open} onClose={props.onClose} closable={false}>
      {props.allInvites.length == 0 && <span>{t("ui:text.no_invites")}</span>}
      <div style={{ maxHeight: 450, overflowY: "auto" }} className="full-width d-stack-column spacing-2">
        {props.allInvites?.map((invite: CompanyInviteDto) => (
          <div className="d-stack-row justify-space-between align-center full-width">
            <div className="d-stack-row align-center justify-space-between full-width">
              <div className="d-stack-row align-center">
                <div
                  className="d-stack-column justify-center mr-4"
                  style={{ backgroundColor: "#bdbdbd", borderRadius: "50%", padding: "10px" }}
                >
                  <FiKey size={20} style={{ stroke: "var(--color-white)" }} />
                </div>
                <div className="d-stack-column justify-center align-start">
                  <h4 style={{ fontWeight: 500, fontSize: 15, marginBottom: 0 }}>
                    {invite.code?.toUpperCase()}
                    <span
                      className="ml-1"
                      style={{ fontWeight: "normal" }}
                      children={`(${invite.accessType == 0 ? t("parse:full_access") : t("parse:limited_access")} ${t(
                        "parse:access"
                      ).toLowerCase()})`}
                    />
                  </h4>
                  <p className="ma-0" style={{ color: "var(--color-gray-strong)" }}>
                    {!!invite.email?.length ? invite.email : t("ui:label.reusable_invite_code")}
                  </p>
                </div>
              </div>
              <div className="d-stack-row spacing-1 justify-end align-center">
                <IconButton
                  disabled={invite.isUsed && !invite.isReusable}
                  onClick={() => props.handleCodeCopy(invite.code ?? "")}
                  // className="employee-invite-dialog_button"
                  size="large"
                  type="link"
                  icon={<Icon component={() => <FiCopy />} />}
                />
                <IconButton
                  disabled={invite.isUsed && !invite.isReusable}
                  onClick={() => props.handleLinkCopy(invite.url ?? "")}
                  // className="employee-invite-dialog_button"
                  size="large"
                  type="link"
                  icon={<Icon component={() => <FiLink2 />} />}
                />
                {claims.has(permissionKeys.user.companyInvite.delete) && (
                  <IconButton
                    onClick={() => props.handleDelete(invite.id as number)}
                    // className="employee-invite-dialog_button __delete"
                    size="large"
                    type="link"
                    danger
                    icon={<Icon component={() => <FiTrash2 />} />}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <DialogActions>
        <Button onClick={() => props.onClose()} variant="text">
          {t("ui:button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmployeeInviteListDialogView;
