import React from "react";
import ScrollTrigger from "../../service/scrollTrigger/ScrollTrigger";
import { SelectProps } from "antd";
import { PagingInfo } from "../../../hooks/usePaging";
import { Avatar, Select, SelectOption } from "../../uiKit";
import { AutocompleteModel, UserAvatarDto } from "../../../api";
import { TAutocompleteAsync } from "./AutocompleteAsync";
import "./AutocompleteAsync.scss";
import { useTranslation } from "react-i18next";

interface IAutocompleteAsyncView extends SelectProps {
  items: (AutocompleteModel & {
    avatar?: UserAvatarDto;
    color?: string;
    isCurrent?: boolean;
    avatarText?: string | null | (string | undefined | null)[];
    disabled?: boolean;
  })[];
  requestOptions?: { [key: string]: any };
  pagingInfo: PagingInfo;
  type: TAutocompleteAsync;
  requestStrategy?: "autocomplete" | "fullInfo";
  idsToFilter?: number[] | string[];
  additionalItems?: (AutocompleteModel & { avatar?: UserAvatarDto })[];
  isLoading: boolean;
  minOptionsCount?: number;
  onLoadMore: () => void;
  isSelectedDisabled?: boolean;
}

const handleGetDisabledOption = (selectedIds: number[] | undefined, currentId: number, minOptionsCount?: number) =>
  (selectedIds ?? []).length <= (minOptionsCount ?? 1) && selectedIds && selectedIds.some((item) => currentId == item);

const getNoNameFallback = (type: TAutocompleteAsync) => {
  switch (type) {
    case "user":
      return "";
  }
};

const SelOption = (
  key: number,
  text: string,
  t: any,
  type?: TAutocompleteAsync,
  isCurrent?: boolean,
  disabled?: boolean
) => {
  const strCurrent = ` (${t("parse:current")})`;
  return (
    <SelectOption key={key} value={key} disabled={disabled}>
      {/*common:misc.new_company*/}
      {type == "plan" ? (
        <span
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          children={text + (isCurrent ? strCurrent : "")}
        />
      ) : (
        <span
          children={
            (text ?? "").trim().length == 0
              ? t(`${type == "adminCompany" ? "common:misc.new_company" : "ui:misc.new_user"}`) + " #" + key
              : text
          }
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
        />
      )}
    </SelectOption>
  );
};

const SelOptionWithAvatar = (
  key: number,
  text: string,
  avatarText: string | null | undefined | (string | undefined | null)[],
  avatar: UserAvatarDto,
  t: any,
  color?: string,
  disabled?: boolean
) => {
  return (
    <SelectOption key={key} value={key} disabled={disabled}>
      <div className="d-flex align-center" style={{ overflow: "hidden" }}>
        <Avatar size={20} src={avatar.image?.url} color={color} text={avatarText} />
        <span
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          className="ml-1"
          children={(text ?? "").trim().length == 0 ? t("ui:misc.new_user") + " #" + key : text}
        />
      </div>
    </SelectOption>
  );
};

export function AutocompleteAsyncView({
  items,
  onLoadMore,
  pagingInfo,
  requestStrategy,
  type,
  idsToFilter,
  additionalItems,
  isLoading,
  minOptionsCount,
  requestOptions,
  isSelectedDisabled,
  ...rest
}: IAutocompleteAsyncView) {
  const { t } = useTranslation();

  return (
    <Select
      filterOption={false}
      // notFoundContent={pagingInfo.isLoading ? <spin size="small" /> : undefined}
      showSearch
      loading={isLoading}
      disabled={isLoading}
      className={"uiKit-autocompleteAsync"}
      isSelectedDisabled={isSelectedDisabled}
      {...rest}
    >
      {items.map((i, index) =>
        // <select.Option key={i.id}>{i.text}</select.Option>
        type == "user"
          ? SelOptionWithAvatar(
              i.id as number,
              i.text as string,
              i.avatarText,
              type == "user" && !!i.avatar ? i.avatar : {},
              t,
              i.color ?? undefined,
              minOptionsCount && rest.mode == "multiple"
                ? handleGetDisabledOption(rest.value, i.id!, minOptionsCount) || (i.disabled ?? false)
                : i.disabled ?? false
            )
          : SelOption(i.id as number, i.text as string, t, type, i.isCurrent != null && i.isCurrent, i.disabled)
      )}
      {!pagingInfo.isDone && (
        <SelectOption value={-1} disabled>
          <ScrollTrigger
            hidden={pagingInfo.isDone}
            onIntersection={() => (pagingInfo.isLoading ? undefined : onLoadMore())}
          />
        </SelectOption>
      )}
    </Select>
  );
}
