import { useLocation, useParams } from "react-router-dom";
import { IPlanningPageMenu } from "./PlanningPageMenu.interface";
import "./PlanningPageMenu.scss";
import { PlanningPageMyPlanMenu } from "./PlanningPageMyPlanMenu";
import { PlanningPageWorkPlansMenu } from "./PlanningPageWorkPlansMenu";
import { PlanningPageApprovalMenu } from "./PlanningPageApprovalMenu";
import { PlanningPageWorkPlanMenu } from "./PlanningPageWorkPlanMenu";

export const PlanningPageMenu = (props: IPlanningPageMenu) => {
  const url = useLocation();
  const params = useParams();

  // const disabledDate = (current: any) => {
  //   return current && current.toDate() > getWeekStartAndEndByDate(dayjs().add(1, "week"), props.weekReportStart)[1];
  // };
  //
  // const customWeekStartEndFormat = () => {
  //   const date = props.date && getWeekStartAndEndByDate(props.date, props.weekReportStart);
  //   return `${dayjs(date && date[0]).format("DD MMM")} - ${dayjs(date && date[1]).format("DD MMM")}`;
  // };

  const getContent = () => {
    switch (url.pathname) {
      case "/communication/my-plan":
        return (
          <PlanningPageMyPlanMenu
            isSidebarOpen={props.isSidebarOpen}
            onSidebarOpenChange={props.onSidebarOpenChange}
            currentPlanId={props.currentPlanId}
            onPlanIdChange={props.onPlanIdChange}
            userId={props.userId}
            plan={props.plan}
            onMigrateIssuesToPlan={props.onMigrateIssuesToPlan}
          />
        );
      case "/communication/work-plans":
        return (
          <PlanningPageWorkPlansMenu
            searchValue={props.searchValue}
            onPlanSearch={props.onPlanSearch}
            currentPlanId={props.currentPlanId}
            onPlanIdChange={props.onPlanIdChange}
            userId={props.userId}
            plans={props.plans}
            onFilterKeyChange={props.onFilterKeyChange}
          />
        );
      case "/communication/require-approval":
        return <PlanningPageApprovalMenu searchValue={props.searchValue} onPlanSearch={props.onPlanSearch} />;
      default:
        return null;
    }
  };

  return <>{params.id ? <PlanningPageWorkPlanMenu plan={props.plan} /> : getContent()}</>;
};
