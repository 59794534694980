import { DecoratorNode, EditorConfig, LexicalEditor, NodeKey, SerializedLexicalNode, Spread } from "lexical";
import { formatDateToDateString } from "../../../../helpers/formatFunctions";
import { getObjectFromTimeSpan } from "../../../../helpers/dateFunctions";
import { OrgchartComponent } from "../components/OrgchartComponent";
import React from "react";
import { RoleComponent } from "../components/RoleComponent";
import { DataType } from "../types/types";
import { UserComponent } from "../components/UserComponent";
import { DateTimeComponent } from "../components/DateTimeComponent";
import { Icon } from "../../../uiKit";
import { FiArrowRight } from "@react-icons/all-files/fi/FiArrowRight";
import { BlockWithAlignableContents } from "@lexical/react/LexicalBlockWithAlignableContents";
import { PriorityComponent } from "../components/PriorityComponent";
import { IssueCommentRecipientDto, UserShortDto } from "../../../../api";

export type SerializedCustomNode = Spread<
  {
    type: "data";
    dataType: string;
    dataValue: string | number;
    subType: string;
  },
  SerializedLexicalNode
>;

export class CustomNode extends DecoratorNode<JSX.Element> {
  __dataType: string;
  __dataValue: string | number;
  __subType: string;

  constructor(dataType: string, dataValue: string | number, subType: string, key?: NodeKey) {
    super(key);
    this.__dataType = dataType;
    this.__dataValue = dataValue;
    this.__subType = subType;
  }

  static getType() {
    return "data";
  }

  static clone(node: CustomNode): CustomNode {
    return new CustomNode(node.__dataType, node.__dataValue, node.__subType, node.__key);
  }

  static importJSON(serializedNode: SerializedCustomNode): CustomNode {
    return $createCustomNode(serializedNode.dataType, serializedNode.dataValue, serializedNode.subType);
  }

  createDOM(config: EditorConfig): HTMLElement {
    const elem = document.createElement("span");
    elem.contentEditable = "false";
    return elem;
  }

  updateDOM(): boolean {
    return false;
  }

  exportJSON(): SerializedCustomNode {
    return {
      type: "data",
      dataType: this.__dataType,
      dataValue: this.__dataValue,
      subType: this.__subType,
      version: 1,
    };
  }

  getStyle(subType: string) {
    switch (subType) {
      case "success":
        return "var(--editor-color-success)";
      case "error":
        return "var(--editor-color-error)";
      default:
        return "var(--color-layout-background)";
    }
  }

  decorate(editor: LexicalEditor, config: EditorConfig): JSX.Element {
    const embedBlockTheme = config.theme.embedBlock || {};

    const className = {
      base: embedBlockTheme.base || "",
      focus: embedBlockTheme.focus || "",
    };

    switch (this.__dataType) {
      case DataType.User:
        const users: UserShortDto[] = config.theme.users ?? [] as UserShortDto[];
        const userId = +this.__dataValue;
        const userInfo = users.find((user) => user?.id === userId);

        return <UserComponent
          id={Number(this.__dataValue)}
          color={this.getStyle(this.__subType)}
          userInfo={userInfo}
        />;
      case DataType.Orgchart:
        return <OrgchartComponent color={this.getStyle(this.__subType)} id={Number(this.__dataValue)} />;
      case DataType.Role:
        return <RoleComponent color={this.getStyle(this.__subType)} id={Number(this.__dataValue)} />;
      case DataType.Date:
        return (
          <DateTimeComponent
            color={this.getStyle(this.__subType)}
            children={formatDateToDateString(new Date(this.__dataValue), "L HH:mm")}
          />
        );
      case DataType.Time:
        return (
          <DateTimeComponent
            children={formatDateToDateString(
              getObjectFromTimeSpan(String(this.__dataValue))?.dateObject as Date,
              "HH:mm"
            )}
            color={this.getStyle(this.__subType)}
          />
        );
      case DataType.Icon:
        return (
          <Icon
            style={{ margin: "0 8px", verticalAlign: "middle" }}
            component={() => (this.__dataValue == "icon.arrow_right" ? <FiArrowRight /> : null)}
          />
        );
      case DataType.Video:
        return (
          <BlockWithAlignableContents nodeKey={this.getKey()} className={className}>
            <video
              title="video"
              // allowFullScreen={true}
              // width={500}
              // height={300}
              style={{ width: "100%", maxWidth: 500 }}
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              // frameBorder={0}
              // marginHeight={0}
              // src={String(this.__dataValue)}
              controls
              // contentEditable={false}
            >
              <source src={String(this.__dataValue)} />
            </video>
          </BlockWithAlignableContents>
        );
      case DataType.Priority:
        return <PriorityComponent value={Number(this.__dataValue)} />;

      default:
        return <span style={{ padding: "2px" }}>{this.__dataValue}</span>;
    }
  }
}

export function $createCustomNode(dataType: string, dataValue: string | number, subType: string): CustomNode {
  return new CustomNode(dataType, dataValue, subType);
}
