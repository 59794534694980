import React, { createRef, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import "./PlanHistoryModule.scss";
import { useTranslation } from "react-i18next";
import { initialEditorContent } from "../../../../../../utils/textEditor";
import { PlanHistoryModuleInterface } from "./PlanHistoryModule.interface";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { api } from "../../../../../../services";
import { useNotifier } from "../../../../../../hooks";
import { PlanHistoryListCards } from "./PlanHistoryListCards";
import { PlanHistoryEditor } from "./PlanHistoryEditor";
import { StaticFileDto } from "../../../../../../api";
import { Card } from "../../../../../uiKit";

export const PlanHistoryModule = forwardRef((props: PlanHistoryModuleInterface, ref) => {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const editorRef = useRef<{ resetEditor: () => void; isEmptyEditor: () => boolean }>();
  const [historyContent, setHistoryContent] = useState<string>(initialEditorContent);
  const [attachments, setAttachments] = useState<StaticFileDto[]>([]);
  const [isFileLoaderUploading, setIsFileLoaderUploading] = useState<boolean>(false);
  const [isSendingLoading, setIsSendingLoading] = useState<boolean>(false);

  const uploadFilesInputRef = createRef();
  const attachmentsCommentCopy = useRef<StaticFileDto[]>([]);
  const isFileLoaderUploadingCopy = useRef(false);

  const handlerApiError = () => {
    notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  };

  const handleChangeCommentContent = (content: string) => {
    setHistoryContent(content);
  };

  const planHistory = usePagingWithController(
    api.planHistory,
    { planId: props.planId },
    undefined,
    undefined,
    handlerApiError
  );

  const handleCreateHistory = async () => {
    setIsSendingLoading(true);

    const allAttachments = [
      ...attachmentsCommentCopy.current.map((f, index) => {
        return {
          fileId: f.id,
          order: index,
        };
      }),
    ];

    const r = await api.planHistory.create({
      planId: props.planId,
      comment: {
        content: historyContent,
        attachments: allAttachments ?? [],
      },
    });
    setIsSendingLoading(false);
    if (r == null) return handlerApiError();
    editorRef.current?.resetEditor && editorRef.current?.resetEditor();
    setHistoryContent(initialEditorContent);
    setAttachmentsCommentSync([]);
    planHistory.restart(true);
  };

  const setAttachmentsCommentSync = useCallback(
    (v: StaticFileDto[]) => {
      setAttachments(v);
      attachmentsCommentCopy.current = v;
    },
    [setAttachments]
  );

  const handleDeleteAttachmentById = (fileId: number) => {
    if (fileId == null) return;
    setAttachmentsCommentSync((attachments ?? []).filter((x) => x.id != fileId));
  };

  const setIsFileLoaderUploadingSync = (v: boolean) => {
    setIsFileLoaderUploading(v);
    isFileLoaderUploadingCopy.current = v;
  };

  const getIsDisabled = (): boolean => {
    if (
      !isFileLoaderUploading &&
      !(
        attachments.filter((a) => a.url != null && a.url.length > 0).length == 0 ||
        attachments.filter((a) => a.url == null).length > 0
      )
    )
      return false;
    // if (historyContent.length == 0 || contentIsEmpty(historyContent)) return true;
    if (historyContent.length == 0 || editorRef.current?.isEmptyEditor()) return true;
    return false;
  };

  const handleRestart = () => {
    // planHistory.reset();
    planHistory.restart(true);
  };

  useImperativeHandle(ref, () => ({
    restart: handleRestart,
  }));

  useEffect(() => {
    props.planId && planHistory.restart();
  }, [props.planId]);

  return (
    <Card className="pa-2">
      <PlanHistoryEditor
        isLoading={planHistory.info.isLoading}
        editorRef={editorRef}
        historyContent={historyContent}
        isSendingLoading={isSendingLoading}
        onChangeCommentContent={handleChangeCommentContent}
        onCreateHistory={handleCreateHistory}
        onDeleteAttachmentById={handleDeleteAttachmentById}
        setIsFileLoaderUploadingSync={setIsFileLoaderUploadingSync}
        uploadFilesInputRef={uploadFilesInputRef}
        setAttachments={setAttachmentsCommentSync}
        attachments={attachments}
        getIsDisabled={getIsDisabled}
      />
      <PlanHistoryListCards
        isDone={planHistory.info.isDone}
        items={planHistory.items}
        onLoadNext={planHistory.loadNext}
      />
    </Card>
  );
});
